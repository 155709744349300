.form{
    margin-bottom: 1rem;
}

.form input{
    width:150px;
}

.form i{
    padding: 10px 27px;
    border: 1px solid #ccc;
    border-radius: 30px;
    font-size: 16px;
    margin-left: 1rem;
}

.form i:hover{
    background:#ebebeb;
    cursor: pointer;
}

.gifSet {
    list-style: none;
    width:100%;
    max-height: 300px;
    overflow: auto;
    padding: 0;
    margin:0 0 1rem 0;
}

.gifTile{
    width: 200px;
    height: auto;
    background: #000;
    cursor: pointer;
}

@media screen and (max-width: 767px){
    .gifTile{
        width:100%;
    }

    .form i{
        padding: 10px 15px;
        margin-left: .8rem;
    }
}

@media screen and (max-width: 600px){
    .gifSet li{
        width: 100%;
    }
    .gifTile{
        width:100%;
    }

    .form i{
        padding: 10px 15px;
        margin-left: .8rem;
    }
}