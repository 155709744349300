.container{
    height: 50px;
    position: relative;
}
.containerResponsive{
    position: relative;
}
.exportButton{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.exportButton i{
    margin-left: 10px;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.listButton{
    text-decoration: underline;
    background: transparent;
    border: none;
    color: #354052;
    padding: 0;
}
.name{
    text-transform: capitalize;
}
.table{
    overflow: auto;
    max-height: 1000px;
}