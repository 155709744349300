.dateToggle :global(.toggleContainer) {
    background-color: #354052;
    border: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,.1);
}
.dateToggle :global(.toggleButton) {
    background-color: #fff;
}
.graphToggle :global(.toggleContainer) {
    background-color: #fff;
    border: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,.1);
}
.graphToggle :global(.toggleButton) {
    background-color: #dee7eb;
}
.leftToggles{
    width: 100%;
    margin-bottom: 20px;
}
.toggles{
    margin: 20px auto;
}

@media screen and (min-width: 776px) {
    .dateToggle{
        max-width: 300px;
    }
    .graphToggle{
        max-width: 400px;
    }
    .toggles{
        display: flex;
        justify-content: space-between;
    }
}