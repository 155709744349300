.badge{
    width: 135px;
    height: auto;
    margin-bottom: 25px;
}
.container{
    text-align: center;
    padding: 15px;
}
.description{
    max-width: 400px;
    margin: 0 auto;
}
.name{
    font-size: 22px;
    margin: 0;
    letter-spacing: 1px;
    font-weight: 600;
}
.points{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px;
}
.points p{
    margin: 0 0 5px 0;
}
.reactions{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 0 0;
}
.reactions>div{
    display: flex;
    align-items: center;
    margin: 0 15px;
}
.reactions .icon{
    width: 20px;
    margin-left: 5px;
}
.reactions svg{
    margin-top: -2px;
}
.reactions .like svg{
    margin-top: -3px;
}
.reactionsContainer{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}