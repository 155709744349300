/* Sidebar Styling */
.sidebar {
  min-width: 300px;
  background: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  margin-left: 20px;
}

/* Header */
.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  font-size: 21px;
  margin-bottom: 20px;
}

/* Add Button */
.addButton {
  background: #6BA543;
  color: white;
  font-size: 20px;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;
}

.addButton:hover {
  background: #5a9135;
}

/* Category Card */
.categoryCard {
  background-color: #ecf2f6;;
  padding: 12px;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

}

/* Container for each category item */
.categoryItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f9fa2c;
  padding: 5px 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  width: 100%;
  transition: background 0.3s ease;
}

/* Category Item */
.categoryItem {
  font-size: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-grow: 1; 
  text-transform: uppercase;
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  background: #ffffff;
  background-color: #ecf2f6;;
  transition: background-color 0.2s ease;}

.categoryItem:hover {

  transform: scale(1.02);
}

/* Selected Category */
.selectedCategory {
  /* background: #6BA543; */
  color: #6BA543;

  transition: background 0.3s ease;
}

.selectedCategory:hover {
  color: #5a9135;
}

/* Delete Button */
.deleteButton {
  background: transparent;
  border: none;
  /* color: #e74c3c; */
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.deleteButton:hover {
  color: #c0392b;
  transform: scale(1.1);
}

/* Category Button */
.categoryButton {
  background: #333;
  color: white;
  width: 100%;
  border: none;
  padding: 6px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 4px;
}
