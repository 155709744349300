.badgeContainer{
    position: relative;
    height: 230px;
}
.badgeContainer.small{
    height: 210px;
}
.container{
    text-align: center;
}
.headingContainer{
    margin-top: 50px;
    margin-bottom: 50px;
    overflow:hidden;
    text-align: center;
}
.heading{
    animation: headingRaise .5s ease-in;
    font-size: 30px;
}
.heading.small{
    font-size: 21px;
}
.newBadge{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.newBadge .badge{
    animation: newBadge_Badge 2s ease;
    width: 160px;
}
.newBadge .badgeName{
    margin: 0 0 -5px 0;
    font-size: 22px;
    animation: newBadge_Badge 2s ease;
    color: #545454;
}
.newBadge.small .badge{
    width: 130px;
}
.newBadge .shadow{
    width: 220px;
    display: block;
    margin: 0 auto;
    animation: newBadge_Image 2s ease;
    opacity: .4;
}
.oldBadge{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 1s ease;
}
.oldBadge .badge{
    width: 100px;
    animation: oldBadge_Badge 2s ease;
}
.oldBadge .shadow{
    width: 190px;
    display: block;
    margin: 0 auto;
    animation: oldBadge_Image 2s ease;
    opacity: .4;
}
.oldBadge.fadeOldBadge{
    opacity: 0;
}
.score{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}
.score.gold{
    font-size: 24px;
    color: #EEBF38;
    animation: scoreAnimation .5s ease;
}

@keyframes headingRaise {
    from{transform: translateY(100%);}
    to {transform: translateY(0);}
}
@keyframes oldBadge_Badge {
    0%{
        transform: scale(1.6);
        opacity: 0;
    }
    50%{
        transform: scale(1.6);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes oldBadge_Image {
    0%{
        transform: scale(1.6);
        opacity: 0;
    }
    50%{
        transform: scale(1.6);
        opacity: 0;
    }
    60% {
        opacity: .8;
    }
    100% {
        transform: scale(1);
        opacity: .4;
    }
}
@keyframes newBadge_Badge {
    0% {
        transform: scale(.3);
        opacity: 0;
    }
    50% {
        transform: scale(.3);
        opacity: 0;
    }
    100%{
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes newBadge_Image {
    0%{
        transform: scale(.3);
        opacity: 0;
    }
    50%{
        transform: scale(.3);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: .4;
    }
}
@keyframes scoreAnimation {
    0%{transform: scale(1);}
    50%{transform: scale(1.2);}
    95%{transform: scale(.9);}
    100%{transform: scale(1);}
}

.description{
    margin: 0 auto 25px auto;
    max-width: 350px;
    animation: descriptionAnimation 1s ease;
}
@keyframes descriptionAnimation {
    from{opacity: 0}
    to {opacity: 1}
}