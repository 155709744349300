.arrow{
    width: 12px;
    fill: #354052;
    margin-right: 15px;
    transform: rotate(-90deg);
    transition: transform .5s ease;
}
.arrow.open{
    transform: none;
}
.category{
    font-size: 21px;
    margin-right: 20px;
    font-weight: bold;
    color: #333;
    text-transform: capitalize;
}
.contentHeading{
    font-size: 18px;
    font-weight: 800;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}
.container{
    margin-bottom: 40px;
}
.heading{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.heading h4{
    font-size: 21px;
    margin-right: 15px;
}
.heading i{
    margin-right: 10px;
    width: 25px;
}
.individual{
    display: flex;
    margin-left: 27px;
    margin-bottom: 5px;
    color: #333;
}
.individual svg{
    width: 12px;
    margin-right: 5px;
    fill: #354052;
    color: #354052;
}