@import url('https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap');
.circle{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top:-25px;
    opacity: 1;
    background: #fff;
    transition: all 5.5s ease-in;
    z-index: 1;
}
.circle.expand{
    animation: expansion 11s infinite linear;
}
@keyframes expansion {
    0% {transform: scale(1); opacity: 1;}
    50% {transform: scale(2); opacity: .2}
    100% {transform: scale(1); opacity: 1;}
}
.circle.freeze{
    transition: all 5.5s ease-in;
    opacity: .2;
}
.container{
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: #fff;
    overflow: hidden;
    height: 100vh;
}
.overlay{
    background-color: rgba(0,0,0,.4);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.player{
    width: 330px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    min-width: 330px;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 70px;
    position: relative;
    z-index: 2;
}
.time{
    z-index: 2;
    position: absolute;
    top: 40%;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    color: #333;
    font-family: 'Ropa Sans', sans-serif;
    font-size: 36px;
}
.time p{
    position: relative;
    z-index: 2;
}

@media screen and (max-height: 550px) {
    .time{
        display: none;
    }
}