.innerRow{
    display: flex;
    margin: 0 -15px;
}
.innerRow .box{
    padding: 0 15px;
    flex: 1;
    margin-bottom: 25px;
}
.statsContainer{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center;
    padding: 15px;
    margin-bottom: 25px;
}
.statsContainer .label{
    font-size: 16px;
}
.statsContainer .stat{
    font-size: 24px;
    font-weight: bold;
}
@media screen and (max-width: 767px) {
    .innerRow{
        flex-wrap: wrap;
        margin: 0;
    }
    .innerRow .box{
        padding: 0;
        min-width: 100%;
    }
}