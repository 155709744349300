@media screen and (max-width:767px) {
    .pathwayContent{
        margin-top: 15px;
    }
    .videoContainer{
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;		
    }
    .videoIframe{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }	
}
@media screen and (max-width:768px) {
	.videoContainer {
		border-radius: 8px;
		overflow: hidden;
	}
}
.category{
    padding: 0 .5rem;
    text-transform: capitalize;
    font-size: 12px;
    margin-bottom:8px;
    display: inline-block;
}
.category:first-of-type{
    padding-left: 0;
}
.pathwayContent .inlineNext{
    justify-self: flex-end;
    margin-left: auto;
    font-size: 12px;
    text-transform: unset;
    padding: 5px 13px;
    margin-bottom: 5px;
}
.pathwayContent p,
.pathwayContent ul,
.pathwayContent ol{
	font-size: 16px;
	line-height: 22px;
}
.pathwayContent ul,
.pathwayContent ol{
    margin: 0 0 0 2rem;
    padding:0;
}
.pathwayContent li{
    margin: 5px 0;
}
.videoContainer {
    margin-top: -5rem;
}