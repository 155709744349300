.buttonContainer{
    flex: 2;
    justify-content: center;
    align-items: center;
    display: flex;
}
.imageContainer{
    margin-right: 1rem;
    display: flex;
    align-items: center;
}
.image{
    border-radius: 50%;
    max-width: 80px;
}
.infoContainer{
    flex: 5;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.itemContainer {
    display: flex;
    margin-bottom: 2rem;
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
    padding: 1rem;
    border-radius: 70px;
}
.name{
    margin: 0;
}

@media screen and (max-width: 767px) {
    .buttonContainer{
        flex: 1;
    }
    .button{
        font-size: 14px;
        padding: .5rem 1.5rem;
    }
    .image{
        max-width: 40px;
    }
    .name{
        font-size: 18px;
    }
}