.container{
    height: 200px;
    position: relative;
    margin-left: -15px;
}
.tooltip{
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
}
.tooltip p{
    margin: 0 0 5px 0;
    color: #4bbc4e;
    font-weight: 400;
}