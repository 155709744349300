.container{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.card{
    padding: 15px;
}
.card .buttons{
    display: flex;
    width: 200px;
    justify-content: space-evenly;
    max-width: 100%;
    margin: 0 auto;
}
.card .buttons .approve{
    color: #4bbc4e;
}
.card .buttons button{
    background-color: transparent;
    border: none;
}
.card .buttons .reject{
    color: #9c4543;
}
.card .image{
    height: 140px;
    margin-bottom: 15px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
}
.card .imageActual{
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}
.card .username{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    width: 100%;
    margin-bottom: 15px;
}
.cardContainer{
    width: 25%;
    padding: 15px;
}
@media screen and (max-width: 800px) {
    .cardContainer{
        width: 50%;
    }
}
@media screen and (max-width: 600px) {
    .cardContainer{
        width: 100%;
    }
}