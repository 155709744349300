.boxes{
    margin: 0 15px 15px 15px;
    padding: 15px;
}
.boxesOuter{
    min-width: 150px;
    min-height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 25px;
}
.calendarRow{
    padding: 15px;
}
.filter .dateRange{
    display: flex;
    align-items: center;
    max-width: 350px;
}
.heading{
    margin: 0 0 30px 0;
}
.heading2{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
}
.mainLabel{
    font-size: 36px;
    font-weight: bold;
}
.secondaryLabel{
    font-size: 18px;
    font-weight: bold;
}
.subLabel{
    font-size: 18px;
    font-weight: 400;
}
.messagingRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.messagingRow .container{
    height: 125px;
    min-width: 100%;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.messagingRow .inner{
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin: 0 25px 25px 0;
}
.messagingRow .inner:last-of-type{
    margin-right: 0;
}
.memberGrowthGraph{
    padding: 15px;
    margin-bottom: 25px;
}
.messagingGraphRow{
    padding: 15px;
    margin-bottom: 25px;
    display: flex;
}
.messagingGraphRow .graph{
    flex: 4;
    margin-right: 25px;
}
.messagingGraphRow .stats{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.messagingGraphRow .stats .singleStat{
    text-align: center;
    padding: 15px;
}
.schedulingGraph{
    padding: 15px;
    margin-bottom: 25px;
}