.mainContent{
    display: flex;
}
.mainContent .content{
    flex: 10;
}
.mainContent .links{
    flex: 2;
}

@media screen and (max-width: 991px) {
    .mainContent{
        flex-wrap: wrap;
    }
    .mainContent .content,
    .mainContent .links{
        width: 100%;
        flex: unset;
    }
}