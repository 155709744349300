.blur{
    filter: blur(5px);
}
.container{
    background-color: #d9e3f4;
    padding: 15px 15px 30px 15px;
}
.form{
    position: relative;
    text-align: center;
    padding: 0 15px 15px 15px;
}
.form .bulgeContainer{
    position: absolute;
    overflow: hidden;
    height: 40px;
    width: 100%;
    top: -39px;
    margin: 0 -15px;
}
.form .bulge{
    position: absolute;
    top: 65px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100px;
    width: 120%;
    border-radius: 50% 50% 0 0;
    background-color: #fff;
}
.form .button{
    background-color: #ff9429;
    border: none;
    border-radius: 30px;
    text-shadow: none;
}
.howToRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 10px 0;
}
.howToRow button{
    background-color: transparent;
    border: none;
    color: #354052;
    display: flex;
    align-items: center;
}
.howToRow .question{
    padding: 4px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 7px;
    color: #fff;
    background: #354052;
    margin-right: 5px;
    margin-top: -2px;
}
.prompt{
    overflow: hidden;
    max-width: 300px;
    margin: 0 auto;
}
.prompt .heading{
    color: #fff;
    font-size: 22px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}
.prompt .iconContainer{
    position: relative;
    text-align: center;
    padding: 20px 0 40px 0;
    background: #ffc000;
    background: linear-gradient(180deg, #ff9429 0%, #ffc000 100%);
}
.prompt .cup{
    width: 100%;
    height: auto;
    fill: #fff;
}
.prompt .steps{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.prompt .underlay{
    position: relative;
    z-index: 1;
    padding: 15px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.row{
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    margin: 10px 0;
    align-items: center;
}
.row:first-of-type{
    padding-top: 0;
    margin-top: 0;
}
.row:last-of-type{
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
.row .badge{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.row .info{
    display: flex;
    align-items: center;
}
.row .info .nickname{
    margin-bottom: 0;
}
.row .infoContainer{
    width: 100%;
    flex: 1;
}
.row .points{
    width: 50px;
}
.row .profileImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid;
    background-size: cover;
    background-position: center;
}
.row .progressInner{
    background-color: #354052;
    border-radius: 10px;
    height: 10px;
    max-width: 100%;
}
.row .progressOuter{
    width: 100%;
    height: 10px;
    border-radius: 10px;
    background-color: #d9e3f4;
    overflow: hidden;
    margin-right: 10px;
}
.rowContainer{
    padding: 15px;
    margin-bottom: 50px;
}
.toggle :global(.toggleContainer) {
    background-color: #fff;
    border: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,.1);
}
.toggle :global(.toggleButton) {
    background-color: #dee7eb;
}
.toggleContainer{
    margin: 0 auto 15px auto;
    max-width: 350px;
}