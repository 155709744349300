
:root {
    --text-primary-color: #ffffff; /* White */
    --text-secondary-color: #e02926; /* red */
    --text-tertiary-color: #949598; /* grey */
    --background-color: #000000;  /* black */
  }


  .loadingContainer {
    height: 100vh;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

/* The outer container */
.outerContainer {
  background: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}

/* container that holds title images and footer */
.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 50px;
}

/* single video player */
/* navbar  */
.headerBackground {
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 50px;
  background: var(--text-secondary-color);
}

.btnLocation {
  min-width: fit-content;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  position: absolute;
  left: 20px;
}

.headerTitle {
  font-size: 25px;
  color: white;
  width: 100%;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  font-family: "industry", sans-serif;
  font-weight: 800
}

/* dark background below video */
.videoWorkoutContainer {
  display: flex;
  height: 140px;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--background-color);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

/* holds video title and container */
.subtitleContainer {
  width: 100vw;
  max-width: 550px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-top: 10px;
}

/* holds icon below video in container */
.iconContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* video title on single video */
.videoTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: white;
  width: fit-content;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-weight: 800;
  font-family: "industry", sans-serif;
}

.iconWrapper {
  display: flex;
  gap: 30px;
}

/* ratings icon */
.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--text-secondary-color);
  border-radius: 50%;
  margin-right: 5px;
}

.filled {
  background-color: var(--text-secondary-color);
}

.empty {
  background-color: #e0292680;
}

.iconText {
  color: var(--text-primary-color);
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'UniversLTPro', sans-serif;
  font-weight: 700;

}

/* centers paragragh below video */
.centerWidthWrapper {
  width: 100vw;
  max-width: 500px;
}

.alignContainerCenter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.description{
  font-family: 'UniversLTPro', sans-serif;
  font-weight: 400; 
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .alignContainerCenter {
    padding-left: 20px;
    padding-right: 20px;
  }
}


@media screen and (max-width: 460px) {
  .outerContainer {
    padding-top: 30px;
  }
  .innerContainer {
    gap: 30px;
  }
  .headerTitle{
    width: 250px;
    font-size: 18px;
  }
}
