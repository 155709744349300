.choiceContainer{
    margin-top: 5rem;
}
.container{
    max-width: 330px;
    margin: 1rem auto;
}
.greenHeader{
    background-color: #6BA543;
    margin-bottom: 25px;
}
.greenHeaderInner{
    max-width: 300px;
    margin: 0 auto;
    padding: 10px 5px;
    color: #fff;
}
.johnsonContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}
.johnsonContainer img{
    max-width: 125px;
    max-height: 46px;
    height: auto;
    width: auto;
}
.learnMore,
.learnMore:hover,
.learnMore:focus,
.learnMore:visited{
    color: #fff;
    text-decoration: underline;
    margin: 5px 0;
    display: block;
}
.logo{
    max-width: 100%;
    margin-bottom: 2rem;
}
.whiteBar{
    padding: 0 2px;
    background: #fff;
    height: 45px;
}