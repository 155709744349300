.contentContainer{
    flex: 4;
    margin-right: 15px;
}
.tagContainer{
    flex: 3;
    margin-left: 15px;
}
@media screen and (max-width: 991px){
    .mainContainer{
        padding: 0 15px;
        margin: 0 auto;
        width: 95%;
    }
}