.description-span {
    font-weight: 100;
}

.disable-goal-bar {
    border: 1px solid gray;
    border-radius: 2px;
    padding: 3px;
    margin-right: 5px;
}

.info-disable-goal-bar {
    margin-left: 5px;
}

.goal-container {
    border: 2px solid gray;
    border-radius: 5px;
    padding-bottom: 18rem;
}

.goal-bar-container {
    border: 1px solid gray;
    padding-top:11px; 
    padding-bottom:11px; 
    background: #e54b4b;
    background: -moz-linear-gradient(left, #e54b4b 0%, #ffff88 50%, #71bf67 100%);
    background: -webkit-linear-gradient(left, #e54b4b 0%,#ffff88 50%,#71bf67 100%);
    background: linear-gradient(to right, #e54b4b 0%,#ffff88 50%,#71bf67 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e54b4b', endColorstr='#71bf67',GradientType=1 );
}

.rc-slider-track {
    background-color: transparent;
}

.rc-slider-rail {
    background-color: transparent;
}