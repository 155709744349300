.boxes{
    padding: 2rem 4rem;
}
.coachTile{
    display: flex;
    margin-bottom: 2rem;
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
    padding: 1rem;
    border-radius: 70px;
}
.coachTile .buttonContainer{
    flex: 2;
    justify-content: center;
    align-items: center;
    display: flex;
}
.coachTile .imageContainer{
    margin-right: 1rem;
    display: flex;
    align-items: center;
}
.coachTile .image{
    border-radius: 50%;
    max-width: 50px;
}
.coachTile .infoContainer{
    flex: 5;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.coachTile .name{
    margin: 0;
}
.coachTile .remove{
    color: #a94442;
    margin-right: .5rem;
}
.icon{
    width: 18px;
    height: 18px;
    margin-left: 0.5rem;
    top: -10px;
    position: relative;
    display: inline-block;
}
.placeholder {
    font-size: 21px;
    color: #ccc;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
}
@media screen and (max-width: 767px) {
    .coachTile{
        flex-wrap: wrap;
        padding: 1.5rem 3rem;
    }
    .coachTile .buttonContainer{
        justify-content: flex-start;
    }
    .coachTile .imageContainer{
        display: none;
    }
    .coachTile .infoContainer{
        width: 100%;
        flex: none;
    }
}