.addButton{
    text-align: right;
}
.archiveButton{
    background: #D90306;
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 6px 24px;
    font-weight: bold;
    margin-bottom: 0.8rem;
}
.communityCheckboxes{
    display: flex;
    margin-bottom: 10px;
}
.communityCheckboxes input[type='checkbox']{
    width: auto;
    display: block;
    margin: 0;
}
.container{
    height: 800px;
    overflow: auto;
}
.champion{
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}
.content{
    max-height: 500px;
}
.branch{
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
}
.branch:first-of-type{
    padding-top:0;
}
.branch:last-of-type{
    border: none;
}
.edit{
    border: none;
    margin-left: 5px;
    background: transparent;
    text-decoration: underline;
    color: #354052;
}
.emailList{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.emailList input[type="checkbox"]{
    width: 15px;
    height: 15px;
    margin: 0 15px 0 0;
}
.emailList label{
    margin: 0;
}
.errorCircle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 1rem;
    background-color: #a94442;
    display: inline-block;
}
.heading{
    display: none;
    border: 1px solid #ccc;
    margin: 0;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}
.list{
    list-style: none;
    margin:0;
    padding: 0;
}
.list .listItem{
    padding: 2rem 1rem 2rem 3rem;
    border-bottom: 1px solid #ccc;
    background-color: #EFF4F8;
}
.list .listItem.active{
    background: #fff;
}
.listMenu{
    float:left;
    height: 100%;
    width:25%;
    margin-left:-2rem;
}
.panel{
    display: none;
}
.panelMain{
    float:left;
    height: 100%;
    width:75%;
    padding-top: 2rem;
    padding-left: 2rem;
}
.show{
    display:block;
}

.secondaryCheckmarkLine{
    margin-right: 10px;
    font-size: 25px;
    border: 1px solid grey;
    width: 12px ;
    height: 15px;
    position: relative;
    top: -5px;
    border-right: 0;
    border-top: 0; 
}

@media screen and (max-width: 991px) {
    .content{
        max-height: 0;
        overflow: hidden;
        transition: all .4s ease;
    }
    .heading{
        display: block;
    }
    .panel{
        display: block;
    }
    .panel.show .content{
        max-height: 1000px;
        padding: 1rem;
        margin-bottom: 1rem;
        border-radius: 5px;
        border: 1px solid #5a5a86;
        overflow-y: auto;
    }
    .panelMain{
        width: 100%;
        padding: 2rem;
    }
}


@media screen and (max-width: 767px) {
    .addButton{
        text-align: left;
    }
}