
/* Pagination styles */
.pagination {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    margin-left: 20px;
    
    margin-bottom: 20px;
    
    
}

.pagination button {
 
    border: none;
    background:  #F3F7F9;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    color: #B2B2B2;
;
}

.pagination .activePage {
    background: #68B93F;
    color: #ffffff;
    border-radius: 6px;
    border-color: white;
    position: relative;

   
}

.pagination .disabled {
    background: #ccc;
    cursor: not-allowed;
}

.prevButton{
    width: 104px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pgBtnContainer{
    background:  #F3F7F9;
    border-radius: 8px;
    margin-left: 15px;
    margin-right: 20px;
    padding: 6px;
    width: fit-content;
    height: 34px;
    display: flex;;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.pgBtnContainer button {
    border-radius: 0;
    font-size: 14px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
