/* global styles yo */
@import url("https://use.typekit.net/oeh2jfr.css");


@font-face {
    font-family: 'UniversLTPro';
    src: url('/fonts/Univers/UniversLTPro-65Bold.otf') format('opentype');
    font-weight: 700; /* Corresponds to Bold */
    font-style: normal;
  }


  @font-face {
    font-family: 'UniversLTPro';
    src: url('/fonts/UniversLTPro-45Light.otf') format('opentype');
    font-weight: 300; /* Light weight */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'UniversLTPro';
    src: url('/fonts/UniversLTPro-55Roman.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

body {
    background: #ecf2f6;
    padding-bottom: 10rem;
    font-family: proxima-nova,sans-serif;
    font-weight: 400;
    font-style: normal;
}


/* added to remove padding-bottom from some components */
.no-padding-bottom {
    padding-bottom: 0 !important;
  }
input {
    border-radius: 6px;
    width: 100%;
    padding: 1rem 1rem;
    border: 1px solid #ccc;
}
img { max-width: 100%; height: auto; }
h1,h2,h3,h4,h5,h6,
.btn, .profile-name, .plan-name {
    font-family: proxima-nova,sans-serif;
    font-weight: 800;
    font-style: normal;
}

/*******************************************
HELPER CLASSES
********************************************/

.no-text-decoration { text-decoration: none !important;  }
.no-padding-left { padding-left: 0 !important }
.no-padding-right { padding-right: 0 !important }
.no-margin { margin: 0 !important; }
.no-margin-top { margin-top: 0 !important; }
.margin-top { margin-top: 1rem !important; }
.margin-top-2x { margin-top: 2rem !important; }
.margin-top-3x { margin-top: 3rem !important; }
.margin-top-lrg { margin-top: 4rem !important; }
.margin-top-nOffset { margin-top: -1rem !important; }
.margin-bottom { margin-bottom: 1rem !important; }
.margin-bottom-2x { margin-bottom: 2rem !important; }
.margin-bottom-lrg { margin-bottom: 20rem !important; }
.margin-bottom-med { margin-bottom: 4rem !important; }
.margin-left { margin-left: 1rem !important; }
.margin-right { margin-right: 1rem !important; }
.margin-right-2x { margin-right: 2rem !important; }
.text-indent-left { padding-left: 40px !important; }
.text-center { text-align: center !important; }
.text-left { text-align: left !important; }
.capitalize { text-transform: capitalize !important; }
.no-padding { padding: 0 !important; }
.no-padding-bottom {padding-bottom: 0 !important;}
.padding-standard{padding:25px;}
.margin-left-25{margin-left: 25px;}
.margin-right-25{margin-right: 25px;}
.margin-bottom-25{margin-bottom: 25px;}
.margin-top-25{margin-top: 25px;}
.padding-small{padding:15px;}
.margin-left-15{margin-left: 15px;}
.margin-right-15{margin-right: 15px;}
.margin-bottom-15{margin-bottom: 15px;}
.margin-top-15{margin-top: 15px;}
.margin-top-20{margin-top: 20px;}
.float-right { float: right !important }
.flex-row {
	display: flex;
	flex-wrap: wrap;
}
.flex-row > div { margin: 0 auto;}
.flex-wrap{ display: flex; flex-wrap: wrap}
.fullWidth { width: 100%; }
.flex-center{
    display:flex;
    justify-content: center;
    margin:0 auto;
}
.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-vertically{
    display:flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
}
.flex-horizontally{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.margin-center{
    max-width:770px;
    margin:0 auto;
}
.center-handv {
  width: auto;
  min-width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.inline-block { display: inline-block !important;}
.list-capitalize{text-transform: capitalize}
.text-uppercase { text-transform: uppercase !important; }
.height-100{
    height: 100%;
}
.mobileModal .modal-dialog {
    max-width: 375px;
    margin-right: auto;
    margin-left: auto;
}
@media screen and (max-width:400px) {
    .mobileModal .modal-dialog{
        margin: 50px 15px 15px 15px;
    }
}


.alert {
    padding: 1rem 2rem;
    margin: 1rem 0;
    border: 1px solid #e2b156 ;
    border-radius: 50px;
    width: 100%;
    max-width: 320px;
    /* margin-left: 5%; */
    /* margin-top: 5%; */
    background-image: none !important;
    background-color: #e2b156  !important;
    color: #fff !important;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0,0,0,.3);
    /* font-family: 'din-condensed'; */
    /* font-size: 18px; */
    /* color: #fff; */
    letter-spacing: 1px;
    text-transform: capitalize;
}
.alert-success {
    background-image: none !important;
    background-color: #4bbc4e !important;
    color: #fff;
    border: 1px solid #4bbc4e !important;
}
.alert-danger {
    background-image: none !important;
    background-color: #B92426 !important;
    color: #fff;
    border: 1px solid #B92426 !important;
}
.login.save-profile-alert,
.edit-teammate-alert {
    margin-top: 0 !important;
}
.alert h4 { margin: 0; letter-spacing: inherit;}
.alert-dismissable .close, .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: 0;
    color: inherit;
}

.link.white{color:white;}
.link.underlined{text-decoration: underline;}

.sort-btn-container{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.sort-label{width:100%}

.sort-btn{
    color: #6c6c6c;
    display: flex;
    justify-content: space-evenly;
    white-space: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    padding: .5rem 1rem;
    border-radius: 8px;
}
.sort-heading{width:100%; margin-top:15px;}

.sort-btn.active{
    color : #fff;
    background-color: #0b97c4;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}
.treoGlyph{
    color: #ABA6A2;
}
.treoGlyph:hover{
    cursor: pointer;;
}
.hover:hover{
    cursor: pointer;
}
.greenLink{
    text-decoration: underline;
    color: #4bbc4e;
}
.black{
    color: #000;
}
.green{
    color: #4bbc4e;
}
/*******************************************
PAGINATION
********************************************/
.pagination-container {
    margin: 0;
    text-align: left;
}
.pagination-container.client--dash {
    margin: 1rem 0 2rem;
    text-align: center;
}

.pagination-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.paginate {
    display: inline-block;
    margin-right: 1rem;
}

.pagination-container .break {
    display: inline-block;
    margin: 0 1rem 0 0;
}
.paginate.disabled {
    display: none;
}
.paginate a {
    border: 1px solid #354052;
    color: #354052;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    line-height: 35px;
    background: transparent;
}
.paginate a:hover,
.paginate a:active {
    text-decoration: none;
    background: #354052;
    color: #fff;
}
.paginate.selected a {
    background: #354052;
    color: #fff;
}
.paginate.paginate-next a,
.paginate.paginate-previous a {
    border: none;
}
.paginate.paginate-previous {
    margin-right: 2rem;
}
.paginate.paginate-next a:hover,
.paginate.paginate-previous a:hover {
    background: transparent;
    color: #354052;
}

/*******************************************
CLIENT SPECIFIC STYLES
********************************************/

/* this is placed in the app wrapper so we can overwrite anything easily by adding .client-dash before anything */
.client-container {}
.app-container .client-container .general-container,
.client-container .wellness-bg .container,
.client-container .client-dash.container,
.client-container .container.client-account-dash,
.client-subheader-content.subheader--desktop .container{
    max-width: 1200px;
}

.client-subheader-content.subheader--desktop {
    padding: 1rem 0;
    /* margin-bottom: 5rem; */
    max-width: 100%;
}

.header-overlay {
    position: absolute;
    top: 0;
    width: 100%;
}
.header-overlay .navbar {
    background: transparent !important;
}
.navbar .client-header-links { position: relative;}
.header-logo{
    max-width: 100px;
    max-height: 40px;
} .login-logo{
    max-width: 130px;
}
@media (max-width: 799px) {
    .navbar .client-header-links {
        color: #fff;
        padding: .25rem .5rem .25rem 1rem;
    }
}
@media (min-width: 800px) {
    .navbar .client-header-links {
        color: #fff;
        padding: .25rem 2.5rem .25rem 1rem;
    }
}
.client-container .mobile--logo {
    float: left;
    margin-left: -30px;
    max-width: 100px;
}
.client-container .mobile--logo img{
    max-height: 40px;
    max-width: 100px;
    height: auto;
}
@media (max-width: 767px) {
    .navbar-nav {
        width: 100%;
    }
}
.navbar { z-index: 2; }
.navbar-inverse .navbar-nav>li>a { font-size: 14px; padding: 1rem 1.25rem; }
@media (min-width: 980px) {
    .navbar-inverse .navbar-nav>li>a { padding: 1rem 3rem; }
}

.navbar a {color: #fff; }
.dropdown-menu a { color: #000; }

.navbar-inverse .navbar-collapse {
    text-align: left;
    background: #354052;
}
.navbar-inverse .navbar-collapse .dropdown-menu a {
    color: #fff;
    text-align: center;
}
.dropdown-menu {
    background: #283140;
}
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    background: #080808;
}
.client-container .navbar-default { text-align: right; }
.client-container .navbar-inverse .navbar-collapse,
.client-container .navbar-inverse .navbar-form {
    border: none !important;
    box-shadow: none !important;
}
/* for the navbar icons like envelopes */
.client-header-links span {
    margin-left: 1rem;
}
.client-header-links div { display: inline-block; }
.client-header-links .header-unread-notification { display: flex; }
.client-container .navbar-default {
    box-shadow: none !important;
}
.wellness-bg {
    padding: 130px 0 100px 0;
    color: #fff;
    text-shadow: 2px 2px 5px rgba(0,0,0,.3);
}

.wellness-bg.thin{
    padding:35px 0;
}
.wellness-bg.largeDesktop{
    padding: 70px 0 80px 0px
}
.wellness-bg.largeDesktop.branded{
    padding: 25px 0 80px 0;
}
.wellness-bg.dashboard.branded{
    padding: 50px 0 100px 0;
}
.wellness-bg.header-only {
    padding: 40px 0;
}
.wellness-bg.header-overlay {
	background: transparent;
}
.wellness-bg.low {
    /* 0 to 50 */
    background: #ff182d; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ff182d 0%, #ff9429 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ff182d 0%,#ff9429 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff182d 0%,#ff9429 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e68f50', endColorstr='#ec463f',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.wellness-bg.average {
    /* 51 to 74 */
    background: #ff9d29; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ff182d 0%, #ff9429 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ff182d 0%,#ff9429 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff182d 0%,#ff9429 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ed3d3d', endColorstr='#e0da60',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.wellness-bg.high {
    /* 75 to 89 */
    background: #ff8c29; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ff8c29 0%, #ffd369 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ff8c29 0%,#ffd369 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff8c29 0%,#ffd369 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0da60', endColorstr='#41ce41',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.wellness-bg.highest {
    /* 90+ */
    background: #7DBB51; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #399c39 0%, #87c33f 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #399c39 0%,#87c33f 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #399c39 0%,#87c33f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7DBB51', endColorstr='#96E859',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
@media screen and (max-width: 768px) {
    .wellness-bg.largeDesktop{
        padding-bottom: 15px;
    }
    .wellness-bg.largeDesktop.branded{
        padding-bottom: 15px;
    }
}
@media screen and (max-width: 600px) {
    .wellness-bg.dashboard{
        padding: 75px 0;
    }
    .wellness-bg.dashboard.branded{
      padding: 15px 0 45px 0;
    }
}

.footer-link{
    margin-top: 10rem;
}

.footer-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 40px;
    box-shadow: 0px -5px 15px rgba(0,0,0,.1);
    z-index: 5;
}
.footer-actions .container {
    margin-top: -20px;
}

.client-account-dash { margin-top: 3rem; }
.assessment .client-account-dash { margin-top: 0; }
.client-dash .tiles-ul .tile,
.client-account-dash .tiles-ul .tile {
    margin: 0 auto 2rem;
    padding: 1.5rem 5rem;
    position: relative;
}
.client-dash .tiles-ul .tile h4,
.client-account-dash .tiles-ul .tile h4 {
    margin-bottom: 0;
}
.client-dash .tiles-ul a .tile,
.client-account-dash .tiles-ul a .tile{
    color: #616161;
}
.client-dash .tiles-ul a .tile h4,
.client-account-dash .tiles-ul a .tile h4 {
    color: #616161;
    text-transform: uppercase;
    font-size: 20px;
}
/* adds the small horizontal dash */
.client-dash .tiles-ul a .tile h4:before,
.client-account-dash .tiles-ul a .tile h4:before,
.BGwhenye:before, .BGwyd:before, .BGwye:before{
    content: '';
    display: block;
    width: 30px;
    height: 4px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 1.5rem;
}
.client-account-dash .tiles-ul .onboarding-tile{
    background-color: #4BBC4E
}
.client-account-dash .tiles-ul .onboarding-tile .tile-titles{
    width: 100%;
}
.client-account-dash .tiles-ul .onboarding-tile .heading,
.client-account-dash .tiles-ul .onboarding-tile .subheading{
    color: #fff;
}

.singleTileDescriptionMobile {
    margin-bottom: -10px;
    margin-top: -30px;
}

.singleTileDescription.BGwhenye:before,
.singleTileDescription.BGwyd:before,
.singleTileDescription.BGwye:before{
    content: none;
}
.singleTileDescription.BGwhenye:after,
.singleTileDescription.BGwyd:after,
.singleTileDescription.BGwye:after
{
    content: '';
    display: block;
    width: 30px;
    height: 4px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-top: 10px;
}
.singleTileDescription.BGwyd:after { background-color: #4f87a0; }
.singleTileDescription.BGwye:after { background-color: #7eaf5b; }
.singleTileDescription.BGwhenye:after { background-color: #c4b02d;}
.singleTileDescription.BGscore:after { background-color: #4f87a0; }
/*.client-dash .tiles-ul a .tile.wydBG h4:before, .BGwyd::before {
    background-color: #4f87a0;
}*/

.completed-tile-message {
    margin-top: -30px;
}

.overview-stat h3 {
    font-size: 24px !important;
}
.tile-titles {
    width: 70%;
    display: inline-block;
}
.tile-titles p {
    text-transform: capitalize;
    font-size: 18px;
}
.tile-icon {
    height: 90px;
    width: 90px;
    background: #f5f5f5;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -45px;
    padding: 15px;
}

.tile-icon-admin-list{
    height: 90px;
    width: 90px;
    background: #f5f5f5;
    border-radius: 50%;
    position: absolute;
    right: 23px !important;
    top: 50%;
    margin-top: -60px !important;
    padding: 15px;
}

.tile-icon-add-activity{
    height: 90px;
    width: 90px;
    background: #f5f5f5;
    border-radius: 50%;
    right: 10px;
    top: 50%;
    margin-top: -45px;
    padding: 15px;
    margin: auto;
}

.tile-icon-admin-list-full{
    height: 90px;
    width: 90px;
    border-radius: 50%;
    padding: 15px;
    margin-top: -5px;
    margin-left: -9px;
}

.the--icon {
    height: 95%;
    width: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.icon-doc{
    background-image: url('../images/icon-document.png');
    background-position: center center;
    background-repeat: no-repeat;
}

.icon-assessment {
    width: 90px;
    height: 90px;
    display: block;
    margin: 0 auto;
    background-image: url('../images/icon-assessment.png');
    background-position: center center;
    background-repeat: no-repeat;
}
.icon-calendar {
    width: 90px;
    height: 90px;
    display: block;
    margin: 0 auto;
    background-image: url('../images/icon-calendar.png');
    background-position: center center;
    background-repeat: no-repeat;
}

.icon-archive {
    display: inline-block;
    padding: 0 0 0 35px;
    background-image: url('../images/icon-archive.png');
    background-position: center left;
    background-repeat: no-repeat;
    cursor: pointer;
}
.archive-link.icon-archive {
    margin-left: 2rem;
    font-size: 12px;
    font-family: "din-condensed";
    font-weight: 600;
    color: rgba(0,0,0,.4);
}
span.archived.icon-archive {
    /* background: #000; */
    display: inline;
    left: -20px;
    top: 38px;
    position: absolute;
    width: 30px;
    height: 30px;
}

.client-search-list{
    margin-left: -8px !important;
}
/* set color inline - Introduction */
.icon-Orientation,
.icon-orientation {background-image: url("/");}

.wydBG svg.cls-1 { fill: #4f87a0; }
.wyeBG svg.cls-1 { fill: #7eaf5b; }
.whenyeBG svg.cls-1 { fill: #c4b02d; }
.scoreBG svg.cls-1 { fill: #4f87a0; }


/* wellness score circle - client dash */
.circle-inner {
    background: #ccc;
    border-radius: 50%;
    height: 115px;
    top: 8px;
    left: 22px;
    width: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    z-index: 2;
}
.circle-outer {
    width: 165px;
    height: 165px;
    /* background: #ccc; */
    position: absolute;
    z-index: 0;
    top: -20px;
    left: -5px;
    border: 12px solid rgba(255,255,255,.2);
    border-radius: 50%;
}

.wellness-bg {
    background-color: #ccc;
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.wellness-bg.intro-tile {
    background-color: transparent;
}
.wellness-bg.intro-tile h2 {
    color: #333;
    text-align: center;
}
.wellness-bg .circle-inner{
    /* 0 to 50 */
    background-color: rgba(0, 0, 0, .2);
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.circle-inner h2 {
    font-family: "din-condensed", sans-serif;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 50px;
    font-weight: 400;
}
.circle-inner .plus{
    font-size: 45px;
}
.circle-inner p { font-size: 10px; margin: 0; }

.client-dash .tiles-ul a .tile.wydBG h4:before, .BGwyd::before { background-color: #4f87a0; }
.client-dash .tiles-ul a .tile.wyeBG h4:before, .BGwye::before { background-color: #7eaf5b; }
.client-dash .tiles-ul a .tile.whenyeBG h4:before, .BGwhenye::before { background-color: #c4b02d;}
.client-dash .tiles-ul a .tile.scoreBG h4:before, .BGscore::before { background-color: #4f87a0; }

.tileBar-center::before{
    background-color: #4f87a0;
    content: '';
    display: block;
    width: 30px;
    height: 4px;
    border-radius: 8px;
    margin: 0 auto 1.5rem auto;
}

.tileBar-center._wyd::before{background-color: #4f87a0;}
.tileBar-center._wye::before{background-color: #7eaf5b;}
.tileBar-center._whenye::before{background-color: #c4b02d;}



@media (max-width: 600px) {
    .circle-inner {
        height: 65px;
        width: 65px;
        top: 2px;
        left: 17px;
    }
    .circle-outer {
        width: 110px;
        height: 110px;
    }
    .circle-inner h2 {
        font-size: 27px;
    }
    .wellness-circle .circle-outer .VictoryContainer{
        width: 130% !important;
        height: 130% !important;
    }
    .circle-inner .plus{
        font-size: 21px;
    }
}
@media (max-width: 400px) {
    .dashboard--container .overview-stat { height: 80px;}
}
@media (min-width: 401px) {
    .dashboard--container .overview-stat { height: auto;}
}
.dashboard--container .wellness-circle {
    position: relative;
    cursor: pointer;
}

.overview-stat h3 { margin-bottom: 2px; font-size: 26px;}
.overview-stat p { font-weight: 200; }

/* account nav screen */
.account-nav {
    margin: 0 0 2rem 0;
    padding: 0;
    display: flex;
    list-style: none;
    width: 100%;
    border-bottom: 1px solid #ccc;
}
.account-nav li {
    margin: 0 auto;
}
.account-nav li a{
    padding: 1.5rem;
    display: inline-block;
}
.account-nav li a:hover,
.account-nav li a:focus,
.account-nav li a:active{
    text-decoration: none;
}
.account-nav li .active{
    border: 1px solid #d7d7d7;
    border-bottom: 1px solid #ECF2F6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    bottom: -2px;
    background: #ecf2f6;
}
.react-datepicker__input-container {
    width: 100% !important;
}

.react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name, .react-datepicker__day--outside-month {
    width: 4rem !important;
    height: 4rem !important;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 4rem !important;
    height: 4rem !important;
}

.gym-container {
    border-bottom: 1px solid #ccc;
    padding-bottom: 4rem;
    margin: 2rem 0;
}
.gym--map { margin-bottom: 3rem; }

input#checkBox.sprint8-check {
    display: inline-block;
    width: 20px;
    margin: 7px 0 0 0;
}
.gym--add {
    margin-top: 5rem;
}
.equipment--item {
    border-bottom: 1px solid #ccc;
    padding-bottom: 4rem;
    margin: 2rem 0;
}

.add-equip-btn {
    background: #4bbc4e !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ccc;
    color: #666;
    font-size: 12px;
    padding: .5rem 2rem;

}

.delete-equip-btn {
    background: transparent !important;
    border: 1px solid red !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: red !important;
    font-size: 12px;
    padding: .5rem 2rem;
}

.btn.delete-equip-btn:hover {
    background: red !important;
    color: #fff !important;
}

/* add activity overlay */
.add-activity-overlay,
.add-activity-overlay .container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    color: #fff;
}
.add-activity-overlay .container {
    max-width: 600px !important;
    padding: 0 5%;
}
.add-activity-overlay {
    background-color: #ccc;
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}

.add-activity-overlay h2 {
    text-transform: capitalize;
    margin: 2rem 0 0 0;
    font-family: "aaux-next";
    font-weight: 800;
}
.add-activity-overlay h3 {
    margin: .5rem 0;
    text-transform: capitalize;
    font-size: 2rem;
    font-family: "aaux-next";
    font-weight: 200;
}
.add-activity-overlay .activitySlider {
    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    -moz-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    border-radius: 80px;
}

.add-activity-overlay .rc-slider {
    margin: 3rem 0 0;
}
.add-activity-overlay .rc-slider-mark-text {
    color: rgba(255,255,255,.9);
    margin: 0 auto !important;
    width: auto !important;
}
.add-activity-overlay .rc-slider-mark {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    font-size: 10px;
}
.add-activity-overlay .rc-slider-handle {
    transform: translateX(0) !important;
    background: red; /* this should be set dynamically based on slider position */
    width: 45px;
    height: 45px;
    margin-top: -20px;
    border: 4px solid rgba(0,0,0,.3);
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    -moz-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
}
.add-activity-overlay .rc-slider-handle:focus,
.add-activity-overlay .rc-slider-handle:active {
    border: 4px solid rgba(0,0,0,.4);
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    -moz-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    outline: none;
}
.add-activity-overlay .rc-slider-handle:hover {
    border: 4px solid rgba(0,0,0,.4);
}
.add-activity-overlay .react-datepicker-wrapper {
    display: inline-block;
    /*width: 60%;*/
}
.add-activity-overlay .react-datepicker__input-container {
    display: block;
    width: 100%;
    margin: 4rem auto 7rem;
    outline: none;
}
.add-activity-overlay .react-datepicker__input-container input {
        border-radius: 50px;
    width: 100%;
    min-width: 320px;
    margin-top: 1rem;
    font-size: 15px;
    padding: .75rem 1rem;
    border: 1px solid rgba(255,255,255,.3);
    background: transparent;
    text-align: center;
    color: rgba(255,255,255,.9);
    outline: none;
}

.multiple-activities {
    margin: 3rem 0 0 0;
    padding: 0;
    list-style: none;
}
.multiple-activities li {
    border-radius: 50px;
    width: 100%;
    max-width: 480px;
    font-size: 2rem;
    padding: 1.5rem 1rem;
    border: 1px solid rgba(255,255,255,.3);
    background: transparent;
    text-align: center;
    color: rgba(255,255,255,.7);
    margin: 0 auto 1.5rem;
    cursor: pointer;
    -webkit-transition: all .5s linear;
    -o-transition: all .5s linear;
    transition: all .5s linear;
    font-family: "aaux-next";
    font-weight: 200;
    text-transform: capitalize;
}
.multiple-activities li:hover {
    border: 1px solid rgba(255,255,255,.2);
    background: rgba(255,255,255,.1);
    color: rgba(255,255,255,1);
    -webkit-transition: all .5s linear;
    -moz-transition: all .5s linear;
    -o-transition: all .5s linear;
    -ms-transition: all .5s linear;
    transition: all .5s linear;
}

.close-button {
    position: absolute;
    top: 2rem;
    right: 1rem;
    border: 3px solid rgba(255,255,255,.5);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 21px;
    font-size: 22px;
    color: rgba(255,255,255,.5);
    cursor: pointer;
    -webkit-transition: all .5s linear;
    -moz-transition: all .5s linear;
    -o-transition: all .5s linear;
    -ms-transition: all .5s linear;
    transition: all .5s linear;
}
.close-button:hover {
    color: rgba(255,255,255,1);
    border: 3px solid rgba(255,255,255,1);
    -webkit-transition: all .5s linear;
    -moz-transition: all .5s linear;
    -o-transition: all .5s linear;
    -ms-transition: all .5s linear;
    transition: all .5s linear;
}

.add-activity-overlay .btn {
    font-size: 20px;
    padding: 1rem 7rem;
}


/* category bgs */
.noCategory {
    background: #ccc; /* Old browsers */
}
.wydBG {
    background: #00325c ; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #3d7b96 0%, #00325c 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #3d7b96 0%,#00325c 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #3d7b96 0%,#00325c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3d7b96', endColorstr='#00325c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.wyeBG {
    background: #7eaf5b; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #7eaf5b 0%, #6ca345 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #7eaf5b 0%,#6ca345 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #7eaf5b 0%,#6ca345 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7eaf5b', endColorstr='#6ca345',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.whenyeBG{
    background: #c4b02d; /* Old browsers */
    background: -moz-linear-gradient(5deg, #d4c45e 0%,#8e7f1a 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(5deg, #d4c45e 0%,#8e7f1a 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(185deg, #d4c45e 0%,#8e7f1a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4c45e', endColorstr='#8e7f1a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}

.scoreBG {
    background: #00325c ; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #3d7b96 0%, #00325c 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #3d7b96 0%,#00325c 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #3d7b96 0%,#00325c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3d7b96', endColorstr='#00325c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}

.delete-client{
    width: 174px;
}

.tile-border-right .rc-slider-handle {
    transform: translateX(10%) !important;
}

/* single tile display */

/*******************************************
CHARTS
********************************************/
.amcharts-chart-div a {display:none !important;}

.amcharts-graph-line {
  filter: url(#dropshadow);
}

.graph-controls{
    /*display: flex;
    justify-content: space-around;*/
    position: relative;
    top: 5rem;
    width: 100%;
}
.graph-controls span {
    width: 50%;
    display: inline-block;
    font-size: 1.25rem;
    cursor: pointer;
    height: 20px;
    text-transform: capitalize;
}
.graph-controls span:first-child:before {
    content: '';
    width: 10px;
    height: 10px;
    left: -20px;
    top: 5px;
    display: inline-block;
    position: relative;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    border-width: 3px 3px 0 0px;
    border-style: solid;
    border-color: #fff;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    display: inline-block;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.graph-controls-admin span:first-child:before, .graph-controls-admin span:last-child:after{
    border-color: black !important;
}

.single-tile-stats{
    padding-right: 5rem;
}

.graph-controls span:nth-child(2):after {
    content: '';
    width: 10px;
    height: 10px;
    right: -10px;
    top: 5px;
    display: inline-block;
    position: relative;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-width: 3px 3px 0 0px;
    border-style: solid;
    border-color: #fff;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    display: inline-block;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.graph-controls span:first-child { padding: 0 0 0 2rem; }
.graph-controls span:nth-child(2) { padding: 0 2rem 0 0; }


@media (max-width: 767px) {
    .col-md-8.tile--graph-container > div { height: 350px !important;}
    .col-md-8.tile--graph-container { height: 280px !important; }
    .graph-controls span:first-child {
        text-align: left;
    }
    .graph-controls span:nth-child(2) {
        text-align: right;
    }

}
@media (min-width: 768px) {
    .col-md-8.tile--graph-container > div { height: 500px !important;}
    .col-md-8.tile--graph-container { height: 500px !important; }
    .graph-controls span:first-child {
        font-size: 1.5rem;
        cursor: pointer;
        text-align: left;
    }
    .graph-controls span:nth-child(2) {
        font-size: 1.5rem;
        cursor: pointer;
        text-align: right;
    }

}


/*******************************************
BUTTONS
********************************************/
.btn {
    text-transform: uppercase;
    display: inline-block;
    padding: 1rem 3rem;
    box-shadow: 0px 3px 5px rgba(0,0,0,.2);
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    border: none;
    border-radius: 10px;
    background-color: #354052;
    color: #fff;
    background-image: none;
    outline: none !important;
    text-shadow: 2px 2px 5px rgba(0,0,0,.3);
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: #fff !important;
  text-decoration: none;
}
.btn.primary {
    border-radius: 8px;
    background: #6BA543;
    color: #fff;
    font-size: 18px;
    text-shadow: none;
}
.btn.primary.outline, 
.btn.primary.outline:focus {
	background: #fff;
    border: 2px solid #4bbc4e;
    box-shadow: none;
    text-shadow: none;
    color: #4bbc4e !important;
	font-size: 16px;
}
.btn.primary.outline.transparent {
	background: transparent;
}
.btn.primary.outline:hover {
	background: #4bbc4e;
	color: #fff !important;
}
.btn.logout {
    background-color: #D90306;
    color: #fff;
    font-size: 18px;
}

.btn.btn--actions {
    background: transparent;
    box-shadow: none;
    border: 1px solid #ccc;
    color: #666;
    font-size: 12px;
    padding: .5rem 2rem;
}
.btn-group.graph--options {
    background: rgba(0,0,0,.3);
    display: inline-block;
    padding: 0 .75rem;
    -webkit-box-shadow: 0px 3px 5px rgba(0,0,0,.2);
    box-shadow: 0px 3px 5px rgba(0,0,0,.2);
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    border: none;
    border-radius: 40px;
    color: #fff;
    background-image: none;
}
.content_display-opacity{
    background: rgba(0,0,0,.26) !important;
}

.btn-group.graph--options .btn {
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 1rem;
    font-family: "aaux-next";
    font-weight: 200;
    box-shadow: none !important;
    text-shadow: none !important;
    font-size: 14px;
}
.contentCheckMarkContainer{
    position: relative;
    left: 201px;
}
.contentCheckMark {
    position: absolute;
    color: #4bbc4e;
    top: 1px;
    border: 1px solid white;
    background: white;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
}
.contentCheckMark:before {
  content: '✓';
}

.btn-group.graph--options .btn:hover,
.btn-group.graph--options .btn:focus,
.btn-group.graph--options .btn-default.active,
.btn-group.graph--options .btn-default:active
{
    color: #fff;
    background: rgba(255,255,255,.2) !important;
    outline: none;
    border-radius: 25px !important;
    /* width: 90%; */
    /* margin-left: 5%; */
    line-height: 1rem;
}
.btn-group-content-graph.graph--options .btn-default.active,
.btn-group-content-graph.graph--options .btn-default:active
{
    color: #fff;
    background: rgba(255,255,255,.2) !important;
    outline: none;
    border-radius: 25px !important;
    /* width: 90%; */
    /* margin-left: 5%; */
    line-height: 1rem;
    margin-top: 5px;
    margin-bottom: 5px;
}
.custom_opacity{
    background: rgba(255,255,255,.1) !important;
}
/*background btn group */
.btn-group.backgroundCloneBtns {
    display: inline-block;
    padding: 0 .75rem;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    border: none;
    border-radius: 40px;
    color: #fff;
}
.btn-group.backgroundCloneBtns .btn {
    background: transparent;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 1rem;
    font-family: "aaux-next";
/*    font-weight: 200;*/
    box-shadow: none !important;
    text-shadow: none !important;
/*    font-size: 14px;  */
}
.btn-group.backgroundCloneBtns .btn:hover,
.btn-group.backgroundCloneBtns .btn:focus,
.btn-group.backgroundCloneBtns .btn-default.active,
.btn-group.backgroundCloneBtns .btn-default:active
{
    color: #fff;
/*    background: blue !important;*/
    outline: none;
    border-radius: 25px !important;
    /* width: 90%; */
    /* margin-left: 5%; */
/*    line-height: 1rem;  */
}

.btn-group-display-header {
    height: 50px;
}
.btn-group-display-header button {
    height: 40px;
    font-size: 18px;
    font-weight: 400;
    line-height: 0;
}
/*.btn-group-content-graph.backgroundCloneBtns .btn-default.active,
.btn-group-content-graph.backgroundCloneBtns .btn-default:active
{
    color: #fff;
    background: blue !important;
    outline: none;
    border-radius: 25px !important;
    line-height: 1rem;
    margin-top: 5px;
    margin-bottom: 5px;
}*/

.active_selected_display{
  padding-bottom: 5px;
  padding-top: 5px;
}

/*category classes, progress/content bar specific*/
/*Either these or the btn group had to be rewritten*/
.noCategoryFont {
    color: #ccc;
}
.noCategoryDisplayHeader {
    background: #ccc !important; /* Old browsers */
}
.wydBGFont {
    color: #00325c !important;
}

.btn-group-display-header-2nd{
    padding-left: 5px;
}
.wydBGDisplayHeader {
    background: #00325c !important; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #3d7b96 0%, #00325c 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #3d7b96 0%,#00325c 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #3d7b96 0%,#00325c 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3d7b96', endColorstr='#00325c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.wydBGDisplayHeaderInactive {
    border-radius: 25px !important;
    transition: background-color 10ms;
}
.wydBGFont:hover {
    color: #00325c !important;
}
.wydBGDisplayHeaderInactive:hover {
    background: rgba(0, 50, 92, 0.2) !important;
    transition: background-color 10ms;
}
.wyeBGFont {
    color: #7eaf5b;
}
.wyeBGFont {
    color: #7eaf5b;
}
.wyeBGDisplayHeader {
    background: #7eaf5b !important; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #7eaf5b 0%, #6ca345 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #7eaf5b 0%,#6ca345 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #7eaf5b 0%,#6ca345 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7eaf5b', endColorstr='#6ca345',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.wyeBGDisplayHeaderInactive {
    border-radius: 25px !important;
    transition: background-color 10ms;
}
.wyeBGFont:hover {
    color: #7eaf5b !important;
}
.wyeBGDisplayHeaderInactive:hover {
    background: rgba(126, 175, 91, 0.2) !important;
    transition: background-color 10ms;
}
.whenyeBGFont {
    color: #c4b02d;
}
.whenyeBGDisplayHeader{
    background: #c4b02d !important; /* Old browsers */
    background: -moz-linear-gradient(5deg, #d4c45e 0%,#8e7f1a 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(5deg, #d4c45e 0%,#8e7f1a 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(185deg, #d4c45e 0%,#8e7f1a 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4c45e', endColorstr='#8e7f1a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.whenyeBGDisplayHeaderInactive {
    border-radius: 25px !important;
    transition: background-color 10ms;
}
.whenyeBGFont:hover {
    color: #c4b02d !important;
}
.whenyeBGDisplayHeaderInactive:hover {
    background: rgba(196, 176, 45, 0.2) !important;
    transition: background-color 10ms;
}
.scoreBGFont {
    color: #00325c;
}
.scoreBGDisplayHeader {
    background: #00325c !important; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #3d7b96 0%, #00325c 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #3d7b96 0%,#00325c 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #3d7b96 0%,#00325c 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3d7b96', endColorstr='#00325c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.scoreBGDisplayHeaderInactive {
    border-radius: 25px !important;
    transition: background-color 10ms;
}
.scoreBGFont:hover {
    color: #00325c !important;
}
.scoreBGDisplayHeaderInactive:hover {
    background: rgba(0, 50, 92, 0.2) !important;
    transition: background-color 10ms;
}
/**/

/**/

.btn.client-white,
.btn.back,
.btn.forward{
    background: #fff;
    font-size: 0px;
    width: 45px;
    padding: 1rem;
    height: 45px;
}

.btn.client-white:before,
.btn.back:before {
    content: '';
    width: 60%;
    height: 60%;
    left: -11px;
    top: 8px;
    position: relative;
    transform: rotate(-135deg);
    border-width: 3px 3px 0 0px;
    border-style: solid;
    border-color: #c4c4c4;
    transition: .2s ease;
    display: block;
    transform-origin: 100% 0;
}
.btn.forward:before {
    content: '';
    width: 60%;
    height: 60%;
    left: 5px;
    top: 8px;
    position: relative;
    transform: rotate(45deg);
    border-width: 3px 3px 0 0px;
    border-style: solid;
    border-color: #c4c4c4;
    transition: .2s ease;
    display: block;
    transform-origin: 100% 0;
}
.footer-actions .btn.primary:before,
.btn.add--icon:before {
    content: '';
    background: url(../images/icon-addcircle.png);
    height: 24px;
    width: 25px;
    display: inline-block;
    margin: -10px .5rem -7px -10px;
}
.footer-actions .btn.logout.primary:before {
    transform: rotate(135deg);
    margin: -11px .5rem -4px -10px;
}
.hide-button {
  background-color: black;
  color: white;
  display: none !important;

}

.show-button {
  background-color: white;
  color: black;
  text-align: right;
}

.cancel {
    margin-left: 195px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.exitBtn {
    position: fixed;
    right: 3.5%;
    top: 1.5%;
    font-size: 2rem;
    font-weight: bold;
    line-height: 40px;
    color: #000;
    cursor: pointer;
    background-color: rgba(255,255,255,1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    margin: 0;
    z-index: 1111;
    box-shadow: 0px 0px 10px rgba(0,0,0,.5);
}

/*******************************************
WIDGET CONTAINERS
********************************************/
.graph-container,
.widget-container,
.goal-container {
    border: none !important;
    max-width: none;
    margin: 0 auto;
    text-align: left;
    /* cursor: pointer; */
    height: auto  !important;
    padding: 1.5rem 2rem  !important;
    background: #fff  !important;
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    border-radius: 8px  !important;
}
.graph-container {
    margin: 5rem auto 0;
    padding-bottom: 65px !important;
}


/*******************************************
MODAL
********************************************/
.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 'auto';
    margin-right: -50%;
    bottom: 'auto';
    background-color: white;
    transform: translate(-50%, -50%);
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.modal-instructions {
    text-align: center;
}


/*******************************************
CONTENT MODAL
********************************************/
@media (max-width: 600px) {
    .modal {
        padding-left: 0 !important;
    }
    .intro-tile iframe.vimeo-iframe {
        height: 210px;
    }
    .content-modal iframe.vimeo-iframe {
        height: auto;
        max-height: 100%;
    }
    .content-modal {
        width: 100%;
    }
    .content-modal .modal-actions,
    .content-modal .modal-copy {
        padding: 1.5rem 2rem !important;
    }
}
@media (min-width: 601px) {
    .intro-tile iframe.vimeo-iframe {
        height: 450px;
    }
    .content-modal {
        width: 95%;
    }
    .content-modal .modal-actions,
    .content-modal .modal-copy {
        padding: 2rem 5rem !important;
    }
}
.content-modal {
    max-width: 770px; /* 750 + 20 for scrollbar */
    height: 100%;
}


.content-modal .hero-image {
    width: 100%;
    height: auto;
}
.content-modal .hero-image img {
    width: 100%;
    height: auto;
}
.content-modal .modal-body {
    padding: 0;
}
.content-modal .modal-copy {
    font-size: 16px;
    line-height: 1.5em;
}
.content-modal .modal-content {
    /*height: 90%;
    overflow-y: auto;
    border-radius: 8px;*/
    height: auto;
    max-height: 90%;
    overflow-y: auto;
    border-radius: 8px;
}
.content-modal img {
    max-width: 100%;
    height: auto;
}
.content-modal iframe.vimeo-iframe {
    background: #000;
}
.content-modal iframe.content-iframe {
    width: 100%;
}
.content-modal .modal-actions {
    background: #ecf2f6;
    padding: 1rem 5rem;
    margin-top: -5px;
}


.content-modal{width:95%; height:100%;}
.content-iframe{width:100%;}

.container.content-modal{width:auto;}


/*******************************************
ACTIVITY ADDED MODAL
********************************************/
.modal--added-activity {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 0;
    margin: 0;
}
.modal--added-activity .modal-content {
    border: none;
    border-radius: 0;
    height: 100%;
    text-align: center;
    padding: 2rem;
    background: #7eaf5b; /* Old browsers */
    background: -moz-linear-gradient(50deg, #7eaf5b 0%, #6ca345 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(50deg, #7eaf5b 0%,#6ca345 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #7eaf5b 0%,#6ca345 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    color: #fff;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bbc4e;
    fill: #4bbc4e;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: block;
    stroke-width: 4px;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #4bbc4e;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.alternateCheckmark{
    width: 120px;
    height: auto;
    display:block;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}


.confetti-overlay {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 100%;
    text-align: center;
    z-index: -1;
    overflow: hidden;
}
.confetti-overlay > div {
    position: absolute !important;
    bottom: -25% !important;
    left: 45%;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
/*******************************************
CSS/GRID FIXES
********************************************/
@media (min-width: 320px) {
    .app-container .container {
        width: 95%;
    }
    .tile-border-right {
        border-right: 1px solid transparent;
        height: auto;
        margin-bottom: 2rem;
    }

    .tile-border-right:last-of-type{
        border-right: none;
    }
}
@media (min-width: 992px){
    .tile-border-right {
        border-right: 1px solid #e5e5e5;
        margin-bottom: 0;
    }
}
@media (min-width: 1200px) {
    .app-container .container {
        max-width: 1600px;
    }
}



/*******************************************
???
********************************************/
.button-container {
  width: 100%;
}
.newTileButton {
  float: right;
}

.selected-tile {
    background-color: #354052 !important;
    transition: background-color 200ms linear !important;
    -webkit-transition: background-color 200ms linear !important;
    -o-transition: background-color 200ms linear !important;
}

.ul-container {
  display: inline-block;
  width: 100%;
}

.disabledCursor {
  cursor: default;
}

.font-white {
    color: white;
}

.enablePointer {
    cursor: pointer;
}

.client-search-slider-container .slick-slider .slick-prev {
    margin-left: 12px !important;
}

.client-search-slider-container .slick-slider .slick-next {
    margin-right: 12px !important;
}

.client-search-slider-container .slick-slider {
    padding-top: 0 !important;
}

.client-search-checkbox{
    display:inline-block;
}

.client-search-checkbox label{
    display:flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
}

.client-search-checkbox label input{
    width:auto;
    margin-right: 1rem;
}

.client-search-organizer .btn-container{
    padding: 0 15px;
}

#popover-positioned-left .arrow:before {
    display: none;
}


@media only screen and (min-width: 992px){
    .client-search-organizer{
        display:inline-block;
    }
}

@media only screen and (max-width: 992px){
    /*target bootstrap md col*/
    .client-search-organizer .flex-vertically{
        width:100%;
    }
}


.slick-prev:before, .slick-next:before{
    color: black !important;
    z-index: 100 !important;
}

.slick-slider{
    margin-bottom: 18px;
}

.slick-track{
    margin: 0 !important;
}

/*******************************************
PAGE HEADERS
********************************************/
.header-container{
    background: #fff;
    padding-bottom: 2em;
}
.page-header-container {}
.page-header-container,
.searchForm-container {
    height: auto;
    padding: 2em 0;
    background-color: #fff;
    border-bottom: 1px solid #dcdcdc;
    text-align: left;
    position: relative;
    width: 100%;
    padding-top: 0px !important;
}

.client-detail .page-header-container {
    border-bottom: none;
}

.header-unread-notification{
    background-color: #f93b3f;
    font-size: 10px;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    color: white;
    right: 10px;
    top: -5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coach-header-unread-notification{
    background-color: #f93b3f;
    font-size: 10px;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    color: white;
    right: 10px;
    top: -5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 767px){
    .coach-header-unread-notification,
    .header-unread-notification{
        right: 43%;
    }
}

/*******************************************
CONTENT - ADMIN
********************************************/
table.content-table {
    border-radius: 8px !important;
    margin-bottom: 4rem;
}
table.content-table thead {
    color: rgba(0,0,0,.5);
    font-weight: bold;
    margin-bottom: 1rem;
}
table.content-table tbody {
    border: none !important;
    max-width: none;
    margin: 0 auto;
    text-align: left;
    /* cursor: pointer; */
    height: auto !important;
    background: #fff !important;
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    border-radius: 8px !important;
}
.content-table>thead>tr>th,
.content-table>tbody>tr>td,
.content-table>tbody>tr>th,
.content-table>tfoot>tr>td,
.content-table>tfoot>tr>th,
.content-table>thead>tr>td,
.content-table>thead>tr>th {
    border: none;
    padding: 1rem 1.5rem;
}
.content-table tbody tr {
    cursor: pointer;
    background: #fff;
}
.content-table tbody tr:nth-child(even) {
    background-color: #ecf2f6;
    -webkit-transition: background-color .2s linear;
    -moz-transition: background-color .2s linear;
    -o-transition: background-color .2s linear;
    -ms-transition: background-color .2s linear;
    transition: background-color .2s linear;

}
.content-table tbody tr:hover {
    background-color: #4bbc4e;
    color: #fff;
    -webkit-transition: background-color .2s linear;
    -moz-transition: background-color .2s linear;
    -o-transition: background-color .2s linear;
    -ms-transition: background-color .2s linear;
    transition: background-color .2s linear;

}

/* TINYMCE THEME EDITS */
.mce-tinymce {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.mce-edit-area iframe { min-height: 350px !important; }
.editor--short-description .mce-edit-area iframe { height: auto !important; }


.form-group {
    margin-bottom: 2rem !important;
}
.radio-inline input[type=radio] {
    margin: -6px 0 0 -20px;
}
.checkbox-inline,
.radio-inline {
    margin-right: 2rem;
}

.tag_links a{
    float: right;
    padding-right: 1rem;
}

.tags-parent-category{
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-radius: 5px;
}

.new-tag-container{
  height: 500px;
  overflow-y: scroll;
}

.no-shadow input {
    box-shadow: none;
}

input.has-error,
select.has-error,
.has-error .select-form-control{
    border: 1px solid #a94442;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-radius: 5px;
}
/*******************************************
NOTES
********************************************/
.note-ul {
  list-style-type: none;
  padding: 0;
}

.note-li{
    border: 1px solid #e6e6e6;
    border-bottom: none;
    padding: 1rem 2.5rem;
    cursor: pointer;
}
.note-li:last-child {
    border-bottom: 1px solid #e6e6e6;
}
.note-li.current,
.note-li:focus,
.active-li-first:first-child {
    border-right: none;
    background: #fff;
}

.note-description{
    margin: 0;
}

.note-single-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.note-container  {
    background: #fff;
    padding: 2rem 0;
    border: 1px solid #e6e6e6;
    margin-left: -1px;
    min-height: 901px;
}

.no-list-style{
    list-style: none;
    padding-left: 0;
}

.inline-radio{
    width: 15%;
}

.padding-bottom{
    padding-bottom: 15px;
}

.notes-radio-container{
    padding: 0;
}


/*******************************************
CLIENT / COACH TILE OVERVIEW DISPLAY
********************************************/
.tile-header {
    margin: 1rem 0;
    font-size: 12px;
    color: #babfc3;
}

.tiles-ul {
    list-style: none;
    margin-top: 0;
    padding: 0;
}

.tiles-ul .tileContainer{
    display: flex;
    align-items: center;
    margin: 0 auto 3rem;
}

.tiles-ul .tileContainer .tile{
    margin-bottom: 0;
    margin-left: 15px;
}

.tiles-ul .tileContainer .tile-updated{
    position: absolute;
    z-index: 1;
    background: rgba(255, 255, 255, .9);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 21px;
    opacity: 1;
    transition: opacity 1.5s ease;
    font-weight: bold;
    color: #4bbc4e;
    padding-left: 20px;
}

.tiles-ul .tileContainer .tile-updated.stash{
    opacity: 0;
}

.tiles-ul .tileContainer .tile-updated.zIndex{
    z-index: -1;
}

.tiles-ul .tile {
    position: relative;
    overflow: hidden;
    flex: 1;
    margin: 0 auto 3rem;
    border: none;
    max-width: none;
    text-align: left;
    cursor: pointer;
    height: auto;
    padding: 1.5rem 2rem;
    background: #fff;
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
    border-radius: 10px;
}

.tiles-ul .tile.selected{
    -webkit-box-shadow: -1px 6px 33px 2px rgba(75,188,78,1);
    -moz-box-shadow: -1px 6px 33px 2px rgba(75,188,78,1);
    box-shadow: -1px 6px 33px 2px rgba(75,188,78,1);
}

.intro-tile-border{
    border: 1px red solid !important;
}

@media (max-width: 767px) {
    .tiles-ul .tile.tile--dashboard {
        border-radius: 10px;
    }
}
@media (min-width: 768px) {
    .tiles-ul .tile.tile--dashboard {
        border-radius: 60px;
    }
}
.tile--dashboard.user_inactive,
.user_inactive .client-search-slider-container .slick-slider{
    background-color: #e1e1e1;
}
.profile-image, .status-image {
    float: left;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    background: #fff;
    overflow: hidden;
    margin-bottom: 10px;
}
.tile-icon.coaching-image {
    padding: 7px 8px;
}
.tile-icon.coach-single > .profile-image {
    position: absolute;
    top: 7px;
    left: 8px;
}
.tile-icon.coach-single > .profile-image .profile-image {
    position: absolute;
    top: 0;
    left: 0;
}
.coaching-team .profile-image {
    top: 0;
    left: 0;
    position: relative;
}

.coach-profile .profile-image

/*.coaching-team .profile-image .profile-image  {
    top: -4px;
    left: -4px;
    position: relative;
}*/
.profile-image.-list {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 25px;
}
.admin-profile { margin-top: 1.5em; }
.admin-profile-image .profile-image.-list {
    width: 35px;
    height: 35px;
    margin-left: 25px;
    margin-bottom: -15px;
    border: 2.5px solid #fff;
    box-shadow: none;
}

.responsive-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.profile-image img {
    width: 75px;
    max-width: 75px;
   /* border-radius: 50%;
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    margin-right: 2rem;*/
    vertical-align: baseline;
}

.profile-image.-list img {
    width: 100%;
    height: auto;
    max-width: none;
    border-radius: 0%;
}
.client-detail .page-header-container .profile-image img {
    width: 150px;
    max-width: 150px;
}
.client-detail .tile .profile-image img {
    box-shadow: none;
}

.client-detail .profile-name {
    font-size: 1.75rem;    
    display: block;
	text-align: left;
}
@media (max-width: 767px) {
	.client-detail .profile-name { text-align: center;}	
}
.client-detail .profile-name .name {
	display: inline-block;
    margin: 0 0 10px 0;
	font-family: "aaux-next";
    font-weight: 400;
	text-transform: none;
}
.client-detail .profile-name .age { 
	font-family: "aaux-next";
    font-weight: 400;
    display: inline-block;
    margin: 0 0 0 1rem;
}
.client-detail .account-details {
	font-family: "aaux-next";
    font-weight: 400;
}
.client-detail .unit-label {
	font-size: 16px;
    margin-top: .25rem;
}
.client-detail .unit-added-details {
	font-style: italic;
    color: #eebf38;
	font-size: 12px;
}
.client-detail .start-datepicker {
	display: inline-block;
	width: auto;
}
.client-detail .start-datepicker input {
    border-radius: 11px;
    padding: 5px 25px;
    font-size: 12px;
    border: 1px solid #d1d1d1;
    cursor: pointer;
    display: inline-block;
}

.client-detail .subHeader-container {}
.client-detail .subHeader-container select {
    width: 100%;
    padding: 1rem;
	margin-bottom: 1rem;
    border-radius: 8px;
}
/* tile ul fix
.tiles-ul .profile-image {
    float: none;
    position: absolute;
    right: -4px;
    top: 9px;
}*/
.client-dash .tiles-ul .tile,
.client-account-dash .tiles-ul .tile {
    min-height: 108px;
}

.status-image {
    max-width: 75px;
    margin-right: 2rem;
    vertical-align: baseline;
}
.profile-container { display: inline-block;}
.profile-name {
    font-size: 1.75rem;
/* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.profile-name .name{display: inline-block; margin-right: 1rem;}
.profile-name .age{display: inline-block;}
.profile-container p { margin-bottom: 0;}

.plan-name { font-size: 1.5rem; }
.plan-category { font-size: 1rem; }
.plan--units {
    margin-top: 4rem;
}

.wellness-circle .circle-outer .VictoryContainer {
    width: 125% !important;
    height: 125% !important;
    touch-action: none;
    position: absolute !important;
    top: -15px !important;
    left: -15px !important;
}
@media (max-width: 600px) {
    .wellness-circle .circle-outer .VictoryContainer{
        width: 133% !important;
        height: 133% !important;
    }
}
.victory-container{
    width:120px;
    position: relative;
}
.admin-dash--summary .victory-container {
    width: 150px;
    max-width: 150px;
}
.profile-image.admin-dash--summary{
    float: left;
     height: auto;
     width: auto;
     border-radius: 0;
     -webkit-box-shadow: 0;
     box-shadow: none !important;
     margin-right: 0;
     overflow: visible;
}
.victory-container .client-summary-profile {
    height: 150px;
    width: 150px;
    overflow: hidden;
    border-radius: 50%;
}
.admin-dash--summary .victory-container .victory-circle {
    width: 210px;
    height: 210px;
    position: absolute;
    left: -28px;
    right: 28px;
    top: -30px;
}
/* this is for the smaller header version of the profile image */
.client-header-links .victory-container {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1rem;
    width: 38px;
    /*margin-top: -20px;*/
}
.client-header-links .victory-container .profile-image.-list {
    width: 35px;
    height: 35px;
    border: 2.5px solid #fff;
    box-shadow: 1px 3px 7px rgba(0,0,0,.3);
}

.victory-circle{
    position:absolute;
    left:-22px;
    right:22px;
    top:-22px;
}

.profileInput{
    display: none !important;
}
/*******************************************
CLIENT / COACH DETAIL VIEW
********************************************/

.subHeader-container {
    display: flex;
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #dcdcdc;
}
ul.subheaderNav {
    list-style: none;
    margin: 0 0 -1px 0;
    padding: 0;
}
ul.subheaderNav li {
    display: inline-flex;
    margin: 0 auto;
}
ul.subheaderNav li a {
    border: 1px solid #fff;
    border-bottom: 1px solid #dcdcdc;
    padding: 1rem 3rem;
    margin-right: 1rem;
    text-decoration: none;
    color: #ccc;
    font-size: 1.5rem;
}
ul.subheaderNav li a:hover,
ul.subheaderNav li.current a {
    background: #edf2f6;
    color: #616161;
    border: 1px solid #dcdcdc;
    border-bottom: 1px solid #edf2f6;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}



/*******************************************
COACHING TEAM / TEAMMATE CARDS
********************************************/

.coach-profile {
    font-size: 16px;
}
.coach-profile .profile-image {
    position: absolute;
    top: 4px;
    left: 4px;
}
.coach-profile h4 {
    text-transform: uppercase;
    font-size: 20px;
}
.coach-profile h4.margin-top { margin-top: 3rem !important; }
.coach-profile ul {
    margin: 1rem 0;
    padding: 0;
    list-style-position: inside;
}



.support-team-container{
    flex-wrap: wrap;
    justify-content: start !important;
}

.support-member {
    display: inline-block;
    border: none !important;
    max-width: none;
    text-align: center;
    cursor: pointer;
    height: auto  !important;
    padding: 0;
    background: #fff  !important;
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    border-radius: 8px  !important;
    transition: .2s all;
}
@media (max-width: 700px) {
    .support-member {
        width: 45%;
        margin: 2.5%;
    }
    .support-img {
        width: 100%;
        height: 120px;
        overflow: hidden;
    }
}
@media (min-width: 701px) {
    .support-member {
        width: 31.25%;
        margin: 1%;
    }
    .support-img {
        width: 100%;
        height: 150px;
        overflow: hidden;
    }
}
@media (min-width: 961px) {
    .support-member {
        width: 22.5%;
        margin: 1.25%;
    }
    .support-img {
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
}

.support-member:hover {
    background:  rgba(0,0,0,.1) !important;
    transition: .2s all;
    color: #333 !important;
}

.support-info { padding: 2rem;}
.support-name { font-size: 2rem;}
.support-role { font-size: 16px; }
.add-new-support{
    text-align: center;
    border: 2px dashed grey !important;
    cursor: pointer;
    background: transparent !important;
}

.add-new-support:hover{
    background: #D3D3D3;
}

.float-right {
    float: right;
}


/*******************************************
INBOX
********************************************/
/* inbox tiles - client */
.inbox--container {}

.inbox--container .slider-thumbnail {
    height: 140px !important;
    width: 100% !important;
    max-width: 200px !important;
}
.inbox--container .slick-slide img {
    height: 100%;
    width: auto;
}
.inbox--container .tile {
    cursor: pointer;
}
.icon--left .tile-icon {
    right: auto;
    left: 10px;
}
.icon--left .tile-icon .profile-image.-list {
    margin-top: -8px;
    margin-left: -7px;
}
.icon--left .tile-titles {
    width: 70%;
    margin-left: 70px;
}
.inbox--container .tile-titles h4 {
    font-size: 16px;
    text-transform: uppercase;
}
.inbox--container .archived-tile .tile-titles h4,
.inbox--container .archived-tile .tile-titles p { color: rgba(0,0,0,.4); }

.inbox--container h4 {
    font-size: 20px;
    text-transform: uppercase;
    display: block;
    margin: 0 0 .5rem 0;
}
.inbox--container .tile-titles p {
    font-size: 14px;
}
.inbox--container.single-message {
    margin-bottom: 3rem;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 3rem;
}
.inbox--container .message--body {
    margin: 2rem 0;
    font-family: "aaux-next";
    font-weight: 400;
    font-size: 16px;
}

/* new message coach select */
.recipient--container {
    margin:0 0 2rem;
}
.recipient--container .victory-container {
    width: 120px;
    cursor: pointer;
    left: 5px;
}
.recipient--container .profile-image {
    float: none;
    margin: 0 1rem 0 0;
    display: inline-block;
}
.recipient--container .profile-image img { margin: 0;}

.reply-container {
    margin-bottom: 2rem;
}
.reply-container.large { margin-bottom: 5rem; }
.reply-container textarea {
    width: 100%;
    max-width: 100%;
    border-radius: 4px;
}

.reply-media{
    border:dashed;
    border-color: gray;
    padding-bottom: 4rem;
    margin-top: 1rem;
}

.reply-media-text{
    padding-left: 2rem;
}

.inbox-container  {
    background: #fff;
    border: 1px solid #e6e6e6;
    margin-left: -1px;
    min-height: 1200px;
}

.note-li.active-li {
    border-right: none;
    background: #fff;
}

.unread-amount,
.unread-amount, .unread-admin {
    border: 1px solid red;
    background: red;
    color: white;
    position: absolute;
    transform: translateY(50%);
    top: 24px;
    left: -7px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 10px;
    line-height: 30px;
    text-align: center;
    z-index: 1;
}

.unread-admin{
    top: 0 !important;
    font-size: 12px !important;
    line-height: 29px !important
}

.profile-image-client-inbox {
    /*padding-left: 10px;
    padding-right: 10px;*/
    display: inline-block;
    text-align: center;
    border-radius: 18px;
    padding: 1rem 1rem .5rem;
    margin-right: 1rem;
    background: transparent;
    cursor: pointer;
    transition: background .2s linear;
}
.profile-image-client-inbox:hover {
    background: rgba(255,255,255,.5);
    transition: background .2s linear;
}
.reply-label span {
    font-family: "aaux-next";
    font-weight: 400;
    font-size: 1.5rem;
}
.inbox-ul{
    margin:auto -15px;
}
.inbox-thread{
    padding:2rem;
    border-top:1px solid #cccccc;
    border-left:1px solid #cccccc;
    position:relative;
}
.inbox-thread.active-thread{
    background: #fff;
}

.inbox-thread:last-of-type{
    border-bottom:1px solid #cccccc;
}

.inbox-thread ._date{
    float:right;
}


.inbox-thread ._title{
    margin:0;
    text-transform: capitalize;
}

.inbox-message{
    padding: 2.5rem 1.5rem;
    border-top: 1px solid #ccc;
}

.inbox-message:first-of-type{
    border:none;
}

.reply-button{
    float:right;
}

.inbox-new .btn{
    margin-right: 1.5rem;
    margin-top:1.5rem;
}

.inbox-new ._text{
    width:100%;
}

._archiveBtn{
    margin: 1.5rem auto;
    display: block;
}

.inbox-pagination{
    margin:1rem auto;
    text-align: center;
}

.inbox-meta{
    font-size: 12px;
    font-family: aaux-next;
    font-weight: 400;
}
/*******************************************
CONTENT
********************************************/

.mce-notification.mce-notification-warning{
    display: none;
}

#content-blog.hidden, #content-file.hidden, #content-link.hidden{
    max-height:0;
    transition:max-height .015s ease-out;
    overflow: hidden;
}

#content-blog, #content-file, #content-link{
    max-height: 10000px;
    transition: max-height .025s ease-in;
}


.content-tab{
    display: inline-block;
    padding: 1em;
    border: none;
    color: rgba(0,0,0,.6);
    border-radius: 12px 12px 0 0;
    border-bottom: white;
}

.content-tab.active{
    background:#fff;
    color: #000;
}
.slick-slider {
    background: #fff;
    padding: 1rem 1rem 0;

}
.inbox--container .slick-slider {
    background: transparent;
    padding: 0;
}
.slick-slide {
    padding: 0 1rem;
    cursor: pointer;
}
.slick-slide h5 {
    font-family: "aaux-next";
    font-weight: 400;
    font-size: 16px;
}
.slider-thumbnail {
        height: 140px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
}

.selected_coach {
   background: #fff;
  transition: background .2s linear;
}
/*******************************************
POLICIES
********************************************/
.client-container.policy-container {
    max-width: 600px;
    padding: 0 2rem;
    margin: 0 auto;
}
.policy-container .alert {
    max-width: none;
}
.policy-container h1 { margin-top: 4rem; }
.policy-container p {
    font-size: 16px;
}
.policy-container .label input[type=checkbox],
.policy-container .label input[type=radio] {
    width: 35px;
    margin:0;
}
.policy-container .label {
    display: flex;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: transparent;
    margin-bottom: 1.5rem;
    transition: .2s all;
    cursor: pointer;
    white-space: break-spaces;
    color:#000;
}
.policy-container .label:hover {
    background-color: #4bbc4e;
    border-color: #4bbc4e;
    color: #fff;
    transition: .2s all;
    curson: pointer;
}
.policy-container .label label:hover{
    cursor: pointer;
}
.policy-container .details {
    text-decoration: underline;
}
/*******************************************
POLICIES
********************************************/
.policy-versions {
    background: rgba(0,0,0,.05);
    padding: 2rem;
    border-radius: 4px;
}
.policy-versions ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.policy-versions li {
    cursor: pointer;
    text-decoration: underline;
}



/*******************************************
Tile Creation
********************************************/
.tile-creation {}

.tile-creation .progressbar {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    border-radius: 25px;
    border: 1px solid #efefef;
}
.progressbar li {
    margin: .75rem;
    width: auto !important;
    min-width: 180px !important;
    transition: .2s all;
}
.tile-creation .progressbar li:before {
    display: none;
}
.progressbar li.active,
.progressbar li.active:hover {
    background: #354052;
    color: #fff !important;
    padding: .75rem 1rem;
    border-radius: 20px;
    transition: .2s all;
}
.progressbar li:hover {
    /*background: rgba(0,0,0,.1);
    padding: .75rem 1rem;
    border-radius: 20px;
    transition: .2s all;*/
    cursor: not-allowed;
}

.tile-creation--container{
    margin-top: 3rem;
    flex-wrap: wrap;
    justify-content: start !important;
}
.tileCategories1, .tileCategories2, .tileCategories3 {}
.tile-card {
    border: none !important;
    max-width: none;
    width: 22.5%;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    height: auto  !important;
    padding: 4.5rem 2rem  !important;
    background: #fff  !important;
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    border-radius: 8px  !important;
    transition: .2s all;
}
.tile-custom.tile-card {
    background: transparent !important;
    border: 2px dashed #354052 !important;
}
.tile-card:hover {
    background:  rgba(0,0,0,.1) !important;
    transition: .2s all;
    color: #333 !important;
}
.tile-card.tile-selected,
.tile-card.tile-selected:hover,
.tile-custom.tile-card.tile-selected,
.tile-custom.tile-card.tile-selected:hover {
    background-color: #4bbc4e !important;
    color: #fff !important;
    padding: 4.5rem 2rem  !important;
    text-align: center;
    border: none;
    cursor: pointer;
    transition: .2s all;
    width: 22.5%;
    margin: auto 10px !important;
}


.tile-creation .goal-container {
    margin-bottom: 2rem;
}

/*.tile-creation .goal-bar-container .rc-slider-rail {
    background: #ff3232 !important;
    background: -moz-linear-gradient(left, #ff3232 0%, #ff3232 49%, #e8db2c 50%, #13c400 51%, #13c400 51%, #13c400 100%) !important;
    background: -webkit-linear-gradient(left, #ff3232 0%,#ff3232 49%,#e8db2c 50%,#13c400 51%,#13c400 51%,#13c400 100%) !important;
    background: linear-gradient(to right, #ff3232 0%,#ff3232 49%,#e8db2c 50%,#13c400 51%,#13c400 51%,#13c400 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3232', endColorstr='#13c400',GradientType=1 ) !important;
}*/

.rc-slider-handle {
    margin-left: -20px !important;
}
.tile-creation .goal-bar-container,
.client-detail .goal-bar-container {
    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    border-radius: 80px;

    background: -webkit-linear-gradient(left, #ff3232 0%,#fff130 51%,#27dd1a 100%);
    background: -webkit-gradient(linear, left top, right top, from(#ff3232),color-stop(51%, #fff130),to(#27dd1a));
    background: -o-linear-gradient(left, #ff3232 0%,#fff130 51%,#27dd1a 100%);
    background: linear-gradient(to right, #ff3232 0%,#fff130 51%,#27dd1a 100%);
}
.tile-creation .rc-slider-mark,
.client-detail .rc-slider-mark {
    top: 28px;
}
.tile-creation .goal-bar-container .rc-slider-handle-1,
.client-detail .goal-bar-container .rc-slider-handle-1{
    transform:translateX(0) !important;
    background: red; /* this should be set dynamically based on slider position */
    width: 45px;
    height: 45px;
    margin-top: -20px;
    border: 4px solid rgba(0,0,0,.3);
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    -moz-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
}
.tile-creation .goal-bar-container .rc-slider-handle-2,
.client-detail .goal-bar-container .rc-slider-handle-2 {
    transform:translateX(0) !important;
    background: green; /* this should be set dynamically based on slider position */
    width: 45px;
    height: 45px;
    margin-top: -20px;
    border: 4px solid rgba(0,0,0,.3);
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    -moz-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
}

.tile-creation .goal-bar-container .rc-slider-handle:focus,
.tile-creation .goal-bar-container .rc-slider-handle:active,
.client-detail .goal-bar-container .rc-slider-handle:focus,
.client-detail .goal-bar-container .rc-slider-handle:active{
    border: 4px solid rgba(0,0,0,.4);
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    -moz-box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,.5), inset 0px 0px 10px 0px rgba(0,0,0,0.8);
    outline: none;
}
.tile-creation .goal-bar-container .rc-slider-handle:hover,
.client-detail .goal-bar-container .rc-slider-handle:hover {
    border: 4px solid rgba(0,0,0,.4);
}


/*******************************************
PATHWAYS CONTENT
********************************************/
.pathways-container {}
.pathways-container .client-dash {	}
.pathways-container .client-dash.no-overview { padding-top: 2.5rem;}
.pathways-container .wellness-bg.wydBG {
	padding: 100px 0 30px;
	background-position: top center;
}

.pathways-container .wellness-bg.wydBG h2 {margin: 0;}
.pathways-container .wellness-bg.wydBG p {padding-bottom: 2rem; font-size: 16px;}

.pathways-container .progress {
	height: 12px;
	width: 200px;
}
.pathways-container .wellness-bg .progress {margin-top: 1rem;}
.pathways-container .wellness-bg p.progressbar-p {font-size: 12px; margin: 0;}

.pathways-container .pathway-tile {
	color: #999;
	font-size: 12px;
    font-weight: 400;
	box-shadow: 0px 0px 15px rgba(0,0,0,.1);
    border-radius: 8px;
	/*border: 4px solid #fff;*/
    padding: 1.5rem 1rem;
	margin-bottom: 1.5rem;
	transition: .2s border linear;
	background-color: #fff;
	display: flex;
    flex-wrap: wrap;
	overflow: hidden;
	background-size: auto;
    background-position: top right;
}
.pathways-container .pathway-tile.video {
	/*background: #43ab46;
	border-color: #43ab46;*/
	color: #fff !important;
	margin-top: -15px;
	margin-bottom: 2.5rem;
	position: relative;
	overflow: inherit;

    /* border: 1px solid #6BA543; */
    background-color: #6BA543;
    background-image: url(/static/media/noodle-overlay.ec734ca7.png);
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center right;
}
.pathways-container .pathway-tile.video .popout {
    position: absolute;
    top: -13px;
    left: 20px;
    background: #d63f3f;
    border-radius: 6px;
    padding: .25rem 1.5rem;
	box-shadow: 0 0 7px rgba(0,0,0,.3);
}
.pathway-tile:hover {
    text-decoration: none;
    /*border: 4px solid #c0e0c2;    */
	transition: .2s border linear;
}
.pathways-container .pathway-tile p {
	margin: 0;
}
.pathways-container .pathway-tile .title {
	font-size: 18px;
	font-weight: 800;
	margin-bottom: 1rem;
	color: #333;
}
.pathways-container .pathway-tile.video .title {
	color: #fff;
	margin-bottom: 0;
}
.pathways-container .pathway-tile .pathway-icon img {
	width: 40px;
	height: 40px;
    margin-top: 8px;
}
.pathways-container .pathway-tile .pathway-icon i { font-size: 2.5rem; }
.contentLinkContainer{
    display: flex;
    flex-wrap: wrap;
}
.contentLinkContainer .contentLink{
    margin-bottom: 1.5rem;
}
/*******************************************
PATHWAY REPORT
********************************************/
.pathwayreport-container { font-size: 16px; margin-top: 3rem; }
.pathwayreport-container p { font-size: 16px; }
.pathwayreport-container h1 { text-transform: inherit; font-size: 3rem;}
.pathwayreport-container h4 { font-size: 22px; margin-bottom: 2rem;}
.pathwayreport-container .lifestyleHeadings { display: flex; justify-content: center;}
.pathwayreport-container .content-container {
	background: #fff;
    box-shadow: 0 4px 10px rgba(0,0,0,.1);
    padding: 2rem;
    border-radius: 18px;
    margin-bottom: 5rem;
}

.pathwayreport-container .wellness-circle {
    position: relative;
    height: 160px;
    width: 160px;    
    margin: -10px auto 0;
}
.pathwayreport-container .wellness-circle .circle-inner {
	background: #fff;
    border-radius: none;
}
.pathwayreport-container .legend-icon {
	margin: 0 auto;	
	width: 25px;
	height: 25px;
	background: #333;
	border-radius: 50%;
}
.pathwayreport-container .legend-icon.healthy { background-color: #5da046; }
.pathwayreport-container .legend-icon.small-opportunity { background-color: #f3d364; }
.pathwayreport-container .legend-icon.large-opportunity { background-color: #d65241; }
@media screen and (max-width:767px) {
	.pathwayreport-container .legend-label { font-size: 12px; }
}
@media screen and (min-width:768px) {
	.pathwayreport-container .legend-label { font-size: 16px; }
}

.pathwayreport-container .circle-inner { top: 22px; left: 37px; }
.pathwayreport-container .circle-outer { top: 0; left: 0;}
.pathwayreport-container .wellness-circle .circle-outer .VictoryContainer { left: 0 !important;}

/* health goals */

.pathwayreport-container .health-goal-container {
	/* border: 4px solid #5da046; */
    border-radius: 18px;
    padding: 3rem 4rem;
    margin-bottom: 5rem;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0,0,0,.1);
}
@media screen and (max-width:968px) {
	.pathwayreport-container .health-goal-container .goal-icon {
		width: 100%;
		text-align: center;
		position: relative;
		left: 0;
		top: 0;
		margin: 0;
	}
	.pathwayreport-container .health-goal-container .goal-icon img {
		height: 150px;
	}
    .pathwayreport-container .health-goal-container{
        text-align: center;
    }
    .pathwayreport-container .health-goal-container ul{
        text-align: left;
    }
}
@media screen and (min-width:969px) {
	.pathwayreport-container .health-goal-container {
		padding: 3rem 4rem 3rem 9rem;
	}
	.pathwayreport-container .health-goal-container .goal-icon {
		margin-top: 0;
		position: absolute;
		left: -70px;
		top: 50%;
		margin-top: -75px;
	}
	.pathwayreport-container .health-goal-container .goal-icon img {
		height: 150px;
		background: #fff;
    	border-radius: 50%;
    	padding: 0.5rem;
    	box-shadow: 0 4px 10px rgba(0,0,0,.1);
	}
}
.pathwayreport-container .health-goal-container h3 { margin-bottom: 2rem;}

.pathwayreport-container .flex-row-offset {
	margin: 2rem -15px;
}
.pathwayreport-container .additional-goals-container {
    border-radius: 18px;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    text-align: center;
	background: #fff;
    box-shadow: 0 4px 10px rgba(0,0,0,.1);
}

/* fundamentals */
.fundamental-key {
	border: 3px solid #ccc;
	border-radius: 25px;
	color: #ccc;
	padding: 1rem;
	display: flex;
	justify-content: center;
	margin-bottom: 2rem;
    flex-wrap: wrap;
}
.fundamental-key div {
	margin: 0 auto;
	text-align: center;
	padding: 0.25rem 1rem;
}
.when-you-eat h2 { color: #b2a67f;	}
.when-you-eat .fundamental-key { border-color: #b2a67f; color: #b2a67f;	}
.what-you-eat h2 { color: #548235;	}
.what-you-eat .fundamental-key { border-color: #548235; color: #548235;	}
.what-you-do h2 { color: #4f87a0;	}
.what-you-do .fundamental-key { border-color: #4f87a0; color: #4f87a0;	}

/* primary pathway */
.research-container  {
	display: flex;    
    flex-flow: wrap;
}
.research-item {
	display: flex;
    justify-content: flex-start;
	margin-bottom: 4rem;
}
.research-item .research-icon {
	width: 50px;
    height: 50px;
    border-radius: 50%;
	flex-shrink: 0;
    margin-right: 1rem;
	/* temp */
    background: #fff;
}
.research-item .research-text {}

/* next steps */
.success-container { 
	display: flex;
    margin: 2rem 0;
}
.success-container ul {
	padding: 0 0 0 2rem;
}
.success-container a {
	color: #4bbc4e;
	text-decoration: underline;
}
.success-container .step-number {
	width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 50%;
    color: #4bbc4e;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    margin-right: 2rem;
    flex-shrink: 0;
    border: 2px solid #4bbc4e;
    background: #fff;
}
.success-container .step-text {}
.pathwayreport-container .references-link {
    display: block;
    color: #666;
    text-decoration: underline;
    margin-top: -3rem;
    margin-bottom: 1rem;
}
.pathwayreport-container a:hover {
	cursor: pointer;
}

/* primary path */
.primary-pathway .goal-icon,
.primary-pathway .goal-tiles {}

.primary-pathway .primary-pathway-examples {
	margin-top: 5rem;
}

/* references */
.reference-container .col-xs-12.col-md-6 {
	width: 100%;
	font-size: 1rem;
	margin-bottom: 2rem;
}
.reference-container .col-xs-12.col-md-6 p {
	font-size: 1.25rem;
	margin-bottom: 0;
}
.reference-container .col-xs-12.col-md-6 a {
	line-break: anywhere;
	pointer-events: none;
    color: #333;
    font-size: 1.25rem;
}
#popover-lifestyle.top {
    top: auto;
}
#popover-lifestyle .arrow:before {
    display: none;
}
.lifestyle-glyph{
    font-size: 16px;
    margin-left: 5px;
    color: #ABA6A2;
    position: absolute;
    top: 15px;
}
@media screen and (max-width: 768px) {
    .lifestyle-glyph{
        font-size: 20px;
        margin-top:-5px;
    }
}
/*******************************************
ASSESSMENTS
********************************************/

.assessment-modal{
    width:90%;
    height:100%;
}

.assessment-modal .modal-content{
    height:95%;
}

.assessment-section{
    -webkit-box-shadow: -4px 2px 17px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: -4px 2px 17px -5px rgba(0,0,0,0.75);
    box-shadow: -4px 2px 17px -5px rgba(0,0,0,0.75);
    padding:1rem;
    margin:1rem 0 .5rem 0;
}

.assessment-section:hover{
    -webkit-box-shadow: -4px 2px 17px -5px rgba(112,124,138,1);
    -moz-box-shadow: -4px 2px 17px -5px rgba(112,124,138,1);
    box-shadow: -4px 2px 17px -5px rgba(112,124,138,1);
}

.assessment-section .subsection{
    margin: 1.5rem 0 1.5rem 1.25rem;
    border-left: #cccccc 4px solid;
    padding: 1.25rem;
}

.question-container{
    position: relative;
    margin-bottom: 3rem;
}

.question-container .disabled{
    position: absolute;
    width:100%;
    height:100%;
}
.__react_component_tooltip.show{
    max-width: 250px;
    border-radius: 5px;
    opacity: 1 !important;
    -webkit-box-shadow: 1px 0px 10px -4px rgba(0,0,0,0.4);
    -moz-box-shadow: 1px 0px 10px -4px rgba(0,0,0,0.4);
    box-shadow: 1px 0px 10px -4px rgba(0,0,0,0.4);
}
h3.question--title {
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
}
h4.question--description {
    font-weight: 400 !important;
    font-size: 18px !important;
    margin-bottom: 3rem;
    font-family: "aaux-next";
}
.question-container h4 {
    font-size: 18px;
    font-weight: 600;
}
.question-container h3 {
        font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
}
/* UI element style */
/* The container */
.multi-container {
    display: block;
    position: relative;
    margin-bottom: 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.multi-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 5px;
}
.text--item textarea {
    width: 100%;
    min-height: 100px;
    border-radius: 8px;
    max-width: 100%;
}
/* Create a custom radio button */
.checked {
    position: relative;
    /* top: 0; */
    /* left: 0; */
    display: block;
    margin: 0 auto;
    height: 28px;
    width: 28px;
    background-color: transparent;
    border-radius: 6px;
    border: 2px solid #ccc;
}

/* On mouse-over, add a grey background color */
.multi-container:hover input ~ .checked {
    background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.multi-container input:checked ~ .checked {
    border-color: #4bbc4e;
}
.answer-text { font-size: 14px; display: block; margin-top: .5rem; font-weight: 400;}
.answer-description {font-size: 10px; font-weight: 200; display: block;}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checked:after {
    content: "";
    position: absolute;
    display: none;
    width: 8px;
    left: 8px;
    top: 2px;
    height: 16px;
    border: solid #4bbc4e;
    border-width: 0px 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Show the indicator when checked */
.multi-container input:checked ~ .checked:after {
    display: block;
}

.onboarding p {
    font-size: 16px;
}
.onboard-start{
    text-align: center;
}

.assessments-form-container{
    width: 100%;
}

/* Style the indicator (dot/circle)
.multi-container .checked:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
} */

#root.leadquizes .app-container{height:100%;}
#root.leadquizes .client-container{height:100%;}
#root.leadquizes .leadquizesContainer{height:100%;}
#root.leadquizes .client-container .footer-actions{position: relative;}
#root.leadquizes .client-container .footer-actions button{margin-top: -2rem;}


/*@media screen and (min-width:1024px) {*/
    /*.leadquizesContainer{*/
        /*height:700px;*/
    /*}*/
/*}*/


/* ASSESSMENTS 2.0 - JRG 4/20/20 */

.assessment-container h1 { font-size: 2.5rem; }

.assessment-form ul { padding: 0;}
.assessment-form .accordion {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 0 2rem;
    overflow: hidden;
}
.assessment-form .accordion__heading {
    margin: -.5rem -2rem 0;
    background: rgba(0,0,0,.065);
    padding: 1rem 2rem;
}
.assessment-form h4 {
	text-transform: uppercase;
    margin-bottom: 0;
}
.assessment-form .accordion__button {
	outline: none;	/* removes blue browser outine when clicked on */
}
.assessment-form .accordion__panel {
	position: relative;
    padding: 3rem 4rem;
}
.form-group.form-header {
	background: rgb(79, 134, 160);
    color: #fff;
    border-radius: 8px;
    padding: 2rem 2rem 3rem;
    margin: -2rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.form-group.form-header label {
	font-family: "din-condensed";
    font-weight: 400;
	font-size: 2.5rem;
}
.assessment-form .btn.delete {
	position: absolute;
    top: -58px;
    right: 0;    
    font-size: 14px;
	background: #D90306;
	box-shadow: none;	
}
.assessment-form .text-delete {
	color: #D90306;
	text-decoration: underline;
	cursor: pointer;
}

.assessment-container h1 { margin-top: 2rem; text-transform: inherit; }
.assessment-container .media { margin: 1rem auto; }
.assessment-container .media img { border-radius: 8px; }
.assessment-container p {
	font-size: 16px;
}
.assessment-container label.flex{
    display: flex;
}
.assessment-container label input[type=checkbox], 
.assessment-container label input[type=radio] {
	width: 35px;
}
.assessment-container label {
	display: block;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: transparent;
    margin-bottom: 1.5rem;
	transition: .2s all;
	cursor: pointer;
}
.assessment-container label:hover {
    background-color: #4bbc4e;
    border-color: #4bbc4e;
    color: #fff;
	transition: .2s all;
}

@media screen and (max-width: 767px) {
    .assessment-container label:hover {
        background-color: transparent;
        border-color: #ccc;
        color: inherit;
    }
}

.assessment-results-profile {
	margin-bottom: 2rem;
	padding-bottom: 2rem;
	font-size: 16px;
}
.assessment-results-profile:after {
    content: "";
    display: block;
    width: 50px;
    height: 6px;
    background: rgba(0,0,0,.1);
    margin: 4rem auto 0;
    border-radius: 5px;
}

/* progress bar */
.progress {
	height: 20px;
    margin-bottom: .5rem;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 6px;
}
.progress-bar {	
	background-image: -webkit-linear-gradient(top,#4bbc4e  0,#43ab46 100%);
    background-image: -o-linear-gradient(top,#4bbc4e 0,#43ab46 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#4bbc4e),to(#43ab46));
    background-image: linear-gradient(to bottom,#4bbc4e 0,#43ab46 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4bbc4e', endColorstr='#43ab46', GradientType=0);
}


/*******************************************
CALENDAR
********************************************/
.calendar{
    background-color: white;
    border: 1px #ddd solid;
    border-radius: 5px;
}

.add-new-event{
    float: right;
}

.password-form{
    max-width: 400px;
    padding: 3rem;
    background: rgba(0, 0, 0, .3);
    margin: 0 auto;
    color:#FFFFFF;
}

.password-form input{
    color:#000;
    margin:1rem 0;
    width:100%;
}

/*******************************************
Client Summary
********************************************/
.highlights{
    margin:30px 0;
}

.highlights hr{
    margin:5px auto;
}


/*******************************************
Breadcrumbs
********************************************/
.breadcrumb {
    background-color: transparent !important;
    padding-left: 0 !important;
    padding: 0!important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    list-style: none !important;
    border-radius: 4px !important;
}

.breadcrumb-dash {
    background-color: transparent !important;
    padding-left: 0 !important;
    padding: 0!important;
    margin-bottom: 20px !important;
    list-style: none !important;
    border-radius: 4px !important;
}

.underline{
    text-decoration: underline;
}

.underline a{
    text-decoration-color: #777;
}

.arrow:before {
    content: "›";
    vertical-align: top;
    color: #408080;
    font-size: 25px;
    line-height: 16px;
    padding-left: 5px;
    padding-right: 5px;
}

.mce-container.mce-panel.mce-floatpanel.mce-window.mce-scroll.mce-in{width:75% !important;}
.mce-container-body.mce-window-body.mce-abs-layout{width:auto !important;}

/*******************************************
Teammates
********************************************/
.teammate-logo-container {
    padding: 10px;
    max-width: 150px;
    margin: 0 auto;
}

.teammate-profile-image {
    max-height: 300px;
    max-width: 300px;
    margin: 0 auto;
    border-radius: 50%;
    -webkit-box-shadow: 0 5px 5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px rgba(0,0,0,.1);
    background: #fff;
    overflow: hidden;
}

.teammate-profile-image-container .teammate-profile-image {
    width: 160px;
    height: auto;
    border: 2.5px solid #fff;
    -webkit-box-shadow: 1px 3px 7px rgba(0,0,0,.3);
    box-shadow: 1px 3px 7px rgba(0,0,0,.3);
}

.teammate-sign-up-btn {
    max-width: 400px;
}

.kudos-container .modal-dialog {
    top: 25%;
}

.kudos-container .modal-header {
    border-bottom: none;
}

.kudos-container .modal-footer {
    border-top: none;
}

.kudos-container .modal-body {
    text-align: center;
    margin-top: -28px;
}

.kudos-container .modal-body p {
    margin-top: -5px !important;
}

.kudos-container button {
     display: none;
}

.kudos-container .slick-list {
    margin-top: -115px;
}

.kudos-container .slick-slider {
    border-radius: 2%;
}

.kudos-container .slick-slide {
    pointer-events:none;
}

.kudos-container .slick-prev,
.kudos-container .slick-next {
    top: auto;
    bottom: 24px;
    z-index: 1042;
    border: solid grey;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.kudos-container .slick-prev::before,
.kudos-container .slick-next::before {
    content: "";
}

.kudos-container .slick-next {
    right: 12%;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.kudos-container .slick-prev {
    left: 12%;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

@media only screen and (min-width: 767px) {
    .confirm-teammate-form-container {
        display: flex;
        align-items: center;
    }
}
@media screen and (max-width: 767px){
    .confirm-teammate-form-container .profile-img-container{
        max-width: 150px;
        margin: 0 auto;
    }
}

.confirm-teammate-alert {
    position: absolute;
    z-index: 5 !important;
    bottom: 5px !important;
    right: 15px !important;
}

.teammates-checkmark {
    margin: 5% auto;
}

.teammates-exitBtn {
    top: 6.5%;
}

.encourageContainer{
    max-width: 600px;
    margin:auto;
}

.encourageMessage{
    width:100%;
}

.assessmentAlert{
    border-radius: 4px;
    text-transform: none;
}

.assessmentAlert a{
    color: #23527c;
}

.react-datepicker{
    font-size: 1.25rem !important;
}
.profileNav-links .container{
    width: 100%;
    padding: 0;
}
.badge-warning{
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #ffc107;
    border-radius: 10px;
}
.searchable input{
    height: 42px;
}
.searchable .select-search__option.is-selected{
    background: #d7d7d7;
}
.searchable .select-search__option.is-highlighted.is-selected,
.searchable .select-search__option.is-selected:hover {
    background: #d7d7d7;
}
.searchable .select-search{
    max-width: 100%;
}
.searchable ul{
    padding:0;
    margin: 0;
}

/* Tour styles */
.shepherd-arrow{
    background-color: #4bbc4e;
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    border: 2px solid #fff;

}
.shepherd-arrow:before{
    border-radius: 50%;
    top: 5px;
    left: 5px;
    background-color: #fff !important;
}
.tour-guide.shepherd-element[data-popper-placement^=right]>.shepherd-arrow{
    left: -25px !important;
}
.tour-guide.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow{
    top: -25px;
}
.shepherd-element[data-popper-placement^=top]>.shepherd-arrow{
    bottom: -25px !important;
}
.shepherd-arrow,
.shepherd-arrow:before{
    z-index: 1 !important;
}
.tour-guide .shepherd-button{
    background-color: #4BBC4E;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
}
.tour-guide .shepherd-button:not(:disabled):hover{
    background-color: #398a3b;
    color: #fff;
}
.tour-guide.shepherd-has-title .shepherd-header button.shepherd-cancel-icon{
    color: #fff;
}
.tour-guide .shepherd-progress-bar {
    position: absolute;
    left: 2%;
    bottom: 9%;
    font-size: 14px;
    border-radius: 9px;
    height: 25px;
    width: 100%;
    max-width: 180px;
    padding: 3px;
    background: #e6e6e6;
}
.tour-guide.no-btn .shepherd-progress-bar{
    margin-bottom: -5px;
}
.tour-guide .shepherd-progress-bar span {
    display: block;
    background: #4bbc4e;
    width: 50%;
    height: 100%;
    border-radius: 9px;
    text-align: right;
    color: #fff;
    font-size: 13px;
    padding-right: 5px;
    min-width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.tour-guide.no-btn .shepherd-text{
    padding: 10px 10px 35px 10px;
}
.tour-guide.shepherd-has-title .shepherd-content .shepherd-header{
    background-color: #4bbc4e;
}
.shepherd-text{font-size: 14px !important;}
.tour-guide .shepherd-title{
    color: #fff;
    font-size: 14px;
}
.tour-custom-modal{
    height: 100vh;
    opacity: .5;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
    width: 100vw;
    z-index: 9997;
    fill: #000;
}
.tour-custom-modal path{
    pointer-events: all;
}
#disableScreen{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 10000;
    display: none;
}
.apple-background{
    background-image: url(https://treo.s3.us-east-2.amazonaws.com/assets/bg-login.jpg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background-size: cover;
    background-position: center;
}