.customerTile {
    display: flex;
    margin-bottom: 2rem;
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
    padding: 1rem;
    border-radius: 70px;
}

.customerTile .buttonContainer {
    flex: 2;
    justify-content: center;
    align-items: center;
    display: flex;
}

.customerTile .imageContainer {
    margin-right: 1rem;
    display: flex;
    align-items: center;
}

.customerTile .image {
    border-radius: 50%;
    max-width: 50px;
}

.customerTile .infoContainer {
    flex: 5;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.customerTile .name {
    margin: 0;
}

.customerTile .remove {
    color: #a94442;
    margin-right: .5rem;
}

.icon {
    width: 18px;
    height: 18px;
    margin-left: 0.5rem;
    top: -10px;
    position: relative;
    display: inline-block;
}

.placeholder {
    font-size: 21px;
    color: #ccc;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
}

/* Responsive Styles */
@media screen and (max-width: 767px) {
    .customerTile {
        flex-wrap: wrap;
        padding: 1.5rem 3rem;
    }
    .customerTile .buttonContainer {
        justify-content: flex-start;
    }
    .customerTile .imageContainer {
        display: none;
    }
    .customerTile .infoContainer {
        width: 100%;
        flex: none;
    }
}

@media screen and (max-width: 991px) {
    .container {
        flex-direction: column;
    }
    .list,
    .sidebar {
        flex: 1;
    }
}
