.arrow {
    position: relative;
    height: 15px;
    width: 15px;
}
.customSelectWrapper {
    position: relative;
    user-select: none;
    width: 100%;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    font-size: 16px;
}
.customSelectWrapper.active{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.customSelect {
    position: relative;
    display: flex;
    flex-direction: column;
}
.customSelectTrigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    font-weight: 300;
    color: #3b3b3b;
    height: 50px;
    line-height: 38px;
    cursor: pointer;
}
.customOptions {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    border-top: 0;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
    padding: 15px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}
.customSelect.open .customOptions {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}
.customOption {
    position: relative;
    display: block;
    padding: 0 20px 0 20px;
    font-weight: 300;
    color: #000;
    line-height: 38px;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 10px;
}
.customOption:hover {
    cursor: pointer;
    background-color: #dee7eb;
}
.customOption.selected {
    background-color: #dee7eb;
}
.icon{
    font-family: "din-condensed", sans-serif;
    display: flex;
    justify-content: center;
}
.icon svg{
    fill: #2A3342;
    stroke: #2A3342;
    margin-right: 10px;
    width: 20px;
}
.open .arrow {
    transform: rotate(180deg);
}

.open .customSelectTrigger{
    border-bottom: 13px solid #fff;
    margin-top: 13px;
    z-index: 3;
}