.container{
    display: flex;
    font-family: "aaux-next";
    font-weight: 400;
}
.container h1{
    font-family: "aaux-next";
}
.info{
    margin:0;
    display: flex;
    justify-content: space-between;
    text-align: right;
 }
.infoContainer{
    flex:1;
    margin-right: 4rem;
    margin-bottom: 2rem;
    min-width: 170px;
    max-width: 230px;
}
.infoRow{
    display: flex;
    flex-wrap: wrap;
}
.label{
    font-weight: bold;
}
.logo{
    width: 100%;
}
.logoContainer{
    background: #3a3a3a;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    overflow:hidden;
}
.titleContainer{
    flex: 1;
    margin-left: 2rem;
}

@media screen and (max-width: 991px) {
    .container{
        flex-wrap: wrap;
    }
    .logoContainer{
        width: 100%;
    }
    .titleContainer{
        width: 100%;
    }
}