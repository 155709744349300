.demographics{
    min-width: 150px;
    height: 200px;
    position: relative;
    width: 50%;
    margin: 0 0 15px 0;
}
.demographics .inner{
    height: 158px;
}
.demographicsContainer{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}