
.progressbar {
    counter-reset: step;
}
.progressbar li {
    list-style: none;
    display: inline-block;
    width: 24.33%;
    position: relative;
    text-align: center;
    cursor: pointer;
}
.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height : 30px;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #fff;
}
.progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index : -1;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.active {
    color: green;
}
.progressbar li.active:before {
    border-color: green;
}
.progressbar li.active + li:after {
    background-color: green;
}

.description-span {
    font-weight: 100;
}

.disable-goal-bar {
    border: 1px solid gray;
    border-radius: 2px;
    padding: 3px;
    margin-right: 5px;
}

.info-disable-goal-bar {
    margin-left: 5px;
}

.goal-container {
    border: 2px solid gray;
    border-radius: 5px;
    padding-bottom: 18rem;
}

.notes-container {
    border: 2px solid gray;
    border-radius: 5px;    
}

.notes-title-label, .notes-label {
    display: block;
}

.notes-label {
    margin-top: 15px;
}

.notes {
    padding-bottom: 10rem;
}

.goal-bar-container {
    border: 1px solid gray;
    padding-top:11px; 
    padding-bottom:11px; 
    background: #e54b4b;
    background: -moz-linear-gradient(left, #e54b4b 0%, #ffff88 50%, #71bf67 100%);
    background: -webkit-linear-gradient(left, #e54b4b 0%,#ffff88 50%,#71bf67 100%);
    background: linear-gradient(to right, #e54b4b 0%,#ffff88 50%,#71bf67 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e54b4b', endColorstr='#71bf67',GradientType=1 );
}



.publish-btn{
    background-color: #4bbc4e;
    width: 200px; 
}

.back-btn{
    background-color: #354052;
    width: 200px;

}

.pub-back-container-btn{
 margin: 10px;
}