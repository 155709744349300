

.sidebarRightContainer{
    flex: 1;
    padding: 20px;

}

.container {
    display: flex;
    width: 100%;           
    max-width: 1600px;     
    margin-left: auto;
    margin-right: auto;

  }
  


@media (max-width: 1000px) {
    .sidebarRightContainer{
        flex-direction: column;
        padding-left: auto;
        padding-right: auto;
        width: 99vw;

  
    }
}