.pathwaysContainer,
.list{
    height:1100px;
    overflow: auto;
    background:#fff;
}
@media screen and (max-width: 991px){
    .list{ height: auto; }
}

.placeholder{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder p{
    margin-right: 1rem;
    font-size: 21px;
    color:#ccc;
}