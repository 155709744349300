.boxes{
    margin: 0 15px 15px 15px;
    padding: 15px;
}
.boxesOuter{
    min-width: 150px;
    min-height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 25px;
}
.exportRow{
    width: 100%;
}
.filter .dateRange{
    display: flex;
    align-items: center;
    max-width: 350px;
}
.goalRow{
    display: flex;
}
.goalRow .goalCompletion{
    flex: 1;
    padding: 15px;
}
.goalRow .healthGoals{
    padding: 15px;
    flex: 1;
    margin-bottom: 25px;
}
.goalRow .tileColumn{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}
.goalRow .tileUsage{
    flex: 1;
    padding: 15px;
    margin-bottom: 25px;
}
.heading{
    margin: 0 0 30px 0;
}
.heading2{
    font-weight: bold;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 15px;
}
.mainLabel{
    font-size: 36px;
    font-weight: bold;
}
.secondaryLabel{
    font-size: 18px;
    font-weight: bold;
}
.subLabel{
    font-size: 18px;
    font-weight: 400;
}
.tilesRow{
    padding: 15px;
}