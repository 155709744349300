.flag{
    background: #354052;
    color: #fff;
    font-size: 12px;
    border-radius: 20px;
    padding: 2px 5px;
    margin-left: 10px;
}
.lock{
    width: 12px;
    height: auto;
    fill: #a1a1a1;
}
.row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px 25px;
}
.title{
    flex: 1;
    margin:0;
    text-transform: capitalize;
    color: #333;
    text-decoration: none;
}