.date{
    margin: 0 0 40px 0;
}
.heading{
    font-size: 24px;
    margin: 25px 0 5px 0;
}
.healthGoal{
    display: flex;
    align-items: center;
    height: 45px;
    margin-bottom: 25px;
}
.healthGoal img{
    width: auto;
    height: 40px;
    margin-right: 10px;
}
.healthGoal .goal{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}