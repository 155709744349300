.actionBtns{
    display: flex;
    align-items: center;
}
.audio{
    background-color: rgba(0,0,0,.5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 6px;
    position: absolute;
    top: 6px;
    right: 6px;
}
.audio svg{
    fill: #fff;
    position: relative;
    top: 0;
    right: 0px;
}
.childCategory{
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: -5px;
    transform: translateY(5px);
    display: inline-block;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
}
.delete{
    padding: 5px 15px;
    background-color: #9c4543;
    color: #fff;
    border: 1px solid #9c4543;
    border-radius: 10px;
    font-size: 14px;
}
.length{
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px 10px;
    background: rgba(0,0,0,.4);
    border-bottom-right-radius: 10px;
}
.link{
    background-color: rgba(0,0,0,.5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 3px 6px;
}
.link svg{
    top: 1px;
    left: 1px;
    position: relative;
}
.main{
    display: flex;
}
.main .actions{
    display: flex;
}
.main .display{
    width: 145px;
}
.main .form{
    flex: 1;
    margin: 0 15px;
}
.main .form .inputs{
    display: flex;
}
.main .form .inputs select:last-of-type{
    width: 80px;
}
.pdf{
    background-color: rgba(0,0,0,.5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 4px 7px;
}
.pdf svg{
    top: 1px;
    left: 1px;
    position: relative;
}
.play{
    background-color: rgba(0,0,0,.5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 7px;
    position: absolute;
    top: 6px;
    right: 6px;
}
.play svg{
    fill: #fff;
    position: relative;
    top: -2px;
    right: -1px;
}
.thumbnail{
    background: #333;
    display: block;
    height:195px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
}
.title{
    color: #fff;
    background-color: #000;
    background-color: rgba(0,0,0,.6);
    position: absolute;
    width: 100%;
    bottom: 0;
    margin:0;
    padding:5px;
}