.subscriptionContainer {
  border-top: 1px solid rgba(211, 211, 211, 0.362);
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.btnContainer {
  display: flex;
  width: 100%;
  justify-content: flex-;
  margin-top: 10px;
}

.selectedPlanHeader {
  font-size: 16px;
  margin-bottom: 10px;
}

.selectedPlanText {
  font-size: 21px;
  margin: 5px 0;
}

.planPrice {
  margin: 5px;
}

.skeletonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.linksContainer {
  margin-top: 20px;
}

.nextButton:disabled {
  background: grey;
  cursor: not-allowed;
  /* Adjust other styles as needed */
}

.customModalSubHeader {
  font-size: 20px;
}

.bulletList {
  font-size: 18px;
}

.modalText {
  font-size: 18px;
  max-width: 350px;
  text-align: center;
}

.modalBoldFooter {
  max-width: 350px;
  text-align: center;
  font-weight: 450;
}

.cancelButton {
  margin-right: 20px;
}

.App-Overview {
  width: 200px;
}

.flex-container {
  min-width: 1200px;
}


.backIcon {
  margin: 20px;
  cursor: pointer;
}


.subscriptionTermsTitle{
  margin-right: 5px;
}

.subscriptionTermsText{
  font-size: 12px;
}