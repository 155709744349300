.sidebarContainer {
  width: 287px;
  height: 980px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 18px;
  margin-left: 20px;
  background-color: #35394e;
  box-shadow: 8px 0 8px 0 rgba(0, 0, 0, 0.07);
  font-family: "Proxima Nova", sans-serif;
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  perspective: 1000px; /* Enables 3D effects for children */
}

/* Logo Area */
.sidebarLogo {
  width: 100%;
  margin-bottom: 25px;
  padding: 18px 14px;
}

.sidebarLogoIcon {
  width: 60%;
  height: auto;
  display: block;
  margin: 25px auto 0 auto;
}

.sidebarLogoTitle {
  margin: 0;
  font-size: 1.5rem;
}

.sidebarLogoSubtitle {
  font-size: 0.9rem;
  display: block;
}

/* Navigation Links */
.sidebarLinks {
  list-style-type: none;
  padding: 18px 14px;
}

openSidebarLink {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  list-style-type: none;
  padding: 18px 14px;
}

.sidebarLinkItem {
  margin-bottom: 10px; /* Adds space between links */
}

/* Top-level link */
.sidebarLink {
  display: flex;
  align-items: center;
  gap: 8px; /* Adds space between icon and text */
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border-radius: 8px;
  padding: 10px;
  font-size: 18px;
  transition: background 0.3s ease;
  cursor: pointer;
  width: 250px;
}

.sidebarLink:hover {
  background-color: #29273d;

  color: #fff;
  text-decoration: none;
}

/* Active Link for primary sidebar items */
.current {
  width: 250px;
  height: 50px;
  border-radius: 8px;
  background: linear-gradient(#7fc03f, #43ae3e);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;
  font-size: 18px;
  font-weight: 600;
  border: none;
  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.128));
}

/* Text inside Links */
.sidebarLinkText {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin: 0; /* remove default <p> margin */
}

/* Icon Styling */
.icon {
  margin-right: 8px;
}

/* Footer Log Out */
.sidebarLogout {
  margin-top: auto;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #29273d;
  height: 114px;
  gap: 20px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.logoutLink {
  text-decoration: none;
  color: #fff;
  font-size: 18pt;
  font-weight: 600;
}

.logoutLink:hover {
  color: #42ae3e;
}

/* Hamburger Button for Mobile */
.hamburger {
  display: none;
  position: fixed;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 48px;
  color: #35394e;
  cursor: pointer;
  z-index: 99999999999999;
}
.dropdownCurrent {
  color: #fff !important;
  text-decoration: none;
  list-style: none;
}
/* Mobile Sidebar */
@media (max-width: 1000px) {
  .sidebarContainer {
    position: fixed;
    top: 0;
    left: -500px;
    width: 287px;
    height: 100vh; /* or 980px, but 100vh is often better on mobile */
    transition: left 0.3s ease-in-out;
    z-index: 999;
  }

  .showSidebar {
    left: 0;
  }

  .hamburger {
    display: block;
  }
}

.sidebarLink:focus,
.sidebarLink:active {
  outline: none;
  text-decoration: none;
  color: white;
}

/* Dropdown arrow container */
.dropdownArrow {
  margin-left: auto;
  position: relative;

  display: inline-block;
  width: 21px; /* fixed width matching the icon size */
  height: 21px; /* fixed height matching the icon size */
}

/* Both arrow spans are absolutely positioned and will fade in/out */
.dropdownArrow span {
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease;
}

/* When the dropdown is open, show the up arrow; otherwise hide it */
.arrowUpVisible {
  opacity: 1;
}

.arrowUpHidden {
  opacity: 0;
}

/* When the dropdown is closed, show the down arrow; otherwise hide it */
.arrowDownVisible {
  opacity: 1;
}

.arrowDownHidden {
  opacity: 0;
}

/* Updated Dropdown Menu Container */
.dropdownMenu {
  list-style: none;
  margin-top: 0;
  background-color: #29273d;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transform-origin: top;
  transform: rotateX(-90deg);
  opacity: 0;
  position: relative;

  width: 250px;
  overflow: hidden;
  max-height: 0;
  transition: transform 0.4s ease, opacity 0.3s ease-in-out,
    max-height 0.3s ease-in-out;
  backface-visibility: hidden;
}

.dropdownMenu.open {
  transform: rotateX(0deg);
  opacity: 1;
  max-height: 500px; /* Adjust as needed for your content */
}

/* Submenu items */
.dropdownItem {
  display: block;
  text-decoration: none;
  color: #ffffff99;
  padding: 12px 16px; /* Indent slightly */
  font-size: 16px;
  text-decoration: none;
  transition: background 0.3s ease;
}

.dropdownItem:hover {
  color: #ffffff;
  text-decoration: none;
  list-style: none;
}

.dropdownItem,
.dropdownItem:visited,
.dropdownItem:hover,
.dropdownItem:active {
  text-decoration: none !important;
}

.dashboardHovered {
  width: 250px;
  height: 50px;
  border-radius: 8px;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #29273d; /* Same as your hover color */
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;
  font-size: 18px;
  font-weight: 600;
  border: none;
  /* Optionally, include a drop-shadow similar to .current if desired */
  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.128));
}
