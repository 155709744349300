.buttons{
    display: flex;
    gap: 20px;
    margin-bottom:20px;
}
.buttons button{
    background: transparent;
    border: 2px solid #6BA543;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    color: #6BA543;
}
.buttons svg{
    background: transparent;
    padding: 0;
    border: none;
    width: 30px;
    height: 30px;
    position: relative;
}
.container{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.heading{
    color: #6BA543;
    text-align: center;
}
.image1, .image2{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.image2{
    opacity: 0.6;
}
.puzzle{
    position: relative;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}
.subheading{
    font-style: italic;
    font-size: 16px;
    text-align: center;
}