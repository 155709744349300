.boxes{
    padding: 2rem;
}
.save{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.saveButton{
    margin-left: 1rem;
}

@media screen and (max-width: 767px) {
    .save{
        flex-direction: row-reverse;
    }
    .saveButton{
        margin-left: 0;
        margin-right: 1rem;
    }
}