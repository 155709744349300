.categories{
    max-height: 500px;
    overflow: auto;
}
/* Scroll Track */
.categories::-webkit-scrollbar {
    width: 10px;
}
.categories::-webkit-scrollbar-track {
    background: transparent;
}
.categories::-webkit-scrollbar-thumb {
    background: rgb(204, 204, 204);
    border-radius: 10px;
    width: 10px;
}
/* Handle on hover */
.categories::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,.6);
}
.categories.overflow{
    margin-right: 5px;
}
.container{
    padding: 15px;
    width: 100%;
}
.filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.filter .panel{
    display: none;
    opacity: 0;
    position: absolute;
    top: 25px;
    right: 25px;
    transition: all 1s ease;
}
.filter .panel.show{
    display: block;
    opacity: 1;
    z-index: 2;
}
.iconFilter{
    width: 27px;
    fill: #333;
    justify-self: flex-end;
    cursor: pointer;
}
.iconTimes{
    width: 20px;
    fill: #ccc;
}
.search{
    display: flex;
    align-items: center;
}
.searchText{
    max-width: 200px;
    margin-right: 15px;
}