.btn{ border: none; background-color: transparent; padding: 0;}
.checkmark{
    width: 20px;
    height: auto;
    fill: #4BBC4E;
}
.circle{
    width: 20px;
    height: 20px;
    border: 2px solid #4BBC4E;
    border-radius: 50%;
    background: #fff;
}
.container{
    padding: 10px 15px;
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tagContainer{
    max-height: 400px;
    overflow: auto;
}
.tag{
    margin: 0 0 0 10px;
}
.tagRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    text-transform: capitalize;
    margin-bottom: 5px;
    border: none;
    background-color: #fff;
}
.times{
    width: 25px;
    stroke: #9d9d9d;
    height: auto;
    fill: #9d9d9d;
}