.form{
    display: flex;
    flex-direction: column;
    gap: 17px;
    min-width: 483px;
}
.formContainer{
    background-color: rgba(245, 245, 245, 1);
    padding: 50px 50px 0 50px;
    border-radius: 35px 35px 0 0;
}
.formFooter{
    width: 100%;
    background-color: rgba(245, 245, 245, 1);
    padding: 37px 50px 50px 50px;
    border-radius: 0 0 35px 35px;
}
.formFooter :global(.TOS-row) label{
    color: #333;
    font-weight: 400;
    text-align: center;
}
.formFooterSingle{
    color: #000;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    text-decoration-line: underline;
}
.formSelector{
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translate(0, -50%);
}
.formSubmit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
    gap: 30px;
    align-items: flex-start;
}
.formSubmit p{
    margin: 0;
}
.formTitle{
    color: #6BA543;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    margin:0;
}
.formTitle2{
    color: #6BA543;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    margin:0;
    text-align: center;
}
.green{
    background-color: #6BA543;
    flex:4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.heading1{
    color: #EDBF36;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 46px; /* 115% */
    margin: 0;
}
.heading2{
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    margin: 0;
}
.headingContainer{
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    max-width: 455px;
}
.logo{
    width: 420px;
    height: auto;
    margin: 0 0 40px 0;
}
.main{
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    inset: 0;
}
.navBtn{
    background-color: transparent;
    color: #6BA543;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    border: none;
}
.navBtn.active{
    border-bottom: 3px solid #EDBF36;
}
.navLogin{
    display: flex;
    width: 410px;
    padding: 0 15px 20px 15px;
    gap: 20px;
    background-color: transparent;
}
.selectionContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.signupSubheading{
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 0 0 0;
}
.signupSubmit{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.white{
    flex: 3;
    position: relative;
}

@media screen and (max-width: 1300px) {
    .green{
        flex: 6;
    }
    .form{
        min-width: 400px;
    }
    .heading1{
        font-size: 30px;
    }
    .logo{
        width: 280px;
    }
    .white{
        flex: 5;
    }
}

@media screen and (max-width: 1024px) {
    .green{
        padding: 40px 0;
        flex: unset;
    }
    .formContainer{
        padding: 40px 20px 0 20px;
        margin: 0 15px;
    }
    .formFooter{
        margin: 0 15px 20px 15px;
        width: auto;
    }
    .formSelector{
        position: relative;
        right: unset;
        top: unset;
        transform: unset;
    }
    .headingContainer{
        display: none;
    }
    .heading1{
        font-size: 30px;
    }
    .logo{
        width: 200px;
        margin: 0;
    }
    .main{
        flex-direction: column;
    }
    .navLogin {
        padding: 20px 15px 20px 40px;
        background: #fff;
        width: 100%;
        justify-content: space-between;
    }
}

@media screen and (max-width: 600px) {
    .form{
        min-width: 100%;
    }
    .navBtn{
        font-size: 16px;
    }
}