.container{
    height: 100%;
    padding: 18px 12px;
    overflow: hidden;
}
.dummy{
    float: left;
    height: 0;
}
.header{
    display: flex;
}
.header .archive{
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc;
    padding: 6px 15px;
    border-radius: 10px;
    font-size: 14px;
}
.header .buttons{
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.header .close{
    background-color: transparent;
    padding: 9px;
    border: 2px solid #ababab;
    border-radius: 50%;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header .close svg{
    fill: #ababab;
    width: 12px;
    height: 12px;
}
.header .title{
    flex: 1;
}
.left .meta{
    flex-direction: row-reverse;
    text-align: right;
}
.left .meta .img{
    margin-right: 0;
    margin-left: 5px;
}
.meta {
    display: flex;
    font-size: 12px;
    margin-bottom: 25px;
}
.meta .date{
    margin: 0;
}
.meta .img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin-right: 5px;
}
.meta .user{
    margin-bottom: 5px;
}
.recipients{
    margin-bottom: 25px;
}
.recipients .header{
    font-size: 12px;
    margin: 0 0 10px 0;
}
.reply{
    float: right;
    padding: 15px 0;
}
.text{
    background: #ecf2f6;
    padding: 15px;
    border-radius: 10px;
}
.thread{
    float: left;
    width: 85%;
}
.thread.left{
    float: right;
}
.threadBody{
    overflow: auto;
    height: 0;
}