.advancedHeading{
    margin: 0 0 15px 0;
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-right: 25px;
}
.advancedHeading:after{
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 5px;
    width: 11px;
    height: 11px;
}
.advancedHeading.open:after{
    transform: rotate(225deg);
    top: calc(50% - 3px);
}
.filter{
    margin-top: 40px;
    padding: 15px;
}
.filter :global(.select-search__input){
    border: 1px solid #ccc;
    height: 34px;
}
.filtersCategory{
    z-index: 2;
    position: relative;
}
.filtersCategory :global(.select-form-control){
    width: 300px;
}
.filtersAdvanced,
.filtersMain{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.filtersMain :global(.form-group),
.filtersAdvanced :global(.form-group){
    margin-right: 25px;
    margin-bottom: 25px;
}
.filtersStatus{
    width: 150px;
}
.filtersTitle{
    width: 350px;
}
.panel{
    max-height: 0;
    overflow:hidden;
}
.plus{
    width: 20px;
    fill: #4BBC4E;
    position: relative;
    top: 5px;
    margin-left: 10px;
    cursor: pointer;
}
/* .primariesContainer{
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    text-align: center;

} */
.primariesContainer .primaries{
    margin-left: 15px;
    font-size: 16px;
    text-shadow: none;
    text-transform: unset;
    min-width: 167px;
    text-align: center;
}
.submit button{
    padding: 0.4rem 3rem;
    text-transform: unset;
}

.scheduleClassContainer{
    width: 200px !important;
}
.tagsHeading{
    margin-bottom: 10px;
}
.tag{
    background: #9c9c9c;
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    border: none;
    padding: 7px 10px;
    margin: 0 5px 5px 0;
}
.tagContainer{
    display: flex;
    flex-wrap: wrap;
}
:global(.tag_links) a{
    color: #4bbc4e;
}
.times{
    width: 14px;
    height: auto;
    fill: #fff;
    margin-left: 10px;
}

@media screen and (max-width: 1150px) {
    .submit{
        width: 100%;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 991px) {
    .filtersCategory,
    .filtersStatus{
        width: 50%;
        margin-right: 0;
    }
    .filtersTitle{
        width: 100%;
    }
}

.btnDropdownScheduleContainer{
display: flex;
justify-content: flex-end;
flex-wrap: wrap-reverse;
gap: 10px;
margin-bottom: 5px;
}



