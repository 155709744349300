.container {
min-height: 450px;
    max-height: 100%;
  position: relative;
  padding: 10px;
  min-width: 300px
}

.loadingContainer{ 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chartTitle {
  padding-left: 10px;
}


.toggle :global(.toggleContainer) {
    background-color: #354052;
    border: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,.1);
    width: 310px;
}
.toggle :global(.toggleButton) {
    background-color: #fff;
}
 .tab{
    margin-right: 15px;
    padding-bottom: 5px;
    cursor: pointer;
    font-size: 16px;
}
.tab.active{
    border-bottom: 2px solid #354052;
}

.categories{
    width: 100%;
    max-height: 450px;
    box-shadow: none !important;
}
.categories .category{
    display: flex;
    align-items: center;
}
.categories input[type='checkbox']{
    display: inline-block;
    width: auto;
    margin: 0 15px 0 0;
}
.categories label{
    margin: 0;
    font-weight: 400;
    flex: 1;
}
/* .container{
    height: 300px;
    position: relative;
} */
.row{
    display: flex;
    padding: 2px 5px;
    border-bottom: 1px solid #ccc;
}
.row .category{
    flex: 1;
}
.row .views{
    width: 150px;
    text-align: center;
}