.rankContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 30px;
    gap: 20px;
  }
  
  .rankLocked {
    width: 150px;
    animation: rankAnimation 3s ease;
  }
  
  .rankText {
    max-width: 300px;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }
  


@keyframes rankAnimation{
    0%{
        transform: translate3d(-1px, 0, 0) scale(0)
    }
    10% {
        transform: translate3d(-1px, 0, 0);
    }
    20% {
        transform: translate3d(2px, 0, 0);
    }
    25%{
        transform: translate3d(-4px, 0, 0);
    }
    30% {
        transform: translate3d(4px, 0, 0);
    }
    35%{
        transform: translate3d(-4px, 0, 0);
    }
    40% {
        transform: translate3d(4px, 0, 0) scale(1);
    }
    45%{
        transform: translate3d(2px, 0, 0);
    }
    50% {
        transform: translate3d(-4px, 0, 0);
    }
    55%{
        transform: translate3d(4px, 0, 0);
    }
    60% {
        transform: translate3d(-4px, 0, 0);
    }
    65%{
        transform: translate3d(4px, 0, 0);
    }
    70%{
        transform: translate3d(-4px, 0, 0);
    }
    75%{
        transform: translate3d(2px, 0, 0);
    }
    80% {
        transform: translate3d(-2px, 0, 0);
    }
    85%{
        transform: translate3d(1px, 0, 0);
    }
    90%{
        transform: translate3d(-1px, 0, 0);
    }
}


@media screen and (max-width: 374px) {
    .badgeRow .underlay{
        transform: translate(32px, -50px) scale(1.2);
    }
    .badgeRow._center .image{
        margin-right: -165px
    }
    .badgeRow._center2 .image{
        margin-right: -168px;
    }
    .badgeRow._center2 .underlay{
        transform: translate(34px, -50px) scale(1.2);
    }
    .flag._center{
        transform: translate(-90%, -50%);
    }
    .path{
        display: none;
    }
    .path.offsetPath{
        display: block;
    }
}