.portalCard {
  padding: 20px;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;

  align-items: center;

  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.searchInput {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.filterSelect {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.searchButton {
  border-radius: 4px;
  border: none;
  background-color: #68b93f;
  color: #fff;
  cursor: pointer;
  width: 210px;
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.thead {
  background-color: #f2f2f2;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.headerRow {
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.thCell {
  padding: 12px;
}

.tbodyRowEven {
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
}

.tbodyRowOdd {
  background-color: #f8f8f8;
  border-bottom: 1px solid #eee;
}

.tdCell {
  padding: 12px;
}

.noData {
  padding: 12px;
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 1rem;
}

.paginationButton {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.disabledButton {
  background-color: #eee;
  cursor: not-allowed;
  width: 100px;
  margin-left: 5px;
  margin-right: 5px;
}

.enabledButton {
  background-color: #ffffffb5;
  cursor: pointer;
  width: 100px;
  margin-left: 5px;
  margin-right: 5px;
}

.thumbnail {
  width: 60px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
}

.tableLoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.loaded {
  opacity: 1;
}

.thumbnailColumn {
  padding: 12px;
}
@media (max-width: 600px) {
  .thumbnailColumn {
    display: none;
  }
}
