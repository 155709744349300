.button{
    border-radius: 50px;
    margin-bottom: 2rem;
    font-size: 14px;
    background: #fff;
    color: #000;
    font-family: "din-condensed";
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
    padding: 1rem 3rem;
    box-shadow: 0px 3px 5px rgba(0,0,0,.2);
    width: 100%;
    text-align: left;
    transition: all ease .3s;
}
.button:hover{
    background: #354052;
    color: #fff;
}
.button.active{
    background: #354052;
    color: #fff;
}
.button .bar{
    width: 30px;
    height: 3px;
    border-radius: 50px;
    display: block;
    margin-bottom: 0.5rem;
}
.button .activeBar{ background: #4D8597;}
.button .monthlyBar{ background: #6FA355;}
.button .totalBar{background: #C0AB4B;}
.graph{
    flex: 4;
}
.graphButtons{
    flex: 1;
    padding-left: 3rem;
    margin-left: 1rem;
    border-left: 1px solid #dddddd;
}
.graphContainer{
    display: flex;
    flex-wrap: wrap;
}
.rangeContainer{
    display: flex;
}
.range{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rangeBack{
    flex:2;
    display: flex;
    align-items: center;
}
.rangeForward{
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
@media screen and (max-width: 767px) {
    .button{
        margin: 1rem 1rem 0rem 1rem;
        width: auto;
        min-width: 160px;
    }
    .graphButtons{
        padding-left: 0;
        border-left: none;
        margin-left: 0;
    }
    .graphButtons .buttonGroup{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .graphContainer{
        display: block;
    }
}