.bulgeContainer{
    position: absolute;
    overflow: hidden;
    height: 70px;
    width: 100%;
    top: -45px;
}
.bulge{
    position: absolute;
    top: 65px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100px;
    width: 120%;
    border-radius: 50% 50% 0 0;
    background-color: #ecf2f6;
    display: none;
}
.concave {
    position: relative;
    background: #ecf2f6;
    height: 120px;
    top: 45px;
}

.concave:before,
.concave:after {
    box-shadow: 0 0 0 80px #ecf2f6;
    border-radius: 100%;
    position: absolute;
    height: 285px;
    content: '';
    right: -20%;
    left: -20%;
    top: 100%;
}

.concave:after {
    bottom: 100%;
    top: auto;
}
.coachOverlay{
    padding: 15px;
    text-align: center;
}
.coachOverlay .btns{
    display: flex;
    justify-content: space-around;
}
.coachOverlay .btns a,
.coachOverlay .btns button{
    font-size: 14px;
    text-transform: unset;
}
.coachOverlay .btns button{
    background-color: #9d9d9d;
    border: #9d9d9d 1px solid;
}
.fullWidth{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
}
.links{
    height: 150px;
    padding: 15px;
    position: relative;
    margin-bottom: 25px;
}
.links:hover{
    text-decoration: none;
    color: #000;
}
.logos{
    height: auto;
    width: 60px;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}
.mainButtons{
    margin: -30px auto 20px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 650px;
}
.mainButtons .button{
    background-color: #fff;
    border-radius: 43px;
    width: 135px;
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0px 6px 6px 3px rgb(0 0 0 / 30%);
    margin: 0px 35px;
    height: 40px;
    align-items: center;
    border: none;
    font-weight: bold;
}
.mainButtons svg{
    fill: #ccc;
    width: 15px;
}
.mainButtons a{
    color: #333;
    font-weight: 600;
}
.popout{
    position: absolute;
    top: -13px;
    left: 20px;
    background: #d63f3f;
    border-radius: 6px;
    padding: .25rem 1.5rem;
    box-shadow: 0 0 7px rgba(0,0,0,.3);
    z-index: 1;
    color: #fff;
    font-size: 12px;
}
.overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.5);
    left: 0;
    top: 0;
    border-radius: 10px;
    z-index: 0;
}
.overlay.left{
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
}
.playIcon{
    background-color: #6BA543;
    padding: 10px;
    border-radius: 50%;
    margin-right: 10px;
    color: #fff;
}
.tipContainer{
    position: relative;
    top: -1.75rem;
    margin-bottom: 1rem;
}
.tip{
    background-color: #fff;
    display: block;
    /*border: 1px solid #6BA543;*/
	background-color: #6BA543;
    box-shadow: 0px 0px 15px rgba(0,0,0,.2);
    border-radius: 8px;
	padding: 1.5rem 2rem;
	background-image: url(../../images/noodle-overlay.png);
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center right;
}
.tip .headline{
    /*background-color: #6BA543;*/    
    margin: 0 0 .5rem 0;
    color: #fff;
	text-transform: uppercase;
}
.tip .content{
    color: #fff;    
	margin: 0;
	font-size: 16px;
}
.tip:hover,
.tip:hover h3,
.tip:hover p {
	text-decoration: none;	
}
.title{
    color: #fff;
    position: relative;
    z-index: 1;
    font-weight: 400;
    text-transform: capitalize;
}
.titleCenter{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: #fff;
    z-index: 1;
    flex-wrap: wrap;
}
.titleCenter .external{
    width: 100%;
    text-align: center;
    margin: 10px 0 0 0;
    font-weight: 400;
    font-size: 12px;
}
.titleCenter .external img{
    position: relative;
    bottom: 1px;
}
.titleCenter .text{
    width: 100%;
    text-align: center;
    margin: 0;
    font-weight: 600;
}
.watchNextContainer{
    position: absolute;
    width: 100%;
    bottom: -25px;
    left: 0;
    display: flex;
    justify-content: center;
}
.watchNext{
    background: #eff5ea;
    z-index: 1;
    padding: 0 20px 0 0;
    font-weight: 400;
    border: 5px solid #fff;
    border-radius: 35px;
    display: flex;
    align-items: center;
}
.watchNextTitle{
    font-size:22px;
    margin-bottom: 5px;
}
.modalTitle {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #4bbc4e;
    font-size: 26px;
    font-weight: 500;
  }
  
.moduleContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
}


@media screen and (max-width: 600px) {
    .bulge{
        display: block;
    }
    .concave{
        display: none;
    }
    .mainButtons{
        margin: -50px auto 20px auto;
        justify-content: space-around;
    }
    .mainButtons .button{
        margin: 0;
    }
}


.customModalDialog {
    display: flex;
    align-items: center;
    border: none !important; 
    justify-content: center;
    min-height: 100vh; /* Full viewport height to center vertically */
  }
  
  .customModalContent {
    margin: auto; /* Ensure the content is centered */
    border: none !important; /* Remove border from the modal content */
    box-shadow: none !important; /* Remove any shadow */
  }
  