.metaData-ul {
	max-width: 1000px;
	margin: auto;
	list-style: none;
}

.metaData-li {
	width: 794px;
	height: 350px;
	border: 1px solid black;
	margin: auto;
	margin-bottom: 20px;
	margin-top: 20px;
}