
/* makes add equipment button responsive on mobile*/
    @media only screen 
    and (max-width: 500px) {
        .equipBtnContainer{
            display: flex;
            flex-wrap:wrap ;
            gap: 20px;
        }
    
    }

  
