.back{
    display: inline-block;
    margin-top: 12px;
    margin-left: 15px;
}
.back:hover, .back:active, .back:visited{
    text-decoration: none;
    color: #fff;
}
.back span{
    color: #fff;
}
.back svg{
    fill: #fff;
    width: 35px;
    height: auto;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: -2px;
}
.links ul i{
    font-size: 16px !important;
    cursor: pointer;
}
.links ul i.question{
    border: 1px solid;
    border-radius: 50%;
    padding: 5px 6px;
    font-size: 11px !important;
}
.nav{
    z-index: 3;
}
.notification{
    width: 100%;
    height: 75px;
    z-index: 10001;
    position: fixed;
    max-width: 600px;
    left: 50%;
    top: 0;
    transform: translate(-50%, -100%);
    transition: all 1s ease;
    overflow: hidden;
}
.notification.show{
    overflow: visible;
    transform: translate(-50%, 0)
}
.bronzeRank :global(.profile-image){
    border: 4px solid #d6764c !important;
}

.silverRank :global(.profile-image){
    border: 4px solid #9d9d9d !important;
}

.goldRank :global(.profile-image){
    border: 4px solid #f7b637 !important;
}

.platinumRank :global(.profile-image){
    border: 4px solid #a2cad8 !important;
}
