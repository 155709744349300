.singleTile-container {
/*	width: 300px;
	height: 300px;
	background-color: green;*/
}

.statusBox {
	height: 50px;
	width: 50px;
}

.statusIcons{
	font-size:5rem !important;
	background-color:#dcdcdc;
	position:relative;
	top:-10rem;
	float: right;
	margin:0 2rem;
	border:1px solid black;
	cursor:pointer;
}