.categoryRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px 25px;
}
.form{
    display: flex;
    align-items: flex-end;
    margin-bottom: 25px;
}
.head{
    display: flex;
    justify-content: space-between;
}
.head h3{
    margin: 0;
    text-transform: capitalize;
}
.lock{
    width: 12px;
    height: auto;
    fill: #a1a1a1;
}
.search{
    display: flex;
    align-items: flex-end;
}
.search .submit{
    margin-right: 25px;
    font-size: 14px;
}
.search .text{
    margin-right: 15px;
}
.title{
    flex: 1;
    margin:0;
    text-transform: capitalize;
}

@media screen and (max-width: 767px){
    .form{
        flex-direction: column;
        align-items: flex-start;
    }
    .search{
        margin-bottom: 15px;
    }
}