.categories{
    max-height: 350px;
    overflow-y: scroll;
}
.categories .category{
    display: flex;
    align-items: center;
}
.categories input[type='checkbox']{
    display: inline-block;
    width: auto;
    margin: 0 15px 0 0;
}
.categories label{
    margin: 0;
    font-weight: 400;          
    flex: 1;
}
.container{
    height: 300px;
    position: relative;
}
.row{
    display: flex;
    padding: 2px 5px;
    border-bottom: 1px solid #ccc;
}
.row .category{
    flex: 1;
}
.row .views{
    width: 150px;
    text-align: center;
}
.toggle :global(.toggleContainer) {
    background-color: #354052;
    border: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,.1);
    width: 310px;
}
.toggle :global(.toggleButton) {
    background-color: #fff;
}
.tooltip{
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
}
.tooltip p{
    margin: 0 0 5px 0;
}