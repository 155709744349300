.button{
    z-index: 101;
    font-size: 14px !important;
    padding: 5px 20px;
}
.buttonSmall{
    font-size: 12px !important;
    padding: 3px 12px;
    z-index: 101;
}
.container{
    position: relative;
    padding: 3px;
}
.headerBg{
    background: #ccc;
    border-radius: 65px 65px 0 0;
    position: relative;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
}
.headerRemove{
    color: #fff;
    z-index: 101;
    background: rgba(0,0,0, 0.6);
    border: none;
    border-radius: 5px;
    font-size: 12px;
    position: absolute;
    bottom: 5px;
    left: 19px;
}
.phone{
    position: absolute;
    width: 100%;
    max-width: 375px;
    top: 0;
    left: 0;
    z-index: 100;
}
.row{
    position: relative;
    display: flex;
    background: #ccc;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: 100% 0;
    overflow: hidden;
}
.row .overlay{
    background: transparent;
    background: linear-gradient(90deg,#fff 50%,hsla(0,0%,100%,.7) 75%,hsla(0,0%,100%,.5) 80%,hsla(0,0%,100%,.25) 90%,hsla(0,0%,100%,0));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.square{
    position: relative;
    display: flex;
    background: #ccc;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
.tileRemove{
    color: #fff;
    z-index: 101;
    background: rgba(0,0,0, 0.6);
    border: none;
    border-radius: 5px;
    font-size: 12px;
    position: absolute;
    top: 5px;
    left: 5px;
}