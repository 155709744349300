.container{

}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding: 10px 0 0px 0;
}
.header button{
    background-color: transparent;
    border: none;
}
.header .label{
    font-weight: bold;
}
.link:hover{
    color: #333;
    text-decoration: none;
}
.placeholder{
    padding: 15px;
    text-align: center;
}
.row{
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
}
.row .profile{
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-right: 10px;
}
.row .title{
    font-weight: bold;
    margin: 0;
}
.row .unseen{
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #4bbc4e;
}
.row .updated{
    margin:0;
}