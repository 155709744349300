.group{
    background-color: #354052;
    padding: 25px;
    border-radius: 10px;
    color: #fff;
    margin-bottom:50px;
}
.selectButton{
    color: #6c6c6c;
    display: flex;
    justify-content: space-evenly;
    white-space: nowrap;
    width: 100%;
    padding: .5rem 1rem;
    border-radius: 8px;
}
.selectContainer{
    margin-bottom: 50px;
}
.selectContainer i{
    display: none;
    padding: 25px;
    margin: -25px;
    cursor: pointer;
}
.selectHeading{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
.selectLabel{
    width: 100%;
}
.singleSelect{
    margin-bottom: 25px;
}
.singleSelect:last-child {
    margin-bottom: 0;
}
.singleSelect select{
    height: 40px;
}

@media screen and (max-width: 1200px) {
    .dropdowns{
        max-height: 0;
        overflow: hidden;
        transition: all ease .5s;
    }
    .group{
        padding: 0;
    }
    .group.open{
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
    .selectContainer{
        background: #354052;
        color: #fff;
        border-radius: 10px;
        padding: 25px;
    }
    .selectContainer i{
        display: inline-block;
    }
    .selectContainer > span{
        transition: all ease .5s;
    }
    .selectContainer.open{
        padding:25px;
    }
    .selectContainer.open>span{
        margin-bottom: 25px;
    }
    .selectHeading{
        margin: 0;
    }
}