.clipboard{
    width: 250px;
    display: block;
    margin: 0 auto 30px auto;
}
.intro{
    font-size: 16px;
    text-align: center;
}
.intro:last-of-type{
    margin-bottom: 25px;
}
.introHeading{
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 25px;
    text-align: center;
}
.name {
    margin: 40px 0 40px 0;
}
.fadeIn{
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
}
.start{
    margin: 0 0 25px 0;
    text-align: center;
}
.surveyIcon{
    max-width: 400px;
    margin-bottom: 40px;
    display: block;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}