.allRanks{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
.container{
    padding: 25px 15px 15px 15px;
    -webkit-box-shadow: 0px 14px 12px -9px rgba(0,0,0,0.45);
    box-shadow: 0px 14px 12px -9px rgba(0,0,0,0.45);
    position: relative;
    z-index: 1;
    background-color: #fff;
    top:0;
}
.rank{
    width: 60px;
    height: auto;
}
.rank.large{
    width: 90px;
}
.rankContainer{
    position: relative;
    cursor: pointer;
}
.rankContainer .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);
    top: 0;
    left: 0;
}
.rankName{
    text-transform: capitalize;
}