.container{
    min-height: 200px;
    position: relative;
    padding: 15px;
}
.lowerStats{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.lowerStats .stat{
    text-align: center;
    max-width: 130px;
}
.lowerStats .stat .number{
    font-size: 21px;
    font-weight: bold;
}
.reactions{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 15px;
    padding: 15px 15px 10px;
}
.reactions>div{
    display: flex;
    align-items: center;
    margin: 0 5px;
}
.reactions .icon{
    width: 20px;
    margin-right: 5px;
}
.reactions svg{
    margin-top: -2px;
}