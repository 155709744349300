/* Payment History  */
.table {
  width: 100%;
  border-collapse: collapse;
}

.tableHeader {
  border-bottom: 1px solid rgba(211, 211, 211, 0.362);
  padding-bottom: 20px;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid rgba(211, 211, 211, 0.362);
}

.pagination {
  text-align: center;
  margin-top: 20px;
}

.active {
  font-weight: bold;
}

.copyIcon {
  margin-left: 8px;
  cursor: pointer;
  color: lightgrey; /* Adjust color if needed */
}

.copyIcon:hover {
  color: #4e4d4d; /* Change color on hover */
}

/* Table header cell styles */
.tableHeaderCell {
  font-weight: 400;
  font-size: 14px;
}

/* Right-aligned text */
.alignRight {
  text-align: right;
}

/* Status and Date spans in the header */
.statusSpan {
  font-weight: 400;
  font-size: 14px;
  margin-right: 10px;
}

.dateSpan {
  font-weight: 400;
  font-size: 14px;
}

/* Payment amount styling */
.paymentAmount {
  font-weight: 500;
  font-size: 16px;
}

/* Flex container for status icons */
.statusContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

/* Margin for status icon */
.statusIcon {
  margin-right: 40px;
}
@media (max-width: 580px) {
  .paymentAmount {
    font-size: 14px;
  }

  .statusIcon{
    height: 12px;
    width: 12px;
    position: relative;
    top: -5px;
    margin-right: 35px;
  }

  .statusSpan {
    font-size: 12px;
  }
  .dateSpan {
    font-size: 12px;
  }
  .payment.date {
    font-size: 12px;
  }
  .paymentNumber {
    font-size: 12px;
  }
}


@media (max-width: 475px) {
  .paymentAmount {
    font-size: 14px;
  }

  .statusIcon{
    height: 12px;
    width: 12px;
    position: relative;
    top: -5px;
    left: 13px;

  }


  .statusSpan {
    font-size: 10px;
    
  }

  .dateSpan {
    font-size: 10px;
    
  }

}