.thrivePassContainer {
  height: 100vh;
  width: 75vw;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  gap: 150px;
}

.title {
  margin-left: auto;
  margin-right: auto;
  width: 75vw;
}

.thriveCreatOrderBtnContainer {
  width: 25%;
  height: 80%;
  min-width: 400px;
}
.thrivePassFormInputLabelContainer {
  display: block;
  display: flex;
  position: relative;
}
.dropdown {
  min-width: 220px;
  margin-right: 20px;
}

.closeBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  margin-left: auto;
  cursor: pointer;
  border-radius: 100px;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.closeBtnContainer:hover,
.removeBtn:hover {
  background-color: rgba(211, 211, 211, 0.294);
  opacity: 0.2;
}

.closeIcon {
  font-weight: 300;
}

.quantity {
  width: 80px;
  border-radius: 8px;
  height: 48px;
  border: 0;
  line-height: 38px;
  background: #fff;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}

.productContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 400px;
margin-bottom: 10px;

}

.removeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 6px;
  min-width: 100px;
  color: rgb(193, 14, 14);
  margin-left: auto;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 100px;
  position: relative;
  top: 8px;
  line-height: 1; /* Add this to maintain consistent vertical alignment */
}

.marginBottom {
  margin-bottom: 10px;
  min-width: 220px;
}

.placePaginationBottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  height: fit-content;
  background-color: #ffff;
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}

.dropdownLabel {
  border-radius: 8px !important;
}

.qtylabel {
  margin-right: 40px;
  margin-bottom: 10px;
}

.rmvText {
  position: relative;
  top: 1px;
  display: block;
}

.stateZipContainer{
    display: flex;
    gap: 40px

}
