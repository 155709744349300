.boldCopy{
    font-family: proxima-nova,sans-serif;
    font-size: 27px;
    color: #3a3a3a;
    font-weight: 700;
}
.boldCopy.extraBold{
    font-weight: 800;
}
.boldCopy.white{
    color: #fff;
}
.columnContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.columnContainer .leftColumn{
    max-width: 700px;
    margin-right: 30px;
}
.columnContainer .leftColumn,
.columnContainer .rightColumn{
    margin-bottom: 25px;
}
.columnContainer .rightColumn{
    max-width: 550px;
}
.container{
    padding: 55px;
}
.greyBackground{
    background-color: #ededed;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.green{
    color: #6BA543;
}
.green:hover{
    color: #6BA543;
}
.greenBackground{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px;
    background-color: #6ba543;
    border-radius: 15px;
}
.italicCopy{
    font-size: 27px;
    font-style: italic;
    color: #3a3a3a;
    text-decoration: none;
}
.italicCopy:hover{
    text-decoration: none;
    color: #3a3a3a;
}
.italicCopy.link{
    font-size: 24px;
    display: inline;
    word-break: break-all;
}
.mainHeading{
    font-family: proxima-nova,sans-serif;
    font-size: 47px;
    text-transform: none;
}
.mobileImage{
    aspect-ratio: 1/1;
    border-radius: 15px;
    padding:0 15px;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 700;
    position: relative;
    overflow: hidden;
    width: 100%;
}
.mobileImage .overlay{
    background-color: rgba(0, 0, 0, .2);
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 0;
}
.mobileImageContainer{
    padding: 0 15px 15px;
    width: 15%;
}
.mobileList{
    display: none;
    flex-wrap: wrap;
    margin: 0 -15px;
    width: 100%;
}
.mobileTitle{
    position: relative;
    z-index: 1;
}
.outerContainer{
    position: absolute;
    inset: 0;
    overflow: auto;
    background-color: #fff;
}
.qrCode{
    margin-right: 25px;
}
.qrCode svg{
    padding: 15px;
    background-color: #fff;
    height: 145px;
    width: 145px;
}
.qrCodeContainer{
    display: flex;
    align-items: center;
}
.shadow{
    box-shadow: 0 4px 5px rgba(0,0,0,.1);
}
.subHeading{
    font-family: proxima-nova,sans-serif;
    font-size: 30px;
    color: #3a3a3a;
    font-weight: 400;
}
.subHeading.small{
    font-size: 27px;
}
.trademark{
    font-size: 30px;
    line-height: 0;
    position: relative;
    top: 5px;
}
.whiteButton{
    background-color: #fff;
    border-radius: 15px;
    padding: 8px 24px;
    text-decoration: none;
}
.whiteButton:hover{
    text-decoration: none;
}

@media screen and (max-width: 1300px) {
    .boldCopy{
        font-size: 18px;
    }
    .columnContainer .leftColumn{
        max-width: 700px;
        margin-right: 30px;
    }
    .columnContainer .rightColumn{
        max-width: 350px;
    }
    .container{
        padding: 55px;
    }
    .greenBackground{
        padding: 30px;
    }
    .italicCopy{
        font-size: 18px;
    }
    .italicCopy.link{
        font-size: 16px;
    }
    .mainHeading{
        font-size: 32px;
    }
    .qrCode{
        margin-right: 25px;
    }
    .qrCode svg{
        padding: 5px;
        height: 120px;
        width: 120px;
    }
    .subHeading{
        font-size: 21px;
    }
    .subHeading.small{
        font-size: 18px;
    }
    .whiteButton{
        padding: 8px 24px;
    }
}

@media screen and (max-width: 991px) {
    .columnContainer{
        flex-wrap: wrap;
    }
    .columnContainer .leftColumn{
        margin-right: 0;
    }
    .columnContainer .rightColumn{
        display: none;
    }
    .mobileList{
        display: flex;
    }
    .mobileImageContainer{
        width: 25%;
    }
}

@media screen and (max-width: 700px) {
    .greenBackground{
        flex-wrap: wrap;
        text-align: center;
    }
    .greenBackground p{
        margin-bottom: 15px;
    }
    .mobileImageContainer{
        width: 33.3333%;
    }
    .qrCodeContainer{
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .mobileImageContainer{
        width: 50%;
    }
}