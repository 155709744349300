
.row{
    display: flex;
    padding: 15px 10px;
}
.row.listItem{
    overflow: hidden;
    background-color: #ecf2f6;
    margin-bottom: 15px;
}
 .row .content{
    flex: 1;
}
 .row .duration,
 .row .access,
 .row .views{
    width: 100px;
    text-align: center;
}
 .row .image{
    background-color: #cccccc;
    width: 75px;
    background-position: center;
    background-size: cover;
    margin: -15px 10px -15px -10px;
}


