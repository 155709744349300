.dropdownContainer {
    position: relative;
    display: inline-block;
  }
  
  .selectedOption {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #4bbc4e;
    font-weight: 400;
    color: white;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    background-image: none;
    outline: none !important;
    transition: background-color 0.3s ease;
    line-height: 1.42857143;
    margin-left: 15px;
    font-size: 16px;
    text-shadow: none;
    text-transform: unset;
    min-width: 167px;
  }
  
  .optionsList {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 0;
    margin: 0;
    margin-top: 5px;
    z-index: 999; /* Add this line to position the options list above other elements */
  }
  
  .option {
    padding: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .option:hover {
    background-color: whitesmoke;
    color: #4BBC4E;
  }
  