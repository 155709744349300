.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  max-width: 700px;
  margin: auto;
  gap: 10px;
}

/* image cards on landing page */
.square {
  position: relative; 
  width: 100%;
  text-decoration: none;
  color: var(--text-primary-color);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: top;
  margin: 5px;
  min-height: 160px;
}

.square::before {
  content: ""; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
/* video titles on the landing page */
.title {
  z-index: 1;
  color: var(--text-primary-color);
  padding: 0.5em 1em;
  border-radius: 10px;
  font-family: "industry", sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 24px;
  position: absolute;
}

/* header title landing page */
.headerTitle {
  text-align: center;
  color: var(--text-primary-color);
  font-family: "industry", sans-serif;
  font-weight: 800;
  font-size: 44px;
  width: 458px;
}

.headerSubtitle {
  text-align: center;
  color: var(--text-tertiary-color);
  font-size: 32px;
  font-family: 'UniversLTPro', sans-serif;
  font-weight: 300; 
}

/* footer title landing page */
.footerTitle {
  text-align: center;
  color: var(--text-secondary-color);
  font-family: "industry", sans-serif;
  font-weight: 800;
  font-size: 34px;


}

.footerSubTitle {
  text-align: center;
  color: var(--text-primary-color);
  width: 200px;
  font-family: "industry", sans-serif;
  font-weight: 800;
  font-size: 18px;
}

.footerStandardRates{
  text-align: center;
  color: var(--text-primary-color);
  width: 200px;
  font-family: "industry", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 15px;
}


@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .square {
    height: 150px;
  }


  .headerTitle {
    font-size: 28px;
    max-width: 320px;
  }

  .headerSubtitle {
    font-size: 22px;
  }
}


@media (max-width: 379px) {
  .square {
    width: 95%; /* Adjust the width as needed */
    min-height: 140px; /* Adjust the height as needed */
  }

  .title {
    font-size: 20px; /* Adjust the font size as needed */
  }
}