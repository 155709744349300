.createableSelect div[class *='-menu']{
    z-index: 10;
}
.dateContainer{
    display: flex;
    flex-wrap: wrap;
}
.dateContainer .date{
    width: 100%;
    margin-bottom: 15px;
}
.dateContainer .hour{
    flex: 1;
    margin-right: 15px;
}
.dateContainer .midday{
    flex: 1;
}
.inlineError{
    margin-top: -2rem;
    position: relative;
    top: 2rem;
}
:global(.ant-select-selector){
    height: 38px !important;
    border-radius: 4px !important;
    align-items: center;
    text-transform: capitalize;
    border-color: #cccccc !important;
}
:global([class$="indicatorSeparator"]){
    background-color: transparent !important;
}