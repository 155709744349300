.container{
    height: 250px;
    overflow-y: auto;
    position: relative;
}
.actions{
    flex: 1;
    padding-right: 15px;
}
.headers{
    color: #7e7e7e;
    text-decoration: underline;
    margin-bottom: -5px;
    font-size: 12px;
}
.date{
    font-size: 12px;
}
.last{
    color: #fff;
    background-color: #354052;
    cursor: pointer;
}
.row{
    padding: 7px 5px;
    margin-right: 5px;
}
.row.colored{
    background-color: #dedede;
}