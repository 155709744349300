.goal{
    position: relative;
    color: #fff;
    text-align: center;
}
.goalContainer{
    position: relative;
    width: 350px;
    height: 230px;
    color: #fff;
    text-align: center;
}

.innerContainer{
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    max-width: 650px;
    align-items: center;
}
.progress{
    cursor: pointer;
    height: 240px;
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-wrap: wrap;
}
.progress .bar{
    width: 12px;
    height: 125px;
    margin-top: 36px;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
}
.progress .bar .inner{
    background: #ffd800 ;
    background: linear-gradient(0deg, #ff8b02  0%, #ffd800  100%);
    height: 0;
    transition: all 1s ease;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 5px 5px 0 0;
    max-height: 100%;
}
.progress .monthlyLabel{
    line-height: 1;
    padding-top: 50px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
}
.progress .topBadge,
.progress .bottomBadge{
    position: absolute;
    width:55px;
    height: 55px;
    z-index: 5;
}
.progress .bottomBadge{
    bottom: 48px;
}
.progress .topBadge{
    top: 0;
}
.scoreInfo{
    height: 240px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}
.scoreInfo .info{
    text-align: center;
    line-height: 1;
}
.scoreInfo svg{
    width: 30px;
    margin-bottom: 5px;
    fill: #fff;
}
.subLabel{
    font-size: 12px;
}
.whiteContainer{
    inset: 0;
    position: fixed;
    z-index: 10000;
    color: #333;
    text-shadow: none;
    background: #fff;
    transition: transform 1s ease, opacity 1s ease, z-index 1s ease;
    overflow: auto;
}
.whiteContainer.hide{
    opacity: 0;
    z-index: -1;
}
@media screen and (min-width: 601px) {
    .goalContainer .label{
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .goalContainer{
        width: 150px;
        height: 200px;
    }
    .goalContainer .label{
        margin-bottom: 25px;
    }
    .progress{
        height: 200px;
    }
    .progress .bar{
        height: 80px;
    }
    .scoreInfo{
        height: 200px;
    }
}
@media screen and (min-width: 850px) {
    .whiteContainer.restrained{
        inset: unset;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 800px;
    }
    .whiteContainer.restrained :global(.footer-actions){
        position: sticky;
    }
    .whiteContainer.restrained :global(.footer-actions) button{
        position: relative;
        top: -25px;
    }
}