.answer{
    color: #333;
    width: 50%;
    padding: 0 10px 15px 10px;
}
.answer.full{
    width: 100%;
    padding: 0 10px 15px 0;
}
.answer.full .answerBtn{
    justify-content: flex-start;
    text-align: left;
}
.answerBtn{
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 16px;
    padding: 5px 10px;
    text-align: center;
    justify-content: center;
}
.answerBtn.complete{
    background-color: #4bbc4e;
}
.answerContainer{
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    margin: 0 auto;
}
.imageContainer{
    max-width: 375px;
    margin: 0 auto;
}
.image{
    padding: 15px 0 25px 0;
}
.nav{
    display: flex;
    margin-bottom: 25px;
}
.navBtn{
    border-radius: 5px;
    background-color: #ccc;
    color: #fff;
    margin-right: 10px;
    width: 30px;
    max-width: 30px;
    height: 30px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navBtn.complete{
    background-color: #4bbc4e;
}
.question{
    padding: 25px 0;
    font-size: 24px;
}
.question.first{
    padding-top: 0;
}
.question.grey{
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #cccccc;
    margin-right: -5%;
    margin-left: -5%;
    padding-right: 5%;
    padding-left: 5%;
}
.question.grey.last{
    border-bottom: none;
}