.header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-weight: bold;
}
.header .labelRight{
    font-style: italic;
    font-weight: 400;
}
.profile{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin: 0 15px 0 0;
}
.profileContainer{
    display: flex;
    margin: 15px 0 30px 0;
}