.container {
    padding: 20px;
    background-color: #fff;
    gap: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    align-self: center;
    justify-self: center;
    margin: 20px 20px 0;
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0,0,0,.2);
}
.input {
    min-width: 200px;
}
.outerContainer{
    display: flex;
    justify-content: center
}
.receiveCode{
    text-align: center;
    cursor: pointer;
}
.title {
    font-weight: 700;
    font-size: 20px;
    color: #6BA543;
}
