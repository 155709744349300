.audio{
    background-color: rgba(0,0,0,.5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 6px;
    position: absolute;
    top: 6px;
    right: 6px;
}
.audio svg{
    fill: #fff;
    position: relative;
    top: 0;
    right: 0px;
}
.childCategory{
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: -5px;
    transform: translateY(5px);
    display: inline-block;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
}
.container{
    padding: 15px;
}
.contentContainer{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    max-height: 600px;
    overflow: auto;
    padding: 15px;
}
.contentContainer :global(.event) {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
}
.form{
    display: none;
    padding-bottom: 10px;
}
.form.open{
    display: block;
}
.form .search{
    background: url("https://treo.s3.us-east-2.amazonaws.com/assets/magnifying_glass.png") no-repeat scroll 7px 8px;
    padding-left: 30px;
    background-size: 16px;
}
.filterIcon{
    display: flex;
    justify-self: flex-end;
    margin-left: auto;
    width: 25px;
    cursor: pointer;
}
.header{
    display: flex;
    margin-bottom: 15px;
}
.length{
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px 10px;
    background: rgba(0,0,0,.4);
    border-bottom-right-radius: 10px;
}
.link{
    background-color: rgba(0,0,0,.5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 3px 6px;
}
.link svg{
    top: 1px;
    left: 1px;
    position: relative;
}
.meta{
    display: flex;
    margin-bottom: 10px;
}
.pdf{
    background-color: rgba(0,0,0,.5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 4px 7px;
}
.pdf svg{
    top: 1px;
    left: 1px;
    position: relative;
}
.play{
    background-color: rgba(0,0,0,.5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 7px;
    position: absolute;
    top: 6px;
    right: 6px;
}
.play svg{
    fill: #fff;
    position: relative;
    top: -2px;
    right: -1px;
}
.taxonomy{
    display: flex;
}
.taxonomy .category{
    flex: 1;
}
.taxonomy .format{
    width: 75px;
}
.thumbnail{
    background: #333;
    display: block;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.title{
    color: #fff;
    background-color: #000;
    background-color: rgba(0,0,0,.6);
    position: absolute;
    width: 100%;
    bottom: 0;
    margin:0;
    padding:5px;
}