.congratulations{
    margin: 40px 0 120px 0;
    color: #4bbc4e;
    font-size: 24px;
    display: block;
    text-align: center;
}
.container{
    max-width: 500px;
    margin: 0 auto;
}
.flyIn{
    -webkit-animation: flyIn 1s ease-in; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: flyIn 1s ease-in; /* Firefox < 16 */
    -o-animation: flyIn 1s ease-in; /* Opera < 12.1 */
    animation: flyIn 1s ease-in;
}
.ringContainer{
    position: relative;
}
.rings{
    display: block;
    margin: 0 auto 120px auto;
    width: 134px;
    height: auto;
}
.splash{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation: flyIn .75s ease-in; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: flyIn .75s ease-in; /* Firefox < 16 */
    -o-animation: flyIn .75s ease-in; /* Opera < 12.1 */
    animation: flyIn .75s ease-in;
}
.subHeading{
    position: relative;
    z-index: 1;
    margin: 0;
    text-align: center;
}
@keyframes flyIn {
    from {
        transform: translate(-50%, -40%) scale(0) rotate(180deg);
        opacity: 0;
    }
    to   {
        transform: translate(-50%, -50%) scale(1) rotate(0deg);
        opacity: 1;
    }
}