.container{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.container .gridItem{
    width: 25%;
    padding: 0 15px;
    margin-bottom: 25px;
    overflow: hidden;
}
.gridItem:hover{
    text-decoration: none;
}
.icon{
    width: 20px;
}
.iconContainer{
    position: absolute;
    top: -15px;
    right: -15px;
    padding: 25px;
}
.info{
    padding: 10px;
    min-height: 95px;
    position: relative;
}
.instructor{
    padding-right: 25px;
}
.intensity{
    display: inline-block;
}
.intensity .dot{
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgba(42, 51, 66, .5);
    border-radius: 50%;
    margin-right: 5px;
}
.intensity .dot.active{
    background-color: rgba(42, 51, 66, 1);
}
.new{
    position: absolute;
    top: 30px;
    left: 5px;
    color: #fff;
    background: #d02a2a;
    border-radius: 5px;
    padding: 2px 5px;
}
.new.top{
    top: 5px;
}
.newDot{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: #fff;
    display: inline-block;
    position: relative;
    top: -2px;
}
.overlayLink{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}
.registered{
    font-size: 2.1rem;
    line-height: 0;
}
.theme{
    padding-right: 25px;
}
.thumbnail{
    width: 100%;
    background-position: top center;
    background-size: cover;
}
.titleCore{
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}
.titleSub{
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
}
.video{
    background: #fff;
    overflow: hidden;
    color: #2A3342;
    cursor: pointer;
    position: relative;
}
.watched{
    width: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
}
@media screen and (max-width: 1200px) {
    .container .gridItem{
        width: 33.333%;
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 900px) {
    .container .gridItem{
        width: 50%;
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 600px) {
    .container .gridItem{
        margin-bottom: 15px;
        width: 100%;
    }
}