.container{
    background-color: #fff;
    padding: 5px;
    margin: 3px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 5px 11px 4px rgba(0,0,0,0.23);
    box-shadow: 0px 5px 11px 4px rgba(0,0,0,0.23);
    display: flex;
    align-items: center;
}
.points svg{
    width: 50px;
    margin-right: 10px;
    fill: #4bbc4e;
    color: #4bbc4e;
}
.points img{
    width: 70px;
    margin-right: 15px;
}
.points svg.padded{
    padding: 5px;
}
.points.animation svg{
    animation: badgeAnimation 1s .5s;
    animation-delay: 1s;
}
.text{
    color: #333;
    text-shadow: none;
}
.text p{
    margin: 0;
    color: #4bbc4e;
    font-size: 16px;
}

@keyframes badgeAnimation {
    0% { transform: scale(1.05)}
    40% { transform: scale(1.1) }
    60% { transform: scale(0.6) }
    80% { transform: scale(0.95) }
    100% { transform: scale(0.85) }
}