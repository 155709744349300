.audio{
    width: 12px;
    height: 12px;
    margin-right: 5px;
    position: relative;
    top: -2px;
}
.audio svg{
    fill: #333;
}
.collections{
    background-color: #ECECEC;
    color: #333;
    border-radius: 20px;
    padding: 3px 10px;
    display: inline-block;
    margin: 0;
    font-size: 12px;
    font-weight: 700;
}
.container{
    position: relative;
    margin-bottom: 30px;
}
.icon{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 18px;
    right: 10px;
}
.length{
    color: #333;
    font-size: 12px;
    display: flex;
    align-items: center;
}
.link svg{
    width: 14px;
    height: 14px;
}
.new{
    position: absolute;
    top: 5px;
    left: 5px;
    color: #fff;
    background: #d02a2a;
    border-radius: 5px;
    padding: 2px 5px;
}
.newDot{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: #fff;
    display: inline-block;
    position: relative;
    top: -2px;
}
.overlayLink{
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
}
.pdf{
    width: 15px;
    height: 20px;
    color: #fff;
}
.pdf svg{
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
}
.play{
    width: 15px;
    margin-right: 5px;
    display: flex;
    align-items: center;
}
.play svg{
    fill: #333 !important;
    width: 100%;
}
.primaryLink{
    display: inline-block;
    padding: 1rem 3rem;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.42857143;
    border: none;
    color: #fff;
    background-image: none;
    outline: none !important;
    text-shadow: 2px 2px 5px rgba(0,0,0,.3);
    background-color: #4bbc4e;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: none;
}
.primaryLink:hover{
    color: #fff;
}
.thumbnail{
    background: #333;
    display: block;
    height:195px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    z-index: -1;
}
.title{
    color: #333;
    width: 100%;
    margin:0 0 5px 0;
}
.warning :global(.modal-content) {
    width: 300px;
    margin: 100px auto 0 auto;
    padding: 15px;
}
.watched{
    width: 15px;
    margin-left: 5px;
}