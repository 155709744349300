.copy{
    max-width: 800px;
    margin: 8px auto 0 auto;
}
.copy p,
.copy ul,
.copy ol{
    font-size: 16px;
    line-height: 22px;
}
.copy ul,
.copy ol{
    margin: 0 0 0 2rem;
    padding:0;
}
.copy li{
    margin: 5px 0;
}