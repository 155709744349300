.branch{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    display: block;
}
.orgId{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
    display: block;
}
.statusContainer{
    display: flex;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    margin-bottom: 15px;
}
.statusLabels{
    width: 100%;
}
.statusSubcontainer{
    display: flex;
    padding: 10px 25px;
    align-items: center;
    flex-wrap: wrap;
}
.statusSubcontainer > *{
    padding-right: 10px;
}
.statusSubcontainer :global(.form-group){
    margin-bottom: 0 !important;
}

@media screen and (max-width: 991px){
    .statusContainer{
        flex-wrap: wrap;
    }
}