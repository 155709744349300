.checkmark{
    width: 40px;
    height: 40px;
    margin-top: 8px;
    margin-bottom: -5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 40%);
    border: 4px solid #fff;
    background-color: #fff;
    border-radius: 50%;
}
.check{
    stroke-width: 5;
    stroke-miterlimit: 10;
    stroke: #4bbc4e;
    width: 30px;
    height: 30px;
}
.checkAnimated{
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    stroke-dasharray: 45;
    stroke-dashoffset: 45;
}

@keyframes stroke {
    to {
        stroke-dashoffset: 0;
    }
}