.demoContainer{
    display: flex;
    align-items: center;
}
input.demoInput{
    width: auto;
    margin: 0 10px 0 0;
    position: relative;
    top: -3px;
}
.demoLabel{
    margin-right: 10px;
}