.navbar-inverse {
    margin-bottom: 0px;
}

.navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.navbar {
    border-radius: 0px;
}

.navbar-inverse .navbar-collapse {
  text-align: center;
  margin-bottom: 0px;

}

.navbar-brand {

}

.user {
    color: white;
    display: inline-block;
    margin-right: 10px;
}

.logout-form {
    display: inline-block; 
    float: right;
}

.profile {
    float: right;
    padding-top: 10px;
    padding-right: 20px;
}

.client-header-links {
  padding-right: 4rem;
}












