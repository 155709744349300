.container{
    position: relative;
}
.container input{
    padding-right: 50px;
}
.eyeContainer{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: #ccc;
}
.eyeContainer svg{
    width: 25px;
}