/* Content Link Style*/
.contentLink{
    background-color: #fff;
    color: #000;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    text-transform: capitalize;
    position: relative;
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    height: 100%;
}
.contentLink.disabled .title{
    color: #c2c2c2;
}
.contentLink .text{
    flex: 1;
}
.contentLink .img{
    box-shadow: 0 0 10px rgba(0,0,0,.4);
    border: 4px solid #fff;
    border-radius: 50%;
    background: #fff;
}
.contentLink img.img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.disableLink {
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width: 100%;
}
/* Primary Style*/
.link.primary{
    background-color: #4bbc4e;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;
    box-shadow: none;
}

/* Secondary Style */
.link.secondary{
    color: #4bbc4e;
    background-color: #fff;
    font-size: 18px;
    font-weight: 400;
    border: 2px solid #4bbc4e;
    text-shadow: none;
    border-radius: 10px;
    box-shadow: none;
}
/* Base style*/
.link{
    text-transform: uppercase;
    display: inline-block;
    padding: 1rem 3rem;
    box-shadow: 0px 3px 5px rgba(0,0,0,.2);
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    border: none;
    border-radius: 40px;
    background-color: #354052;
    color: #fff;
    background-image: none;
    outline: none !important;
    text-shadow: 2px 2px 5px rgba(0,0,0,.3);
}

.link:hover{
    color: #fff;
    text-decoration: none;
}
.overlay{
    background: transparent;	
	background: -moz-linear-gradient(90deg, 
		rgba(255,255,255,1) 50%, 
		rgba(255,255,255,0.7) 75%, 
		rgba(255,255,255,0.5) 80%, 
		rgba(255,255,255,0.25) 90%, 
		rgba(255,255,255,0) 100%);
	background: -webkit-linear-gradient(90deg, 
		rgba(255,255,255,1) 50%, 
		rgba(255,255,255,0.7) 75%, 
		rgba(255,255,255,0.5) 80%, 
		rgba(255,255,255,0.25) 90%, 
		rgba(255,255,255,0) 100%);
    background: linear-gradient(90deg, 
		rgba(255,255,255,1) 50%, 
		rgba(255,255,255,0.7) 75%, 
		rgba(255,255,255,0.5) 80%, 
		rgba(255,255,255,0.25) 90%, 
		rgba(255,255,255,0) 100%);	
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}