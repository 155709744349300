.forgot-password {
        line-height: 55px;
}
/* Add padding to containers */
.login.container {    
    max-width: 60% !important;
    width:60%;
    position: absolute;
    letter-spacing: 1px;
    left: 0;
    background: #fff;
    color: #000;
    padding: 4rem 4rem 6rem 4rem;
    margin-bottom: -5px;
}
.login.container input {
    color: #616161;
}
h1 { text-transform: uppercase; margin-top: 1rem;     letter-spacing: initial;}
img.login-logo {
    margin: 0 2rem 1rem 0;
}

.error{
    color:red;
}

/* Full-width inputs */
.loginUser, .loginPass {
    width: 100%;
    padding: 12px 20px;
    margin: 0;
    display: inline-block;
    box-sizing: border-box;
}

/* Set a style for all buttons */
.loginButton {
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}

/* Add a hover effect for buttons */
.login.button:hover {
    opacity: 0.8;
}
.login .btn { margin-top: 1rem; }


/* The "Forgot password" text */
.login.span.psw {
    float: right;
    padding-top: 16px;
}
.app-container .login.container{
    width:100%;
    height: 100%;
}
.signupHeader{
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .app-container .login.container{
        height: unset;
    }
    .login-bg{
        background-color: #fff;
        height: 100%;
    }
    .signupHeader{
        flex-direction: column-reverse;
    }
    .login.container{
        max-width: 100% !important;
        width:100%;
    }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
    .login.span.psw {
        display: block;
        float: none;
    }
    .login.cancelbtn {
        width: 100%;
    }
}