.active {
    border: 1px solid #354052;
}
.dot{
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
}
.dot.unread{
    background-color: #f04d4d;;
}
.read{
    background: #ccc;
    font-weight: 400;
}
.thread{
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
}
.thread>*{
    margin-bottom: 0;
}
.thread .archive{
    color: #354052;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    height: 20px;
    margin-right: 10px;
}
.thread .archive svg{
    margin-right: 5px;
    width: 12px;
    height: 12px;
}
.thread .date{
    width: 130px;
    text-align: right;
}
.thread .sender{
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
}
.thread .subject{
    flex: 1;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}