.container{
    padding: 25px;
    background-color: #354052;
    font-size: 18px;
    border-radius: 10px;
}
.container.onlyDropdown{
    padding: 0;
    background-color: transparent;
}
.dropdown{
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border-radius: 10px;
}
.link{
    color: #fff;
    display: block;
    padding: 15px;
    border-radius: 10px;
}
.link:hover,
.link:active,
.link:focus{
    text-decoration: none;
    color: #fff;
}
.link:hover{
    background-color: #101927;
}
.link.active{
    background-color: #4BBC4E;
}
.link.active:hover{
    background-color: #4BBC4E;
}


.link.activeDropdown{
    border-bottom: 1px solid #4BBC4E;
}
.link.activeDropdown:hover{
    background-color: #4BBC4E;
}

.linkDropdown{
padding-left: 30px;
    font-size: 16px;

}

.dropdownBackground{
    padding: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.mobile{
    display: none;
}

@media screen and (max-width: 991px){
    .container{
        padding: 0;
        background-color: transparent;
    }
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
    }
}