.container{
    padding: 15px;
}
.heading{
    display: flex;
    flex-wrap: wrap;
}
.heading>div{
    margin: 0 85px 50px 0;
}
.heading>div:last-of-type{
    margin-right: 0;
}
.headingText{
    margin: 0 0 25px 0;
    font-size: 18px;
}
.healthGoal{
    display: flex;
    align-items: center;
    height: 60px;
}
.healthGoal img{
    width: auto;
    height: 55px;
    margin-right: 10px;
}
.healthGoal .goal{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.questionsContainer{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.questions{
    width: 33.333333%;
    padding: 0 15px 25px 15px;
}
.statistics{
    display: flex;
}

@media screen and (max-width: 1400px){
    .questions{
        width: 50%;
    }
}

@media screen and (max-width: 991px){
    .questions{
        width: 100%;
    }
}