.averages{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.averages .singleAverage{
    text-align: center;
    margin: 0 15px 15px 15px;
    min-width: 115px;
}
.averages h2{
    font-size: 32px;
    margin: 0;
}
.container {
    padding:15px 15px 0 15px;
    margin-bottom: 25px;
}
.container2{
    height: 145px;
    position: relative;
    margin-bottom: 25px;
}
.subLabel{
    font-size: 18px;
    font-weight: 400;
}
.toggles{
    width: 300px;
    margin-bottom: 15px;
}