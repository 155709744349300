.action{
    font-size: 18px;
    margin-top:0;
}
.button{
    border-radius: 10px;
    background-color: #eaf0f3;
    color: #5087a0;
    padding: 20px;
    margin-bottom: 20px;
    border: none;
    text-transform: capitalize;
}
.button:last-of-type{
    margin-bottom: 0;
}
.buttonContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    width: 100%;
}
.exit{
    font-size: 30px;
    color: #ccc;
    border-radius: 50%;
    border: 2px solid #ccc;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding-top: 2px;
}
.group{
    font-size: 16px;
    margin-bottom: 0;
    text-transform: capitalize;
}
.icon{
    width: 90px;
    height: 90px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectionContainer{
    padding: 50px 20px 20px 20px;
    text-align: center;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;
    position: relative;
}