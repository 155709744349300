/* video landing page */
.cardStyle {
  position: relative;
  height: 200px;
  width: 200px;
}

.headerTitle {
  font-size: 25px;
  color: white;
  width: 100%;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.thumbnailStyle {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.btnLocation {
  min-width: fit-content;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  position: absolute;
  left: 20px;
}

.trademark {
  font-size: 30px;
  color: #fff;
  height: fit-content;
  width: fit-content;
}

.VAtrademark {
  font-size: 30px;
  color: #fff;
  height: fit-content;
  width: fit-content;
}

.ATtrademark {
  font-size: 30px;
  color: #fff;
  height: fit-content;
  width: fit-content;
}

.titleStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 10px;
  text-align: center;
  width: 90%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logoContainer {
  max-width: 300px;
  max-height: 200px;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 50px;
}

.brandingLogo {
  max-height: 130px;
  object-fit: cover;
  padding: 25px;
}

.brandingContainer {
  background: linear-gradient(135deg, #399c39, #87c33f);
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleFontColor {
  color: #6ba543;
}

.subTitleFontColor {
  color: grey;
}

.overlayStyle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.videoContainerGreaterEight {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  gap: 20px;
}

.landingContainer {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  overflow-x: hidden !important;
}

.videoContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 900px;
  gap: 20px;
}

.videoTitleLanding {
  font-size: 20px;
  font-weight: bold;
}

.signUpBtnContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.signUpBtn {
  background: #4bbc4e;
  color: #fff;
  font-size: 18px;
  /* font-weight: 400; */
  border-radius: 10px;
  margin-top: 10px;
  width: 200px;
  box-shadow: none;
  border: 1px solid #4bbc4e;
  font-weight: bold;
}

/* single video  */

.headerBackground {
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 50px;
  background: #7dbb51;
  background: linear-gradient(135deg, #399c39 0%, #87c33f 100%);
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgba(120, 176, 6, 0.5);
  border-radius: 50%;
  margin-right: 5px;
}

.filled {
  background-color: rgba(120, 176, 61, 1);
}

.empty {
  background-color: rgba(120, 176, 6, 0.5);
}

.videoWorkoutContainer {
  display: flex;
  height: 140px;
  flex-direction: column;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(53, 64, 82);
  background: linear-gradient(
    0deg,
    rgba(53, 64, 82, 1) 0%,
    rgba(13, 16, 21, 1) 100%
  );
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.subtitleContainer {
  width: 100vw;
  max-width: 550px;
}

.iconContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.centerWidthWrapper {
  width: 100vw;
  max-width: 500px;
}

/* Redirect */

.redirectContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.subtitleContainer {
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 30px;
}

.titleContainer {
  margin-top: 40px;
  text-align: center;
}

.textColor {
  color: white;
  text-transform: uppercase;
  font-size: 16px;
}

.iconWrapper {
  display: flex;
  gap: 30px;
}

.learnMoreContainer {
  display: flex;
  gap: 20px;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.learnMoreContainer h4 {
  margin-bottom: 1px;
}

.alignContainerCenter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.videoTitle {
  font-size: 20px;
  /* text-transform: uppercase; */
  color: white;
  width: fit-content;
}

@media screen and (max-width: 660px) {
  .landingContainer {
    height: auto;
    overflow-y: hidden;
  }

  .cardStyle {
    max-height: 150px;
    max-width: 150px;
  }

  .videoTitleLanding {
    font-size: 14px;
  }

  .headerTitle {
    font-size: 16px;
    color: white;
  }
}

@media screen and (max-width: 896px) and (max-height: 415px) {
  .cardStyle {
    max-height: 100px;
    max-width: 100px;
  }

  .logoContainer {
    max-height: 200px;
    max-width: 200px;
  }

  .videoTitleLanding {
    font-size: 12px;
    font-weight: 400;
  }

  .subTitleFontColor {
    font-size: 20px;
  }

  .ATtrademark {
    position: absolute;
    right: 13px;
    top: 18px;
  }
  .VAtrademark {
    position: absolute;
    right: 1px;
    top: -4px;
  }

  .videoContainerGreaterEight {
    max-width: 600px !important;
  }

  .signUpBtnContainer {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .signUpBtn {
    font-size: 14px;
    width: 130px;
  }
}

@media screen and (max-width: 510px) {
  .headerTitle {
    font-size: 16px;
    color: white;
  }
  .logoContainer {
    max-height: 250px;
    max-width: 250px;
  }

  .signUpBtnContainer {
    flex-direction: column;
  }

  .brandingLogo {
    margin-top: 10px;
  }

  .subtitleContainer {
    padding: 15px;
  }

  .cardStyle {
    max-height: 120px;
    max-width: 120px;
    position: relative;
  }

  .videoTitleLanding {
    font-size: 12px;
  }

  .ATtrademark {
    position: absolute;
    right: 3px;
    top: -4px;
  }
}

@media screen and (max-width: 460px) {
  .videoWorkoutContainer {
    height: 120px;
  }
  .textColor {
    font-size: 12px;
  }

  .logoContainer {
    max-height: 200px;
    max-width: 200px;
  }
  .titleFontColor {
    font-size: 31px;
  }

  .subtitleContainer {
    padding: 20px;
  }
  .videoTitle {
    font-size: 20px;
  }

  .headerBackground {
    height: 70px;
    padding: 10px;
  }

  .iconWrapper {
    gap: 15px;
  }

  .signUpBtnContainer {
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ATtrademark {
    position: absolute;
    right: 3px;
    top: -4px;
  }
  .VAtrademark {
    position: absolute;
    right: 6px;
    top: -2px;
  }
}

@media screen and (max-width: 415px) {
  .cardStyle {
    max-height: 100px;
    max-width: 100px;
  }

  .videoTitleLanding {
    font-size: 12px;
    font-weight: 400;
  }

  .signUpBtn {
    font-size: 16px;

    width: 200px;
  }

  .videoContainer {
    gap: 10px;
  }

  .videoContainerGreaterEight {
    gap: 10px;
  }

  .ATtrademark {
    position: absolute;
    right: 13px;
    top: 18px;
  }
  .VAtrademark {
    position: absolute;
    right: 1px;
    top: -4px;
  }
}

@media screen and (max-width: 375px) {
  .subtitleContainer {
    padding: 20px;
  }

  .logoContainer {
    max-height: 200px;
    max-width: 200px;
  }
  .titleFontColor {
    font-size: 28px;
  }
  .iconWrapper {
    gap: 15px;
  }

  .headerTitle {
    font-size: 14px;
  }

  .btnLocation {
    margin-top: 2px;
  }
}

@media screen and (max-width: 328px) {
  .cardStyle {
    max-height: 90px;
    max-width: 90px;
  }

  .videoTitleLanding {
    font-size: 12px;
    font-weight: 400;
  }

  .titleFontColor {
    font-size: 25px;
  }

  .ATtrademark {
    position: absolute;
    right: 8px;
    top: 18px;
  }

  .VAtrademark {
    position: absolute;
    right: 10px;
    top: 18px;
  }
}

@media (max-height: 730px) {
  .videoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 500px;
    gap: 20px;
  }

  .videoContainerGreaterEight {
    max-width: 800px;
  }
}

@media (max-height: 600px) {
  .videoContainer {
    max-width: 500px;
  }
}
