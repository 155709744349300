.coach{
    font-size: 14px;
    font-weight: 700;
    padding: 5px 15px;
    margin-bottom: 5px;
    display: inline-block;
    cursor: pointer;
}
.coach.selected{
    background-color: #4BBC4E;
    border-radius: 5px;
    color: #fff;
}
.list{
    padding: 15px;
}
.member {
    font-size: 14px;
    color: #3b3b3b;
    margin-left: 20px;
    margin-bottom: 5px;
    cursor: pointer;
}
.memberSelected{
    background: #5d5d5d;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 5px;
    color: #fff;
}
.threadContainer{
    position: fixed;
    right: 0;
    bottom: 0;
    height: 500px;
    overflow: auto;
    width: 700px;
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%)

}
.threadContainer.hide{
    display: none;
}
@media screen and (min-width: 1400px){
    .threadContainer{
        height: 700px;
        width: 900px;
    }
}