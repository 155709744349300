.container{
    padding-top: 25px;
}
.count{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}
.exportBtn{
    margin-bottom: 10px;
}
.header{
    background: #fff;
    padding-bottom: 50px;
    border-bottom: 1px solid #dcdcdc;
}

@media screen and (max-width: 1200px) {
    .search{
        display: flex;
    }
    .search > div{
        width: 50%;
        align-self: flex-start;
    }
    .search > div:first-of-type{
        margin-right: 15px;
    }
    .search > div:last-of-type{
        margin-left: 15px;
    }
}

@media screen and (max-width: 600px) {
    .search{
        display: block;
    }
    .search > div{
        width: 100%;
    }
    .search > div:first-of-type{
        margin-right: 0;
    }
    .search > div:last-of-type{
        margin-left: 0;
    }
}