.container{
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}
.dateHeader{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.dateNav{
    display: flex;
    justify-content: space-between;
}
.dateNav i{
    background-color: #dee7eb;
    color: #828A8D;
    padding: 7px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: all ease .2s;
}
.dateNav i:hover{
    color: #E1E5EA;
    background-color: #828A8D;
}
.dateNav i:first-of-type{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.dateNav i:last-of-type{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dateYear{
    font-weight: 800;
    font-size: 18px;
    text-transform: capitalize;
}