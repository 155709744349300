/* HamburgerMenu.module.css */

.hamburgerMenu {
    position: absolute;
    top: -10px;
  }
  
  .hamburgerIcon {
    font-size:16px;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
  }
  
  .menuOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffff; /* Light background */
    z-index: 1000;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Arial, sans-serif;
  }
  
  .hamburgerLinks {
    list-style: none; /* Remove default bullet points */
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .link{
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between; /* This pushes the arrow icon to the right */
    width: 100%;
  }
  
  .linkItem {
    padding: 15px 20px;
    font-size: 18px;
    color: #333;
    cursor: pointer;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0; /* Light gray separator */
  }
  
  
  .linkItem:hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
  
  .hamburgerLinkLabel {
    display: flex;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between; /* This pushes the arrow icon to the right */
    width: 100%;
    text-decoration: none;
    cursor: pointer;

  }
  
  /* Submenu styling */
  .subLinks {
    list-style: none; /* Remove bullets from sublinks */
    padding-left: 30px; /* Indent sublinks */
    margin: 5px 0;
    display: none; /* Hide sublinks by default */
  }
  
  .subLinksVisible {
    display: block; /* Show sublinks when expanded */
  }
  
  /* Submenu link item styling */
  .subLinkItem {
    padding: 10px 0;
    font-size: 16px;
    color: #333;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0; /* Light gray separator */
  }
  


  .backIcon{
    padding: 20px;
    cursor: pointer;
  }
  
  .iconOpen,
  .iconClosed {
    font-size: 16px;
    transition: transform 0.3s;
    color: #4caf50; /* Green color for chevron */
  }
  
  .iconOpen {
    transform: rotate(90deg); /* Rotate when expanded */
  }
  
  .closeIcon {
    align-self: flex-end;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  padding: 20px;
  }
  