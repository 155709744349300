.back{
    border: none;
    background-color: transparent;
    color: #6c6c6c;
    display: flex;
    align-items: center;
}
.back svg{
    width: 24px;
    fill: #6c6c6c;
    margin-right: 15px;
}
.container{
    height: 300px;
    overflow-x: auto;
}
.more{
    border: none;
    color: #585858;
    padding: 2px 10px;
    margin: 5px 0 15px 0;
}
.reactions{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 0 0;
}
.reactions .chevron{
    width: 30px;
    padding: 0 5px 0 15px;
}
.reactions>div{
    display: flex;
    align-items: center;
    margin: 0 8px;
}
.reactions .icon{
    width: 20px;
    margin-left: 5px;
}
.reactions svg{
    margin-top: -2px;
}
.reactions .like svg{
    margin-top: -3px;
}
.row{
    cursor: pointer;
    display: flex;
    padding: 5px;
    margin-bottom: 10px;
}
.row:nth-child(odd){
    background-color: #cedde3;
}
.row.active{
    align-items: flex-start;
    justify-content: space-between;
    text-align: right;
    background: #f1f1f1;
}
.row .rowInfo{
    flex: 1;
}
.row .rowInfo .earned{
    font-size: 12px;
}
.row .rowInfo .label{
    font-size: 16px;
    margin: 0;
}
.victories{
    height: 235px;
    overflow: auto;
}