.container{
    display: inline-block;
    position: relative;
}
.icon{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, -50%);
    transition: opacity 1s ease;
}
.iconFilled{
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, -50%);
    transition: opacity 1s ease;
    opacity: 0;
}
.icon.animate, .iconFilled.animate{
    animation: likeAnimation 1s ease;
}
.icon.animate{
    opacity: 0;
}
.iconFilled.animate{
    opacity: 1;
}
.icon.noAnimationFill{
    opacity: 0;
    transition: unset;
}
.iconFilled.noAnimationFill{
    opacity: 1;
    transition: unset;
}
@keyframes likeAnimation {
    0%{
        transform: translate(0, -50%) scale(1) rotate(0);
    }
    40%{
        transform: translate(0, -50%) scale(1.2) rotate(-30deg);
    }
    100%{
        transform: translate(0, -50%) scale(1) rotate(0);
    }
}