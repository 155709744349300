.back{
    display: flex;
    justify-content: center;
}
.back:hover, .back:active, .back:visited{
    text-decoration: none;
    color: #fff;
}
.back span{
    color: #fff;
}
.back svg{
    fill: #fff;
    width: 35px;
    height: auto;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: -2px;
}