.container{
    background-color: #fff;
    padding: 15px;
}
.container ul{
    list-style: none;
    padding: 0 0 75px 0;
    margin: 0;
}
.row{
    display: flex;
    align-items: center;
    padding: 7px 0;
    border-bottom: 1px solid #ececec;
    color: #333;
    text-decoration: none;
}
.row:active, .row:hover{
    background-color: rgba(75, 188, 78, 0.4);
    color: #333;
    text-decoration: none;
}
.row .arrow{
    width: 10px;
    height: auto;
    fill: #ececec;
}
.row .icon{
    width: 30px;
    height: auto;
    fill: #ececec;
    stroke: #ececec;
    color: #ececec;
    margin-right: 25px;
}
.row .text{
    flex: 1;
    font-size: 16px;
}