.back{
    border: none;
    background-color: transparent;
    color: #4bbc4e;
    display: flex;
    align-items: center;
}
.back svg{
    width: 35px;
    fill: #4bbc4e;
    margin-right: 15px;
}
.container{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.profile{
    justify-self: flex-end;
    margin: 0 5px 0 0;
    width: 35px;
    height: 35px;
}
.profile :global(.profile-image) {
    width: 35px;
    height: 35px;
}
.mail{
    margin: 0 25px 0 auto;
    justify-self: flex-end;
    color: #4bbc4e;
    text-decoration: none;
    position: relative;
}
.mail:hover{
    color: #4bbc4e;
    text-decoration: none;
}
.mail .unread{
    font-family: proxima-nova,sans-serif;
    background: #f93b3f;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -12px;
    left: -10px;
}
.bronzeRank :global(.profile-image){
    border: 4px solid #d6764c !important;
}

.silverRank :global(.profile-image){
    border: 4px solid #9d9d9d !important;
}

.goldRank :global(.profile-image){
    border: 4px solid #f7b637 !important;
}

.platinumRank :global(.profile-image){
    border: 4px solid #a2cad8 !important;
}