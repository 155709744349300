.addButton{
    border: none;
    background-color: transparent;
}
.addButton svg{
    fill: #4BBC4E;
    width: 18px;
    height: auto;
    margin-left: 5px;
    transition: all .5s ease;
}
.addButton svg:hover{
    transform: rotate(90deg);
}
.form{
    margin-bottom: 15px !important;
}
.save{
    font-size: 14px !important;
    padding: 6px 20px;
    text-transform: unset;
    margin-left: 10px;
}
.tag{
    background-color: #9c9c9c;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.tag:hover{
    background-color: #3b3b3b;
}
.tag.selected{
    background-color: #354052;
}
.tagContainer{
    display: flex;
    padding: 15px;
    flex-wrap: wrap;
    margin-bottom: -10px;
}
.title{
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 10px 0;
}