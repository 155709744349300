.averages{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 2;
    text-align: center;
}
.break{ display: none;}
.container{
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 25px;
    display: flex;
    padding: 25px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    color: #333;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
}
.container:hover,
.container:focus,
.container:active{
    color:#000;
    text-decoration: none;
}
.demo{
    background-color: #ffc107;
    border-radius: 50px;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
}
.label{
    font-weight: bold;
}
.lifetime{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}
.meta{
    font-size: 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    max-width: 150px;
}
.name{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
.org{
    font-size: 14px;
    color: #565656;
    margin-bottom: 15px;
}
.profile{
    flex: 2;
}
.rank{
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    font-size: 12px;
    padding: 2px 8px;
    border-bottom-right-radius: 5px;
    text-transform: capitalize;
}
.rank.bronze{background-color: #d6764c;}
.rank.silver{background-color: #9d9d9d;}
.rank.gold{background-color: #f7b637;}
.rank.platinum{background-color: #a2cad8;}
.rank.topLeftBorder{
    border-top-left-radius: 10px;
}

@media screen and (max-width: 767px) {
    .break{
        width: 100px;
        flex-basis: 100%;
        display: block;
    }
    .averages,
    .lifetime{
        margin-top: 10px;
        width: 50%;
    }
}