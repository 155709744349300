.action{
    font-size: 18px;
    margin-top:0;
    text-transform: capitalize;
    margin-bottom: 30px;
}
.button{
    flex: 1;
    display: flex;
    align-items: flex-end;
}
.container{
     padding: 50px 40px 20px 40px;
     text-align: center;
     min-height: 550px;
     display: flex;
     flex-direction: column;
     align-items: center;
     z-index: 101;
     position: relative;
 }
.date{
    width: 166px;
    border-radius: 11px;
    padding: 5px 25px;
    font-size: 12px;
    margin-bottom: 50px;
}
.exit{
    font-size: 30px;
    color: #ccc;
    border-radius: 50%;
    border: 2px solid #ccc;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding-top: 2px;
}
.group{
    font-size: 16px;
    margin-bottom: 0;
    text-transform: capitalize;
}
.icon{
    width: 90px;
    height: 90px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon svg{
    width: 80%;
    height: 80%;
}
.slider:global(.rc-slider) {
    height: 23px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 20%);
}
.slider :global(.rc-slider-mark) {
    top: 38px;
}
.slider :global(.rc-slider-handle){
    transform: translateX(0)!important;
    background: red;
    width: 40px;
    height: 40px;
    margin-top: -14px;
    border: 5px solid #fff;
    box-shadow: 0 0 15px rgb(0 0 0 / 50%), inset 0 0 10px 0 rgb(0 0 0 / 80%);
}
.units{
    margin-top: 40px;
}