.container{
    display: flex;
    margin-top: 25px;
}
.display{
    width: 400px;
    margin-right: 25px;
}

.schedule{
    flex:1;
}
.schedule :global(.fc-toolbar-title) {
    font-size: 16px;
}
.schedule :global(.fc-toolbar-chunk) button{
    height: 32px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 14px;
}
.schedule table{
    margin-top: 0;
}
.schedule :global(.fc-col-header-cell-cushion) {
    color: #333;
    text-decoration: none;
}
.schedule :global(.fc .fc-daygrid-day-number) {
    color: #333;
}
.schedule :global(.fc-event-title) {
    color: #333;
}
.schedule :global(.fc-event) {
    background-color: #e8e8e8;
}