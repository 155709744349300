.score{
    font-size: 24px;
    font-weight: 600;
}
.scoreLabel{
    margin: 0;
    font-size: 12px;
}
.stat{
    display: flex;
    flex-direction: column;
    background: #fff;
    color: #1a1a1a;
    text-shadow: none;
    padding: 15px 10px 10px 10px;
    border-radius: 5px;
    max-width: 100px;
    align-items: center;
    margin-right: 15px;
    flex: 1;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0,0,0,.1);
}
.stat:last-of-type{
    margin-right: 0;
}

@media screen and (max-width: 767px) {
    .stat{
        max-width: 767px;
    }
}