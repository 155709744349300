.container-fluid.app-container,
.app-container {
    padding: 0;
}

.navbar-inverse {
    margin-bottom: 0px;
}
 
.navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
  margin-top: 1.5rem;
}
.navbar-inverse .navbar-nav>li>a {
    color: #fff;
    font-size: 18px;
    font-weight: 100;
    padding: 1rem 3rem;
    border-radius: 100px;    
    background: transparent;
    transition: .2s background-color linear;
}
.navbar-inverse .navbar-nav>li>a:focus, .navbar-inverse .navbar-nav>li>a:hover {
    color: #fff;
    background-color: #283140;    
    transition: .2s background-color linear;
}
.navbar {
    height: 76px;
    border-radius: 0px;
    background-color: #354052;
    background-image: none;
    border: none;
}
.navbar-inverse .navbar-collapse {
  text-align: center;
  margin-bottom: 0px;

}
/* for the logo */
.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}
.navbar-inverse .navbar-nav>li>a {
    color: #fff;
    font-size: 18px;
    font-weight: 100;
}
.user {
    color: white;
    display: inline-block;
    margin-right: 10px;
}

.logout-form {
    display: inline-block; 
    float: right;
}
.profile {
    float: right;
    padding-top: 10px;
    padding-right: 20px;
}










