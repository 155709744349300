.pendingFlag{
    background: #4b92bc;
    color: #fff;
    padding: 1px 8px;
    border-radius: 5px;
}
.rejectionFlag{
    margin: 5px 0 0 0;
    color: #a10000;
    font-weight: 400;
}