/* Main Container Styles */

/* Base font sizes */
.habitValue {
  font-size: 12px;
}

.habitUnit {
  font-size: 10px;
  line-height: 1.1;
}

.habitTitleContainer {
  font-size: 10.5px;
  color: #000000;
  margin-bottom: 2px;
  width: 70%;
  line-height: 1.2;
}

.customHabitValueContainer {
  width: 80%;
  height: 60%;
  position: absolute;
  bottom: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.customHabitValueWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customHabitValue {
  color: #212427;
  font-weight: 600;
  font-size: 14px;
}

.customHabitUnit {
  color: #212427;
  font-weight: 500;
  font-size: 10px;
  margin-left: 2px;
  line-height: 1.2;
}

.sectionHeader {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}

.container {
  flex: 1;
}

/* DotProgress styles */
.dotProgressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 10px;
  width: 100%;
}

.dotProgressCircles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
  flex-wrap: wrap;
}

.dotProgressCircle {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 0 2px;
}

.dotProgressCircleFilled {
  border: none;
}

.dotProgressCircleEmpty {
  background-color: transparent;
  border: 1.5px solid;
  border-style: solid;
}

.dotProgressValue {
  font-size: 18px;
  margin-bottom: 2px;
  text-align: center;
  max-width: 80px;
}

.dotProgressUnit {
  font-size: 9px;
  margin-top: 2px;
  text-align: center;
  max-width: 100px;
  flex-wrap: wrap;
}

.pageContainer {
  flex: 1;
  /* background-color: #f8f9fa; grey-lightest */
  padding: 0 20px;
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -6px;
}

.historyButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #2D2F41;
  padding: 4px 7px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  gap: 4px;
}

.historyText {
  color: #ffffff;
  font-size: 11px;
}

.pageTitle {
  font-size: 24px;
  color: #212427; /* black */
}

.statsContainer {
  background-color: #ffffff; /* white */
  border-radius: 12px;
  padding: 6px;
  margin-bottom: 12px;
}

.statsRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  overflow: visible;
}

.statIconContainer {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWithShadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.lifetimeScoreContainer {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 130px;
  background-color: transparent;
}

.statValue {
  font-size: 11px;
  color: #212427; /* black */
  text-align: center;
  margin: 2px 0;
  font-weight: 700;
}

.statLabel {
  font-size: 14px;
  color: #212427; /* black */
  text-align: center;
  margin-bottom: 2px;
}

.streakTileContainer {
  margin-bottom: 16px;
}

.streakTile {
  background-color: #ffffff; /* white */
  border-radius: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
  height: 72px;
}

.streakIconContainer {
  width: 52px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.streakContentContainer {
  flex: 1;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -14px;
}

.streakLabel {
  font-size: 12px;
  color: #212427; /* black */
  margin-bottom: 6px;
  font-weight: 600;
}

.progressBarContainer {
  flex: 1;
  position: relative;
}

.streakValue {
  font-size: 11px;
  color: #212427;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.streakProgressBar {
  height: 7px;
  background-color: #eaecf1;
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
}

.streakProgressFill {
  height: 100%;
  border-radius: 3px;
}
.achievementRow {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.largeTile {
  background-color: #ffffff; /* white */
  border-radius: 12px;
  padding: 12px;
  flex: 2;
  height: 120px;
}

.monthlyLoginTile {
  background-color: #ffffff; /* white */
  border-radius: 12px;
  padding: 16px;
  flex: 1;
  height: 120px;
  position: relative;
  overflow: hidden;
  width: 31%;
}

.monthlyLoginTitle {
  font-size: 11px;
  color: #212427; /* black */
  flex-wrap: wrap;
  width: 70%;
}

.monthlyLoginValue {
  font-size: 22px;
  color: #ff9429;
  margin-top: 10px;
  margin-bottom: 0;
}

.calendarContainer {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.calendarRow {
  flex-direction: row;
  gap: 3px;
  margin-bottom: 3px;
  display: flex;
}

.calendarDot {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #6BA543; /* green */
}

.squareTile {
  background-color: #ffffff; /* white */
  border-radius: 12px;
  padding: 16px;
  flex: 1;
  height: 120px;
  position: relative;
  overflow: hidden;
  width: 31%;
}

.achievementTitleContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
}

.tileMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 8px;
}

.tileMessageText {
  font-size: 11px;
  color: #6c757d;
}

.progressContainer {
  display: flex;
  flex-direction: column;
}

.progressRow {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 4px;
}

.progressValue {
  position: absolute;
  bottom: 24px;
  right: 12px;
  font-size: 10px;
  color: #777777;
}

/* Modal Styles */
.victoryModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1000;
}

.victoryModalContent {
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
  min-height: 50%;
  max-height: 70vh;
  max-width: 500px;
  width: 100%;
  align-self: center;
  overflow-y: auto;
  position: relative;
}

.historyModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.historyModalContent {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  position: relative;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  position: relative;
}

.modalScrollContent {
  overflow-y: auto;
  padding-bottom: 20px;
}

.modalTitle {
  font-size: 24px;
  color: #212427;
  text-align: center;
}

.closeButton {
  position: absolute;
  right: 6px;
  top: 4px;
  border: none;
  background: rgba(234, 236, 241, 0.6);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.habitProgressWrapper {
  position: relative;
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
}

.monthPickerHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 46px;
  padding: 10px 0;
}

.monthPickerTitle {
  font-size: 18px;
  color: #212427;
}

.victoryGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 8px;
  padding-bottom: 40px;
  margin-top: 10px;
}

.victoryItem {
  width: 33.33%;
  padding: 8px;
  margin-bottom: 16px;
  position: relative;
}

.victoryCount {
  position: absolute;
  bottom: -20px;
  right: 36px;
  background-color: #eaecf1;
  padding: 2px 10px;
  border-radius: 12px;
}

.victoryCountText {
  font-size: 12px;
  color: #777777;
}

.noVictoriesContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.noVictoriesText {
  font-size: 18px;
  color: #777777;
  text-align: center;
}

.monthNavButton {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.achievementTitle {
  font-size: 10.5px;
  color: #212427; /* black */
  margin-bottom: 0;
  flex-wrap: wrap;
  width: 70%;
  line-height: 1.2;
}

.monthlyLoginDays {
  font-size: 12px;
  color: #212427; /* black */
  margin-top: -4px;
}

.squareTileRow {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 12px;
}

.squareTile {
  background-color: #ffffff; /* white */
  border-radius: 12px;
  padding: 10px;
  position: relative;
  width: 31%;
  height: 100px;
  overflow: hidden;
}

/* Daily Score Tile */

.circularProgressBar {
  transition: stroke-dashoffset 1s ease-in-out;
}

.dailyScoreTile {
  background-color: #ffffff; /* white */
  border-radius: 12px;
  padding: 16px;
  flex: 2;
  height: 120px;
}

.dailyScoreContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.dailyScoreProgressGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dailyScoreNumber {
  font-size: 30px;
  color: #6BA543; /* greenFlat */
  margin-top: 2px;
  font-weight: 600;
  display: block;
}

.dailyScoreTextContainer {
  flex: 1;
  margin-left: 14px;
  display: flex;
  flex-direction: column;
}

.dailyScoreTitle {
  font-size: 12px;
  color: #212427; /* black */
  margin-bottom: 2px;
  font-weight: 600;
  display: block;
}

.dailyScoreSubtitle {
  font-size: 12px;
  color: #212427; /* black */
  font-weight: 600;
  display: block;
}

/* Square Tiles */
.squareTileRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 12px;
}

.squareTile {
  background-color: #ffffff; /* white */
  border-radius: 12px;
  padding: 10px;
  position: relative;
  width: 31%;
  height: 100px;
  overflow: hidden;
}
.iconHabit {
  position: absolute;
  right: -5px;
  top: -3px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.iconHabitBackground {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 37px;
  height: 37px;
  border-radius: 18px;
}

.recentVictoryContainer {
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
  width: 58px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tileMessageText {
  font-size: 9px;
  color: #6c757d; /* grey-dark */
  text-align: center;
  margin-top: 4px;
  padding: 0 10px;
}

.carrotIconContainer {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.progressContainer {
  margin-top: auto;
}

.progressBar {
  position: absolute;
  bottom: 12px;
  left: 12px;
  right: 12px;
  height: 7px;
  background-color: #eaecf1; /* grey-light */
  border-radius: 3px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  border-radius: 3px;
}

.rankMedalsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: '100%';
  margin-bottom: 8px;
}

.rankMedalLeft {
  flex: 1;
  align-items: flex-start;
}

.rankMedalRight {
  flex: 1;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}

.maxRankIndicator {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressRow .lockBadge {
  width: 36px !important;
  height: 36px !important;
}

.victoryIconWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.victoryCount {
  position: absolute;
  bottom: -20px;
  right: 36px;
  background-color: #eaecf1; /* grey-light2 */
  padding: 2px 10px;
  border-radius: 12px;
}

.victoryCountText {
  font-size: 12px;
  color: #777777; /* grey-dark */
}

/* Activity Earnings Styles */
.activityEarningsTile {
  background-color: #ffffff; /* white */
  border-radius: 12px;
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100px;
  margin-bottom: 12px;
  overflow: hidden;
}

.earningsTileTitle {
  font-size: 12px;
  color: #212427; /* black */
  text-align: left;
}

.earningsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 4px;
  height: 54px;
  width: 100%;
}

.earningItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 0 4px;
}

.earningLabel {
  font-size: 14px;
  color: #212427; /* black */
  text-align: left;
}

.earningSubLabel {
  font-size: 9px;
  color: #212427; /* black */
  text-align: left;
}

.earningValue {
  font-size: 15px;
  text-align: left;
  margin-top: 2px;
}

.divider {
  width: 1px;
  align-self: stretch;
  background-color: #eaecf1; /* grey-light2 */
  margin: 0 8px;
}

/* Custom Habit Styles */
.customHabitContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.customHabitValue {
  font-size: 20px;
  color: #212427; /* black */
  text-align: center;
  max-width: 80px;
  margin-left: 6px;
  margin-top: 6px;
}

.customHabitUnit {
  font-size: 9.5px;
  color: #777777; /* grey-dark */
  text-align: center;
  margin-top: 6px;
  margin-left: 6px;
  max-width: 80px;
  flex-wrap: wrap;
}

/* Utility Classes */
.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.centered {
  justify-content: center;
  align-items: center;
}

/* Animations */
.modalOverlay {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 480px) {
  .squareTile {
    width: calc(50% - 6px);
  }
  
  .victoryGridItem {
    width: 50%;
  }
}

/* Circular Progress */

.progressValueOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Media query for larger screens */
@media (min-width: 600px) {
  .pageTitle {
    font-size: 30px;
  }

  .sectionHeader {
    font-size: 24px;
  }

  /* Make all text in achievement tiles larger */
  .achievementsList {
    font-size: 16px;
  }

  /* Increase size of numbers and labels in tiles */
  .content {
    font-size: 16px;
  }

  .habitValue {
    font-size: 14px;
  }

  .habitUnit {
    font-size: 13px;
    line-height: 1.1;
  }

  .habitTitleContainer {
    font-size: 12.5px;
  }

  .customHabitValue {
    font-size: 20px;
  }
  
  .customHabitUnit {
    font-size: 12px;
  }

  /* DotProgress responsive styles */
  .dotProgressValue {
    font-size: 22px;
  }
  .dotProgressUnit {
    font-size: 11px;
  }
  
  /* CircularProgressbar responsive styles */
  .habitProgressWrapper {
    width: 45px;
    height: 45px;
    min-width: 45px;
    max-width: 45px;
    min-height: 45px;
    max-height: 45px;
  }

  .statLabel {
    font-size: 16px;
  }

  .statValue {
    font-size: 13px;
  }

/* Achievement titles */
  .achievementTitle {
    font-size: 12.5px;
  }

  /* Earnings labels */
  .earningsTileTitle {
    font-size: 13px;
  }

  .earningLabel {
    font-size: 16px;
    text-align: center;
  }

  .earningSubLabel {
    font-size: 10.5px;
    text-align: center;
  }

  .earningValue {
    text-align: center;
  }

  .tileMessageText {
    font-size: 11px;
  }

  .streakLabel {
    font-size: 13px;
  }

  .streakValue {
    font-size: 12px;
  }

  .victoryCountText {
    font-size: 14px;
  }

  .progressValue {
    font-size: 11px;
  }

  .dailyScoreTitle {
    font-size: 15px;
  }

  .dailyScoreSubtitle {
    font-size: 15px;
  }

  .dailyScoreNumber {
    font-size: 32px;
  }

  /* Monthly Login tile adjustments */

  .calendarContainer {
    bottom: 15px;
    right: 32px;
  }

  .calendarRow {
    gap: 4px;
    margin-bottom: 4px;
  }

  .calendarDot {
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
  }

  .monthlyLoginValue {
    font-size: 26px;
    margin-left: 20px;
    margin-top: 0px;    
  }

  .monthlyLoginDays {
    font-size: 14px;
    margin-left: 20px;
  }

  .progressRow img,
  .progressRow svg {
    width: 36px !important;
    height: 36px !important;
  }

  .maxRankIndicator {
    width: 36px;
    height: 36px;
  }

  .progressRow .lockBadge {
    width: 40px !important;
    height: 40px !important;
  }

  /* Adjust progress bar position */
  .progressBar {
    bottom: 8px;
  }

  /* Make history button larger */
  .historyButton {
    padding: 6px 10px;
    gap: 6px;
    transform: scale(1.15);
    transform-origin: right center;
  }

  .historyText {
    font-size: 13px;
  }

  /* Center daily score tile content */
  .dailyScoreTile {
    padding: 16px 24px;
  }

  .dailyScoreContent {
    justify-content: center;
  }

  .dailyScoreProgressGroup {
    justify-content: center;
  }

  .dailyScoreTextContainer {
    flex: 0 1 auto;
    margin-left: 50px;
  }

  /* Make progress circle larger */
  .dailyScoreProgressGroup :global(.ant-progress) {
    transform: scale(1.2);
    transform-origin: left center;
  }

  /* Make progress dots larger on wider screens */
  .dotProgressCircle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin: 0 3px;
  }
  
  .dotProgressCircleEmpty {
    border-width: 2px;
  }
}
