.back{
    width: 35px;
    height: auto;
    fill: #333333;
    cursor: pointer;
    margin-right: 15px;
    position: relative;
    top: 2px;
}
.collection .collectionCheckbox {
    display: flex;
    align-items: center;
}
.collection .collectionCheckbox input{
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
}
.collection label{
    margin: 0;
}
.container{
    display: flex;
    margin: 0 -15px;
}
.containerContent{
    flex: 7;
    padding: 0 15px;
}
.containerForm{
    flex: 3;
    padding: 0 15px;
    margin-bottom: 40px;
}
.head{
    text-transform: capitalize;
    margin: 0;
}
.subCategoryHead{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
    .container{
        flex-direction: column;
    }
    .containerContent,
    .containerForm{
        flex: 1;
    }
}