.boxes{
    padding: 2rem 4rem;
}
.dateRange{
    display: flex;
    align-items: center;
    max-width: 350px;
}
.heading2{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
}
.levelActivity{
    flex: 1;
    padding: 15px;
    margin: 0 25px 25px 0;
}
.levelActivityRow{
    display: flex;
}
.memberActivity{
    margin: 0 0 10px 0;
}
.scoreActivityRow{
    padding: 15px;
    margin-bottom: 25px;
}
.streakRow{
    padding: 15px;
    margin-bottom: 40px;
}
.targetedActivity{
    flex: 1;
    padding: 15px;
    margin-bottom: 25px;
}
.tileActivityRow{
    padding: 15px;
}
.tileActivityRow .tabs{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.tileActivityRow .tabs .tab{
    padding: 5px 8px;
    background-color: transparent;
    border: none;
    margin-bottom: 5px;
    border-radius: 5px 5px 0 0;
    text-transform: capitalize;
}
.tileActivityRow .tabs .tab.active{
    border-bottom: 2px solid #354052;
    background: #d3defc;
    color: #354052;
    font-weight: bold;
}