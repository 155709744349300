.container{
    height: 250px;
    position: relative;
}
.innerCircle{
    background: rgba(0,0,0, .3);
    border-radius: 50%;
    height: 250px;
    width: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
}
.innerCircle .actualScore{
    font-size: 50px;
}
.innerCircle h2{
    color: #fff;
    margin: 0;
}
.innerCircle span{
    font-size: 26px;
}
.innerCircle .label{
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: -15px;
    font-weight: 400;
}
/*.outerCircle {*/
/*    width: 350px;*/
/*    height: 350px;*/
/*    position: absolute;*/
/*    z-index: 0;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    border: 7px solid rgba(255, 255, 255, .2);*/
/*    border-radius: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/
.outerCircle :global(.VictoryContainer) {
    width: 155%;
    height: 155%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
}
.outerCircle :global(.CircularProgressbar-trail){
    stroke: rgba(255, 255, 255, .2);
    stroke-width: 2;
}
.outerCircle :global(.CircularProgressbar-path){
    stroke: #fff;
    stroke-width: 6;
}
.outerCircle :global(.CircularProgressbar ){
    overflow: visible;
    width: 100%;
    height: 100%;
}

/* SMALL STYLES*/

.container.small{
    height: 150px;
}
.small .innerCircle{
    height: 100px;
    width: 100px;
}
.small .innerCircle .actualScore{
    font-size: 25px;
}
.small .innerCircle span{
    font-size: 13px;
}
.small .label{
    display: none;
}
.small .outerCircle {
    width: 150px;
    height: 150px;
}
.small .outerCircle :global(.VictoryContainer) {
    width: 134% !important;
    height: 134% !important;
    touch-action: none;
    position: absolute !important;
    top: -21px !important;
    left: -21px !important;
}

@media screen and (max-width: 600px) {
    .container{
        height: 150px;
    }
    .innerCircle{
        height: 100px;
        width: 100px;
    }
    .innerCircle .actualScore{
        font-size: 25px;
    }
    .innerCircle span{
        font-size: 13px;
    }
    .label{
        display: none;
    }
    .outerCircle {
        width: 150px;
        height: 150px;
    }
    .outerCircle :global(.CircularProgressbar-path){
        stroke-width: 10;
    }

    .outerCircle :global(.CircularProgressbar-trail){
        stroke: rgba(255, 255, 255, .2);
        stroke-width: 4;
    }
    .outerCircle :global(.VictoryContainer) {
        width: 120%;
        height: 120%;
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
    }
}