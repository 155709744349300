.button{
    background-color: transparent;
    border: none;
}
.button.preview{
    color: #4BBC4E;
}
.button.remove{
    color: #a94442;
    margin-left: 20px;
}
.calendar{
    flex: 1;
    cursor: pointer;
}
.calendarContainer{
    display: flex;
    padding: 15px 20px;
}
.calendar .editedBy{
    font-size: 10px;
    margin: 0;
}
.calendar .title{
    margin: 0;
}
.calendar .url{
    margin: 0 0 10px 0;
}
.onboardingCheckbox{
    width: auto;
    margin-left: 10px !important;
    top: 2px;
    position: relative;
}