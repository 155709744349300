.boxes{
    padding: 2rem 4rem;
}
.icon{
    width: 18px;
    height: 18px;
    margin-left: 0.5rem;
    top: -8px;
    position: relative;
    display: inline-block;
}

