.container{
    text-align: center;
    margin: 0 auto;
    padding: 50px 15px;
    max-width: 400px;
}
.disclaimer{
    font-size: 14px;
    color: #6BA543;
    font-weight: 700;
    text-align: center;
    margin: 0;
}
.greenBackground{
    background-color: #6BA543;
    border-radius: 15px;
    padding: 40px 25px;
    margin-bottom: 25px;
}
.heading{
    font-size: 40px;
    margin: 0 0 40px 0;
    font-family: proxima-nova,sans-serif;
    text-transform: unset;
    color: #6BA543;
}
.list{
    list-style: none;
    padding: 0;
    margin: 0 0 40px 0;
}
.list .button{
    background-color: #F7F2F6;
    border-radius: 10px;
    padding: 8px 24px;
    display: flex;
    margin-bottom: 25px;
    color: #333;
    font-weight: 700;
}
.list .button.selected{
    background-color: #628A45;
    color: #fff;
}
.list .button button{
    border: none;
    width: 100%;
    text-align: left;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
}
.list .button img{
    width: 45px;
    margin-right: 15px;
}
.shadow{
    box-shadow: 0 4px 5px rgba(0,0,0,.1);
}
.subHeading{
    color: #fff;
    font-size: 27px;
    font-family: proxima-nova,sans-serif;
    margin: 0 0 40px 0;
    font-weight: 700;
}
.submit{
    background-color: #fff;
    border-radius: 10px;
    color: #6BA543;
    font-weight: 700;
    border: none;
    padding: 8px 24px;
    font-size: 18px;

}