.addButton{
    text-align: right;
}
.confirm{
    display: none;
    flex-direction: column;
    align-items: center;
}
.formFooter{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.formFooter .deactivate{
    color: #a94442;
    text-decoration: underline;
    background: transparent;
    box-shadow: none;
    text-transform: none;
    padding: 0;
    text-shadow: none;
}
.history{
    color: #676767;
    text-decoration: underline;
    background: transparent;
    box-shadow: none;
    text-transform: none;
    padding: 0;
    text-shadow: none;
}
.historyContainer{
    max-height: 0;
    overflow-x: auto;
    overflow-y: hidden;
    transition: all .5s ease;
}
.search{
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    align-items: center;
}
.searchButton{
    flex: 1;
    max-width: 150px;
}
.searchInput{
    flex: 4;
    margin-right: 1rem;
}

@media screen and (max-width: 767px) {
    .addButton{
        text-align: left;
    }
    .searchButton{
        flex: 1;
    }
    .searchInput{
        flex: 1;
    }
}