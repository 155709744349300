.colorContainer{
    margin-top: 2rem;
}
.color{
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 35px;
    height: 42px;
    margin-right: 10px;
    float:left;
}
.colorText{
    width: 90px;
    float:left;
}
.phoneContainer{
    width:250px;
}
.phone{
    position: relative;
    z-index: 1;
}
.phoneIcon{
    float: right;
    color: #fff;
    display: flex;
    align-items: center;
    width: 58px;
    justify-content: space-between;
    margin-top: 3px;
    font-size: 10px;
}
.phoneIcon i{
    font-size: 16px;
    color:#fff;
}
.phoneHeader{
    position: absolute;
    width: 100%;
    height: 100px;
    background-color: #fff;
    top: 0;
    left: 0;
}
.phoneLogo{
    max-width: 104px;
    max-height: 25px;
    height: auto;
    margin-right: 4px;
}
.phoneText{
    margin-top: 68px;
    margin-left: 22px;
    width: 205px;
    overflow: hidden;
    white-space: nowrap;
}
.replaceCheckbox{
    width: 20px;
    height: 20px;
    box-shadow: none;
    display: inline-block;
    margin: 0 1rem 0 0 !important;
}
.replaceCheckbox:focus{
    box-shadow: none;
    border:none;
}
.replaceContainer{
    display: flex;
    align-items: center;
    margin-top: 1rem;
}