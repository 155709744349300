.iconContainer{
    position: relative;
    width: 130px;
    margin: auto;
    height: auto;
}
.iconCircle{
    position: absolute;
    top: 0;
}
.icon{
    padding: 25px;
}
.icon img{
    width: 80px;
    height: 80px;
}