.container:hover{
    text-decoration: none;
}
.heading{
    color: #78B03D;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}
.info{
    position: relative;
    z-index: 1;
}
.instructor{
    padding-right: 25px;
}
.intensity{
    display: inline-block;
}
.intensity .dot{
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgba(110, 159, 57, .5);
    border-radius: 50%;
    margin-right: 5px;
}
.intensity .dot.active{
    background-color: rgba(110, 159, 57, 1);
}
.playBtn{
    width: 40px;
    height: 40px;
    background-color: #78B03D;
    position: absolute;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px -2px rgba(0,0,0,0.54);
    -webkit-box-shadow: 4px 4px 10px -2px rgba(0,0,0,0.54);
    -moz-box-shadow: 4px 4px 10px -2px rgba(0,0,0,0.54);
}
.playBtn svg{
    width: 20px;
    fill: #fff;
    position: relative;
    top: 1px;
}
.theme{
    padding-right: 25px;
}
.titleCore{
    color: #fff;
    font-size: 45px;
    font-weight: 400;
    font-family: "din-condensed", sans-serif;
    margin: 0;
    text-transform: uppercase;
}
.titleSub{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
}

.thumbnail{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}
.thumbnail .gradient{
    background: rgb(24,30,38);
    background: linear-gradient(90deg, rgba(24,30,38,1) 60%, rgba(35,41,51,0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 0;
}
.thumbnailImg{
    width: 40%;
    background-size: cover;
    background-position: top center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: -1;
}

/*Mobile styles*/
.mobileThumbnail{
    align-items: flex-end;
}
.mobileThumbnail .thumbnailImg{
    width: 100%;
}
.mobileThumbnail .gradient{
    background: linear-gradient(20deg, rgba(24,30,38,1) 25%, rgba(35,41,51,0) 100%);
}
@media screen and (max-width: 600px) {
    .titleCore{
        font-size: 34px;
    }
    .titleSub{
        font-size: 12px;
    }
}