.container1{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.container1 .data{
    flex: 1;
    padding: 0 15px;
    margin-bottom: 25px;
}
.container1 .victories{
    flex: 1;
    padding: 0 15px;
}
.filter .dateRange{
    display: flex;
    align-items: center;
    max-width: 350px;
}
.heading{
    margin: 0 0 30px 0;
}
.victoriesContainer{
    padding: 15px;
    border: 1px solid #ccc;
    background-color: transparent;
    box-shadow: unset;
    margin-bottom: 25px;
}
@media screen and (max-width: 1100px) {
    .container1{
        margin: 0;
    }
    .container1 .data,
    .container1 .victories{
        min-width: 100%;
        padding: 0;
    }
}