.save-profile-btn {
	float: right;
    margin-top: -120px;
}

.save-profile-alert {
	float: right;
    margin-top: -95px;
	z-index: 1;
	position: relative;
}

.method-label {
	display: block;
}

