.event{
    display: flex;
    padding: 15px 20px;
}
.event .button{
    text-decoration: underline;
    color: #4BBC4E;
    cursor: pointer;
}
.event .date{
    width: 70px;
}
.event .flag{
    width: 20px;
}
.event .member{
    flex: 1;
}
.event .time{
    width: 70px;
}
.eventsStats{
    display: flex;
    margin: 0 -15px;
}
.eventsStats .stat{
    padding: 1.5rem 2rem;
    box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
    margin: 0 15px;
    align-items: center;
    text-align: center;
}
.circle{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}
.circle.green{
    background-color: #4BBC4E;
}
.circle.red{
    background-color: #F47170;
}
.circle.yellow{
    background-color: #FDD86D;
}