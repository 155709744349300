.dates{
    margin: 0;
    font-size: 12px;
}
.dates .output{
    float: right;
}
.name{
    margin:0;
}
.label{
    text-align: center;
    color: #ccc;
    width:100%;
}
.logo{
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
}
.logoContainer{
    background: #3a3a3a;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 10px;
}
.lgStat{
     font-size: 40px;
 }
.smStat{
    font-size: 20px;
    color: #ccc;
}
.stat{
    font-family: "din-condensed";
    min-height:85px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
    .dates .output {
        float: none;
        margin-left: 1rem;
    }
    .name{
        margin-top: 1rem;
    }
}