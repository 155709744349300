.giphyLogo{
    width:60px;
    height: auto;
}

.giphyWrapper{
    border: 1px solid #ccc;
    display: inline-block;
    padding: 5px;
    margin-bottom: 1rem;
}

.giphyWrapper:hover{
    cursor: pointer;
    background: #ccc;
}