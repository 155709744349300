.mainNav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.moreNav {
  position: fixed;
  top: 0;
  right: 0;
  width: 450px;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  transition: transform 1s ease;
  transform: translate(100%, 0);
  overflow: auto;
}
.moreNav.open {
  transform: translate(0, 0);
}
.notification {
  width: 100%;
  height: 75px;
  z-index: 10001;
  position: fixed;
  max-width: 600px;
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);
  transition: all 1s ease;
  overflow: hidden;
}
.notification.show {
  overflow: visible;
  transform: translate(-50%, 0);
}

.subscription-container {
    overflow: hidden;
}

.subscription-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; /* full width coverage */
  bottom: 0; /* full height coverage */
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;

  display: flex;
  flex-direction: column;
  justify-content: center; /* center vertically */
  align-items: center; /* center horizontally */
  overflow-y: hidden;
  /* Hide scrollbar in IE/Edge: */
  -ms-overflow-style: none;

  padding: 5px ;
}

.subscriptions-inner {
  /* Give the modal a max height so it can scroll internally */
  max-height: 85vh;
  width: 95%;
  max-width: 1200px; 

  /* give it some internal padding */

  /* Enable scrolling but hide scrollbar */
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

/* Hide scrollbar in Chrome / Safari / Opera */
.subscriptions-inner::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

@media screen and (max-width: 767px) {
  .moreNav {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .moreNav {
    overflow: auto;
    margin-bottom: 80px;
  }
}
