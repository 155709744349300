.boxes{
    margin: 0 15px 15px 15px;
    padding: 15px;
}
.boxesOuter{
    min-width: 150px;
    min-height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 25px;
}
.conversionRow{
    display: flex;
}
.conversionRow .abandoned{
    padding: 15px;
    margin-bottom: 25px;
    flex: 2;
}
.conversionRow .conversion{
    padding: 15px;
    margin: 0 25px 25px 0;
    flex: 4;
}
.conversionRow .inner{
    position: relative;
    min-height: 191px;
}
.filter .dateRange{
    display: flex;
    align-items: center;
    max-width: 350px;
}
.demographics{
    padding-top: 15px;
    margin-bottom: 25px;
}
.engagementRow .engagement{
    padding: 15px;
}
.engagementRow .engagement .tab{
    margin-right: 15px;
    padding-bottom: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}
/*.engagementRow .engagement .tab.active{*/
/*    border-bottom: 2px solid #354052;*/
/*}*/
.heading{
    margin: 0 0 30px 0;
}
.heading2{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
}
.mainLabel{
    font-size: 36px;
    font-weight: bold;
}
.newMemberGraphRow{
    display: flex;
}
.newMemberGraphRow .newMemberGraph{
    padding: 15px;
    margin-right: 25px;
    flex: 4;
    margin-bottom: 25px;
}
.newMemberGraphRow .newMemberGraph .inner{
    height: 200px;
}
.newMemberGraphRow .topClients{
    flex: 2;
    padding: 15px;
    margin-bottom: 25px;
}
.newMembersRow{
    display: flex;
}
.newMembersRow .activeRow{
    flex: 4;
}
.newMembersRow .activeMembers{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.newMembersRow .activeMembers .stats{
    min-width: 150px;
    text-align: center;
}
.newMembersRow .newMembers{
    flex: 1;
    flex-direction: column;
    margin-right: 25px;
    height: 195px;
    position: relative;
}
.subLabel{
    font-size: 18px;
    font-weight: 400;
}
@media screen and (max-width: 800px) {
    .newMembersRow{
        display: block;
    }
    .newMembersRow .activeMembers .stats{
        min-width: 80px;
        text-align: center;
    }
    .newMembersRow .newMembers{
        margin-right: 0;
    }

}