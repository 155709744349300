.backgroundLeft{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: .36;
}
.backgroundRight{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    opacity: .22;
}
.backgroundRight2{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    opacity: .2;
}
.badgeContainer{
    position: absolute;
    top: 46px;
    left: 0;
    width: 100%;
}
.badgeRow{
    position: relative;
    text-align: center;
    height: 148px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.badgeRow._center .image{
    margin-right: -80px
}
.badgeRow._center2 .image{
    margin-right: -75px;
}
.badgeRow._left .image{
    margin-right: 200px;
}
.badgeRow._right .image{
    margin-right: -205px;
}
.badgeRow .image{
    width: 130px;
    height: 130px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.badgeRow .underlay{
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-10px, -50px) scale(1.2);
    z-index: 0;
}
.badgeRow._left .underlay{
    transform: translate(-150px, -50px) scale(1.2);
}
.badgeRow._right .underlay{
    transform: translate(52px, -50px) scale(1.2);
}
.badgeRow._center2 .underlay{
    transform: translate(-13px, -50px) scale(1.2);
}
.container{
    position: relative;
    background: rgb(66,205,234);
    background: linear-gradient(130deg, rgba(66,205,234,1) 0%, rgba(175,206,153,1) 40%, rgba(248,229,175,1) 100%);
    overflow-x: hidden;
}
.flag{
    position: absolute;
    width: 160px;
    top: 50%;
    left: 50%;
    transform: translate(-80%, -50%);
}
.flag._left{
    transform: translate(-20%, -50%);
}
.flag._center{
    transform: translate(-115%, -50%);
}
.flag._left .earned{
    align-items: flex-end;
}
.flag .earned{
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 55px;
    padding: 8px;
    align-items: flex-start;
}
.flag .triangle{
    fill: rgba(255, 255, 255, .6);
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
}
.modal{
    height: 500px;
    z-index: 10000;
    padding: 15px;
    transition: all .7s ease;
    transform: scale(0);
    left: 0;
    opacity: 0;
    position: fixed;
    width: 100%;
}
.exit{
    font-size: 30px;
    color: #fff;
    background-color: #ccc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    padding-top: 2px;
}
.modal.show{
    opacity: 1;
    transform: scale(1);
}
.modal .window{
    position: relative;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    overflow: auto;
}
.path{
    width: 250px;
    margin: 0 auto;
    display: block;
    padding-top: 80px;
    padding-bottom: 80px;
}
.path.offsetPath{
    display: none;
}
.path path{
    stroke: #fff;
    stroke-width: 15px;
}
.rankContainer{
    inset: 0;
    top: 275px;
    position: absolute;
    background: #fff;
    text-align: center;
    padding-top: 30px;
}
.rankLocked{
    width: 150px;
    animation: rankAnimation 3s ease;
}
.rankText{
    max-width: 300px;
    margin: 0 auto;
    font-size: 16px;
}
@keyframes rankAnimation{
    0%{
        transform: translate3d(-1px, 0, 0) scale(0)
    }
    10% {
        transform: translate3d(-1px, 0, 0);
    }
    20% {
        transform: translate3d(2px, 0, 0);
    }
    25%{
        transform: translate3d(-4px, 0, 0);
    }
    30% {
        transform: translate3d(4px, 0, 0);
    }
    35%{
        transform: translate3d(-4px, 0, 0);
    }
    40% {
        transform: translate3d(4px, 0, 0) scale(1);
    }
    45%{
        transform: translate3d(2px, 0, 0);
    }
    50% {
        transform: translate3d(-4px, 0, 0);
    }
    55%{
        transform: translate3d(4px, 0, 0);
    }
    60% {
        transform: translate3d(-4px, 0, 0);
    }
    65%{
        transform: translate3d(4px, 0, 0);
    }
    70%{
        transform: translate3d(-4px, 0, 0);
    }
    75%{
        transform: translate3d(2px, 0, 0);
    }
    80% {
        transform: translate3d(-2px, 0, 0);
    }
    85%{
        transform: translate3d(1px, 0, 0);
    }
    90%{
        transform: translate3d(-1px, 0, 0);
    }
}


@media screen and (max-width: 374px) {
    .badgeRow .underlay{
        transform: translate(32px, -50px) scale(1.2);
    }
    .badgeRow._center .image{
        margin-right: -165px
    }
    .badgeRow._center2 .image{
        margin-right: -168px;
    }
    .badgeRow._center2 .underlay{
        transform: translate(34px, -50px) scale(1.2);
    }
    .flag._center{
        transform: translate(-90%, -50%);
    }
    .path{
        display: none;
    }
    .path.offsetPath{
        display: block;
    }
}