.barContainer{
    display: flex;
    align-items: center;
    width: 100%;
}
.bar{
    width: 100%;
    height: 25px;
    margin-right: 15px;
    flex: 1;
}
.bar.abandoned{
    background-color: #d50e0e;
}
.bar.total{
    background-color: #6697bd;
}
.barLabel{
    margin-right: 15px;
    width: 115px;
}
.row{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.totalAbandoned{
    font-size: 18px;
    font-weight: bold;
    text-align: right;
    margin: 0;
}