.barcodeExit{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    line-height: 0.5;
    padding: 15px 15px 25px 25px;
    cursor: pointer;
}
.barcodeIcon{
    width: 70px !important;
    fill: #fff;
    padding-top: 10px;
    filter: drop-shadow(1px 2px 2px rgb(0 0 0 / 0.3));
}
.barcodeModal{
    padding: 150px 15px;
    text-align: center;
    position: relative;
}
.barcodeModal svg{
    max-width: 315px;
}
.barcodeTile{
    background-color: #4bbc4e;
    color: #fff;
    cursor: pointer;
}
.connectHeading {
    font-size: 21px;
    font-weight: bold;
}
.featuredHeading{
    display: flex;
    font-size: 16px;
    align-items: center;
    margin-bottom: 10px;
}
.featuredTrack{
    display: flex;
    overflow-x: auto;
    align-items: flex-start;
    max-width: 100%;
    margin-bottom: 25px;
}
/* Scroll Track */
.featuredTrack::-webkit-scrollbar {
    height: 10px;
}
.featuredTrack::-webkit-scrollbar-track {
    background: transparent;
}
.featuredTrack::-webkit-scrollbar-thumb {
    background: rgb(204, 204, 204);
    border-radius: 10px;
    height: 10px;
}
/* Handle on hover */
.featuredTrack::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,.6);
}
.featuredTrack .featuredItem{
    width: 130px;
    height: 130px;
    margin-right: 5px;
    min-width: 130px;
    background-size: cover;
    background-position: center;
    margin-bottom: 0;
}
.featuredTrack.overflow .featuredItem{
    margin-bottom: 16px;
}
.fullWidth{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
}
.icon{
    border-radius: 50%;
    width: 28px;
    height: 28px;
    margin-right: 10px;
}
.links{
    height: 150px;
    padding: 15px;
    position: relative;
    background: #333;
    cursor: pointer;
}
.links:hover{
    text-decoration: none;
    color: #000;
}
.primaryLink{
    display: inline-block;
    padding: 1rem 3rem;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.42857143;
    border: none;
    color: #fff;
    background-image: none;
    outline: none !important;
    text-shadow: 2px 2px 5px rgba(0,0,0,.3);
    background-color: #4bbc4e;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: none;
}
.primaryLink:hover{
    color: #fff;
}
.new{
    position: absolute;
    top: 5px;
    left: 5px;
    color: #fff;
    background: #d02a2a;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 12px;
    z-index: 1;
}
.newDot{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: #fff;
    display: inline-block;
    position: relative;
    top: -2px;
}
.overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.5);
    left: 0;
    top: 0;
    border-radius: 10px;
    z-index: 0;
}
.title{
    color: #fff;
    position: relative;
    z-index: 1;
    font-weight: 400;
    text-transform: capitalize;
}
.titleCenter{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: #fff;
    z-index: 1;
    flex-wrap: wrap;
}
.titleCenter .external{
    width: 100%;
    text-align: center;
    margin: 10px 0 0 0;
    font-weight: 400;
    font-size: 12px;
    white-space: nowrap;
}
.titleCenter .external img{
    position: relative;
    bottom: 1px;
}
.titleCenter .text{
    display: inline-block;
    text-align: center;
    margin: 0;
    font-weight: 600;
}
.titleCenter svg{
    width: 15px;
    height: auto;
    fill: #fff;
}
.trademark{
    font-size: 30px;
    color: #fff;
    margin-top: -5px;
    display: inline-block;
    position: absolute;
    top: -5px;
    right: -10px;
}
.warning :global(.modal-content) {
    width: 300px;
    margin: 100px auto 0 auto;
    padding: 15px;
}