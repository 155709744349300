/*All classes are in order of screen size followed by alphabetical order*/
/* screen sizes go from desktop -> mobile */
.categoryContainer .accordion{
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 0 1rem;
    overflow: hidden;
}
.categoryContainer .accordion__heading{
    margin: -.5rem -2rem 0;
    background: rgba(0,0,0,.065);
    padding: 1rem 2rem;
}
.pathwaysBackground{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    position: relative;
}
.pathwaysOverlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    background: -moz-linear-gradient(90deg, rgba(0,0,0,0.7343312324929971) 0%, rgba(0,0,0,0.5662640056022409) 29%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.7343312324929971) 0%, rgba(0,0,0,0.5662640056022409) 29%, rgba(0,0,0,0) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,0.7343312324929971) 0%, rgba(0,0,0,0.5662640056022409) 29%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);}
/* color picker in orgs */
.sketch-picker{
    bottom: 25px;
    left: 30px;
    position: absolute;
    z-index: 100;
}

.square-tile{
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0,0,0,.15);
    border-radius:10px;
    padding: 16px;
    color: #000;
    font-weight: 700;
    display: block;
    margin-bottom: 16px;
	font-size: 16px;
}
.videoHighlight{
    position: relative;
    background: #55A859;
    border-radius: 5px;
    margin: 0 auto 0px auto;
    top: -15px;
    color: #fff;    
    display: flex;
    padding: .5rem 2rem;
    align-items: center;
}
.videoHighlight:hover{
    color: #fff;
}
.videoHighlight.slim{
    height: 40px;
    margin: 0 auto -10px auto;
    bottom: 20px;
}
.videoHighlight.slim .img{
    font-size: 35px;
    height: auto;
}
.videoHighlight .img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.videoHighlight .popout{
    position: absolute;
    padding: 2px 10px;
    background-color: #D65040;
    border-radius: 5px;
    top: -12px;
}
.videoHighlight .col-1{
    width: 85%;
    float: left;
}
.videoHighlight .col-2{
    width: 15%;
    float: left;
    text-align: right;
}