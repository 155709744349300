.btns{
    display: flex;
    justify-content: center;
}
.btnReset{
    border-radius: 20px;
    border: 2px solid #2A3342;
    background-color: transparent;
    color: #2A3342;
    margin-right: 25px;
    width: 85px;
    height: 28px;
    font-weight: bold;
}
.btnSubmit{
    border-radius: 20px;
    border: 2px solid #78B03D;
    background-color: #78B03D;
    color: #fff;
    width: 85px;
    height: 28px;
}
.close{
    color: #a5a5a5;
    font-size: 36px;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 15px;
    top: 0;
}
.container{
    background-color: #eaecf1;
    padding: 15px;
    border-radius: 5px;
}
.dropdown{
    margin-bottom: 15px;
}
.dropdown :global(.standard-dropdown){
    font-size: 18px;
    text-transform: uppercase;
}
.heading{
    color: #2A3342;
    font-size: 20px;
    font-family: "din-condensed", sans-serif;
}
.myFavorites{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    background: #fff;
    border-radius: 30px;
    height: 50px;
    line-height: 38px;
    padding: 0 22px;
    font-size: 18px;
    font-family: "din-condensed", sans-serif;
}
.myFavorites:last-of-type{
    margin-bottom: 25px;
}