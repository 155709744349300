.calendlyContainer{
    height: 1250px;
}
.confirmFormContainer{
    margin: 30px;
}
.confirmForm{
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 3px 40px 0 rgba(0,0,0,.15);
    margin: 0 auto;
    z-index: 10;
    opacity: 1;
    color: #333;
    padding: 2rem;
    max-width: 450px;
    font-size: 13px;
}

@media screen and (max-width: 600px) {
    .calendlyContainer{
        margin-top: 30px;
    }
}