.btn{
    background: #ccc;
    color: #333;
    border: none;
    border-radius: 9px;
    padding: 3px 9px;
    min-width: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.btn.selected{
    background: #7b7b7b;
    color: #fff;
}
.btn.selected svg{
    fill: #fff;
}
.btn .arrowDown{
    transform: rotate(-90deg);
}
.btn .arrowUp{
    transform: rotate(90deg);
}
.btn svg{
    width: 15px;
}
.container{
    min-width: 300px;
    padding: 15px;
}
.container :global(.select-search__input){
    border: 1px solid #ccc;
}
.times{
    cursor: pointer;
    width: 20px;
    fill: #333;
}