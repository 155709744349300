.cardContainer{
    width: 100%;
   min-width: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.mainLabel{
    font-size: 36px;
    font-weight: bold;
}

.subLabel{
    font-size: 18px;
    font-weight: 400;
}

