.details-save-profile {
    margin-top: -210px;
}

.user-activity-tiles {
    margin:0;
    padding:.5rem;
    text-align: center;
    border:2px solid grey;
    cursor: pointer;
    opacity: .2;
    border-radius: 6px;
    font-weight: normal;
}

.user-activity-tiles:focus { 
	outline: none;
    opacity: 1;
}

.user-activity-tiles-active1, .user-activity-tiles-active2, .user-activity-tiles-active3, .user-activity-tiles-active4 { 
    margin:0;
    padding:.5rem;
    text-align: center;
    border:2px solid grey;
    cursor: pointer;
    opacity: 1;
    outline: none;
    border-radius: 6px;
    font-weight: bold;
}