.episode{
    margin: 0 0 10px 0;
    font-size: 16px;
}
.gradient{
    background: rgb(53,64,82);
    background: linear-gradient(0deg, rgba(53,64,82,1) 0%, rgba(13,16,21,1) 100%);
    color: #fff;
    padding: 15px 0;
    box-shadow: 0 4px 20px rgba(0,0,0,.3);
    margin-bottom: 25px;
}
.icon{
    width: 20px;
}
.intensity{
    display: inline-block;
}
.intensity .dot{
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: rgba(120, 176, 6, .5);
    border-radius: 50%;
    margin-right: 5px;
}
.intensity .dot.active{
    background-color: rgba(120, 176, 61, 1);
}
.meta {
    display: flex;
}
.meta .favorite, .meta .instructor, .meta .level{
    margin-bottom: 10px;
    margin-right: 25px;
}
.meta .favorite{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.meta .favorite .icon{
    margin-right: 5px;
}
.meta .instructor{
    display: flex;
    align-items: center;

}
.meta .instructor svg{
    width: 20px;
    stroke: #78B03D;
    margin-right: 5px;
}
.meta .level{
    display: flex;
    align-items: center;
}
.title{
    font-size: 20px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .meta{
        font-size: 12px;
    }
}