.placeholder{
    position: absolute;
    top: 0;
    left: 0;
    border: 5px dashed #1a1a1a;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    z-index: -1;
    text-align: center;
}

.thumbnail{
    padding-top:56.25%;
    width:100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position:relative;
}

.text{
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-size: 21px;
    position: absolute;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.6);
    cursor: pointer;
}