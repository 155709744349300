.placeholderContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.placeholderContainer svg{
    width: 100%;
    max-width: 130px;
    max-height: 100%;
    animation: placeholder-glow 4s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes placeholder-glow {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}