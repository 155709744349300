.addButton{
    text-align: right;
}
.infoContainer{
    display: flex;
    align-items: center;
}
.infoContainer .info{
    flex: 1 1;
    color: #000;
    text-decoration: none;
}
.name{
    margin:0;
}
.profileImage{
    border-radius: 50%;
    width: 40px;
    margin-right: 1rem;
}
.remove{
    color: #a94442;
    margin-right: .5rem;
}
.title{
    margin: 0;
}
@media screen and (max-width: 992px) {
    .name{
        margin-top: 1rem;
    }
}
@media screen and (max-width: 767px) {
    .addButton{
        text-align: left;
    }
}