.switch{
    width: 150px;
    margin: 0 0 50px 0;
}
.header{
    display: flex;
    justify-content: space-between;
}
.heading{
    margin: 0 0 15px 0;
    font-weight: bold;
    font-size: 24px;
}
.stats{
    padding: 1.5rem 2rem;
    box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin: 0 15px;
    align-items: center;
    text-align: center;
}
.statsContainer{
    display: flex;
    margin: 0 -15px;
}
.tooltip{
    position: absolute;
    top: -20px;
    right: -20px;
    width: 20px;
}

@media screen and (max-width: 767px) {
    .stats{
        min-width: 100%;
        margin: 0 0 15px 0;
    }
    .statsContainer{
        margin: 0;
        flex-wrap: wrap;
    }
}