/* Button List*/
.buttonList{
    list-style: none;
    margin: 0;
	padding: 0;
}
.buttonList li{
    width:100%;
    padding: 15px 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    text-align: center;
	margin-bottom: 1rem;
    font-size: 16px;
    
}
.buttonList li:hover{
    background: #ccc;
	border-color: #ccc;
    color:#333;
    cursor: pointer;
}
.buttonList li.active {
    background: #354052;
    border-color: #354052;
    color: #fff;
}
.buttonList li:last-of-type{
    margin:0;
}
.buttonList li.disabled{
    pointer-events:none;
    opacity:0.7;	
}
.buttonList li.disabled:hover {
	cursor: no-drop;
}
.buttonList li.save {
	background: #4bbc4e !important;
    border-color: #4bbc4e !important;
    color: #fff !important;
}