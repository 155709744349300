
/* PortalHeader.module.css */
.headerWrapper {
  width: 100%;        
  box-sizing: border-box;   

}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  margin-bottom: 20px;
}
.leftSection {
  display: flex;
  flex-direction: column;
}

.companyName {
  font-size: 18px;
  color: #202426;
  margin: 0;
  font-weight: 400;
}

.pageTitle {
  color: #202426;
  font-size: 28px;
  font-weight: bold;
  margin: 1px 0 0;
  text-transform: none;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 20px;
}

.notification {
  position: relative;
  font-size: 30px;
  color: #555;
  margin-right: 20px;
}

.notificationDot {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.userProfile {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #393C52;
  padding: 10px 15px;
  border-radius: 15px;
  color: white;
  width: 495px;             /* Fixed width remains unchanged */
  height: 85px;             /* Fixed height remains unchanged */
  justify-content: space-between;
  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.128));
  box-shadow: inset 0 4px 10px rgba(208, 206, 206, 0.22);
  z-index: 10;
  position: relative;
}

.userImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 20px;
  border: 4px solid white;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-left: 5px;

}

.userName {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.userRole {
  font-size: 16px;
  margin: 0;
  color: #d0d0d0;
  z-index: 99999999;
  position: relative;
}

.dropdownArrowContainer {
  width: 29px;
  height: 29px;
  background-color: #76C140;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;


}

.dropdownArrow {
  width: 21px;
  position: relative;
  left: 1.5px;
  top: 2px;
  display: block;
}



@media (max-width: 1000px) {
  .headerContainer { 
    flex-direction: column;
    align-items: normal;

  }
  .userProfile{
    width: 100%;
    margin-top: 20px;
  }
  
}

@media (max-width: 600px) {
  .headerContainer { 
    flex-direction: column;
    align-items: normal;

  }
  .userProfile{
    width: 95vw;
    margin-top: 20px;
  }
  
}


.dropdownArrowContainer {
  cursor: pointer;
  margin-left: 0.5rem;
  z-index: 999999;
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 4px); /* Slight gap below the parent */
  right: 0;
  background-color: #393C52;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 4px; /* Reduced padding for a smaller height */
  animation: fadeIn 0.3s ease-out;
  z-index: 9999;
  width: 60%;
  display: flex;

  height: 60px;

}

/* Fade-in animation for a smooth appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sidebarLogout {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 6px;
  gap: 10px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s ease;
}

.sidebarLogout:hover {
  background-color: #f1f1f10f;
  width: 100%;
}

.sidebarLinkText {
  margin-left: 10px;
  font-size: 20px;
  color: white;
  font-weight: 500;
  position: relative;
  top: 3px;
}
