.archiveContainer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}
.archiveContainer button{
    margin-left: 15px;
}
.threadContainer{
    position: fixed;
    right: 0;
    bottom: 0;
    height: 550px;
    overflow: auto;
    width: 700px;
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%)

}
.threadContainer.hide{
    display: none;
}
@media screen and (min-width: 1400px){
    .threadContainer{
        height: 700px;
        width: 900px;
    }
}