.progressBar{
    width: 100%;
    height: 15px;
    background-color: #fff;
    background-image: -webkit-linear-gradient(top,#ebebeb 0,#f5f5f5 100%);
    background-image: -o-linear-gradient(top,#ebebeb 0,#f5f5f5 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#ebebeb),to(#f5f5f5));
    background-image: linear-gradient(to bottom,#ebebeb 0,#f5f5f5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
    background-repeat: repeat-x;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}
.progressBar .fill{
    height: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}