/* Base style*/
.button{
    display: inline-block;
    padding: 1rem 2rem;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    border: none;
    border-radius: 10px;
    background-color: #354052;
    color: #fff;
    background-image: none;
    outline: none !important;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out;
}


.button.primary:disabled {
    background-color: #ccc;  /* gray background */
    color: #999;             /* gray text */
    border-color: #ccc;      /* gray border if needed */
    cursor: not-allowed;     /* show a "no" cursor */
    outline: none;           /* remove outline if you see a green outline */
    box-shadow: none;        /* remove any potential box shadow */
  }

/* Primary Style*/
.button.primary{
    background-color: #6BA543;
    color: #fff;
    font-weight: 400;
    border-radius: 10px;
    box-shadow: none;
}
/* Primary Style*/
.button.primary_outline{
    background-color: transparent;
    color: #6BA543;
    border: #6BA543 2px solid;
    font-weight: 400;
    border-radius: 10px;
    box-shadow: none;
}
.button.primarySm{
    background-color: #6BA543;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid #6BA543;
    text-transform: unset;
    padding: 5px 15px;
}

/* Secondary Style */
.button.secondary{
    color: #6BA543;
    background-color: #fff;
    font-size: 18px;
    font-weight: 400;
    border: 2px solid #6BA543;
    text-shadow: none;
    border-radius: 10px;
    box-shadow: none;
}

/* Danger Style */
.button.danger {
    background: transparent;
    border: 2px solid #a94442;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #a94442;
    font-size: 18px;
    padding: .8rem 3rem;
    text-shadow: none;
    text-transform: unset;
}
/* Inline Danger Style */
.button.danger {
    background: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #a94442;
    font-size: 14px;
    padding: 0;
    text-shadow: none;
}

/* toggle styles */
.toggle{
    display: block;
    width:100%;
}
.toggle .background{
    position: absolute;
    background-color: #354052;
    height: 27px;
    width: 50px;
    border-radius: 55px;
    margin: .5rem 0;
    transition: all .5s ease;
}
.toggle .buttons{
    padding: 0.5rem 25px;
    position: relative;
    z-index: 1;
    flex: 1;
    text-align: center;
 }
.toggle .buttons:hover,
.toggle .buttons label:hover{
    cursor: pointer;
}

.toggle .buttons input{
    display: none;
}
.toggle .container{
    display: flex;
    border: 1px solid #ccc;
    border-radius: 55px;
    position: relative;
    padding: 0 5px;
}
.toggle .toggleText{
    margin-top: 3px;
    white-space: nowrap;
    font-weight: 400;
}