.arrow{
    width: 10px;
    fill: #354052;
    margin-bottom: -4px;
    margin-left: 5px;;
}
.arrow.up{
    transform: rotate(180deg);
}
.boxes{
    margin: 0 15px 15px 15px;
    padding: 15px;
}
.boxesOuter{
    min-width: 150px;
    min-height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 25px;
}
.contentViewsRow .views{
    padding: 15px;
    margin-bottom: 25px;
}
.contentViewsRow .views .tab{
    margin-right: 15px;
    padding-bottom: 5px;
    cursor: pointer;
    font-size: 16px;
}
.contentViewsRow .views .tab.active{
    border-bottom: 2px solid #354052;
}
.filter .dateRange{
    display: flex;
    align-items: center;
    max-width: 350px;
}
.heading{
    margin: 0 0 30px 0;
}
.heading2{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
}
.mainLabel{
    font-size: 36px;
    font-weight: bold;
}
.pathwayRow{
    display: flex;
    justify-content: space-around;
    padding: 15px;
    margin-bottom: 25px;
}
.pathwayRow .inner{
    text-align: center;
}
.secondaryLabel{
    text-transform: capitalize;
    font-size: 18px;
    font-weight: bold;
}
.subLabel{
    font-size: 18px;
    font-weight: 400;
}
.tertiaryLabel{
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
}
.topContentRow{
    position: relative;
    margin-top: 30px;
    padding: 15px;
}
.topContentRow .categoryDD{
    width: 270px;
}
.topContentRow .row{
    display: flex;
    padding: 15px 10px;
}
.topContentRow .row.listItem{
    overflow: hidden;
    background-color: #ecf2f6;
    margin-bottom: 15px;
}
.topContentRow .row .content{
    flex: 1;
}
.topContentRow .row .duration,
.topContentRow .row .access,
.topContentRow .row .views{
    width: 100px;
    text-align: center;
}

.topContentRow .row .headers{
    font-size: 12px;
    text-decoration: underline;
}
.topContentRow .row .headers.active{
    color: #4BBC4E;
}
.topContentRow .search{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.topContentRow .search > *{
    margin-right: 25px;
}
.topContentRow .search .searchText{
    display: flex;
}
.topContentRow .search .searchText input[type='checkbox']{
    margin-right: 5px;
}
.searchText {
    width: 250px;
}


.topContentRow .search :global(.select-search__input){
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 34px;
}
.topContentRow .search .times{
    cursor: pointer;
    width: 20px;
    fill: #333;
   
}
.topContentRow .submit{
    padding: 0.4rem 3rem;
    text-transform: unset;
    margin-bottom: 25px;
}
.viewsRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.viewsRow .inner{
    flex: 1;
    flex-direction: column;
    margin: 0 25px 25px 0;
}
.viewsRow .inner:last-of-type{
    margin-right: 0;
}