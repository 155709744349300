.statusContainer {
  width: 30rem;
  display: flex;
  justify-content: space-around;
  position: relative;
  top: -10rem;
  float: right;
}

.statusLabelAlignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.circleIcon{
  cursor: pointer;
  transition-duration: 0.3s;
  width: 40px;
  height: 40px;
}

.circleIcon:hover {
	transform: scale(1.1);
}


@media only screen and (max-width: 1400px) {
  .statusContainer {
    width: 15vw;
    top: -12rem;
  }
  .circleIcon {
    width: 35px;
  }

}
@media only screen and (max-width: 990px) {
  .statusContainer {
    width: 35vw;
    top: -15rem;
    gap: 10px;
  }

}

@media only screen and (max-width: 600px) {
  .statusContainer {
    width: 80vw;
    margin: auto 0 auto 0;

  }
}
