.birthday{
    display: flex;
}
.birthday select{
    border-radius: 4px;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    margin-right: 15px;
}
.container{
    max-width: 500px;
    margin: 0 auto;
}
.heading{
    font-size: 24px;
    text-transform: unset;
    font-weight: 400;
    margin: 0 0 5px 0;
}