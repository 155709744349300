.container{
    height: 200px;
    position: relative;
}
.more{
    background-color: #fff;
    border: none;
    color: #585858;
    padding: 2px 10px;
    margin: 5px 0 15px 0;
}
.victories{
    height: 730px;
    overflow: auto;
    padding: 0 10px 0 0;
}