.explainer{
    margin: 15px 0 40px 0;
}
.goalContainer{
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}
.goal{
    font-size: 24px;
    color: #4bbc4e;
    border: 3px solid #4bbc4e;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 20px auto;
}
.heading{
    font-size: 18px;
    margin: 0 0 15px 0
}
.pendingFlag{
    display: inline-block;
    color: #b17200;
    margin-left: 5px;
}
.progressBar{
    --bar-bg: #bfbfbf;
    --seek-before-width: 0px;
    --seek-before-color: #354052;
    --handle: #354052;
    --selected-handel: #354052;
    --border-radius: 10px;
    --bar-height: 8px;

    appearance: none;
    background: var(--bar-bg);
    border-radius: var(--border-radius);
    position: relative;
    width: 100%;
    height: var(--bar-height);
    outline: none;
    padding: 0;
    border: 1px solid #bfbfbf;
}
/* progress bar - safari */
.progressBar::-webkit-slider-runnable-track{
    background: var(--bar-bg);
    border-radius: var(--border-radius);
    position: relative;
    width: 100%;
    height: 8px;
    outline: none;
}
/* progress bar - firefox */
.progressBar::-moz-range-track{
    background: var(--bar-bg);
    border-radius: var(--border-radius);
    position: relative;
    width: 100%;
    height: var(--bar-height);
    outline: none;
}
.progressBar::-moz-focus-outer{
    border: 0;
}
/* progress bar - chrome and safari */
.progressBar::before{
    content: '';
    height: var(--bar-height);
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    position:absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
}
/* progress bar - firefox */
.progressBar::-moz-range-progress{
    background-color: var(--seek-before-color);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    height: var(--bar-height);
}
/* knobby - chrome and safari */
.progressBar::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
    background-color: var(--handle);
    cursor: pointer;
    position: relative;
    margin-top: -3px;
    z-index: 3;
    box-sizing: border-box;
}
/* knobby while dragging - chrome and safari */
.progressBar:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selected-handel);
}
/* knobby - firefox */
.progressBar::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: transparent;
    background-color: var(--handle);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
}
/* knobby while dragging - firefox */
.progressBar:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selected-handel);
}
.rejectionFlag{
    margin: 5px 0 0 0;
    color: #a10000;
    font-weight: 400;
}
.row{
    display: flex;
    margin-bottom: 15px;
}
.row .text{
    width: 270px;
    margin-right: 15px;
}
.row .toggle{
    width: 75px;
}