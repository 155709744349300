.bottom{
    background-color: #fff;
}
.coach{
    text-align: center;
    margin: 0 15px 15px 0;
    color: #333;
    text-decoration: none;
}
.coach:hover{
    color: #333;
    text-decoration: none;
}
.coach .profile{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 8px solid #FFF;
    background-position: center;
    background-size: contain;
    margin: 0 0 5px 0;
}
.coachContainer{
    display: flex;
    flex-wrap: wrap;
}
.messageContainer .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.messageContainer .header .title{
    font-size: 24px;
}
.messageContainer .header a{
    font-size: 14px;
}
.top{
    background-color: #ecf2f6;
}