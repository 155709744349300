.activitySlider{
    background: -moz-linear-gradient(left, #ff3232 0%, #fff130 51%, #27dd1a 100%);
    background: -webkit-linear-gradient(left, #ff3232 0%,#fff130 51%,#27dd1a 100%);
    background: linear-gradient(to right, #ff3232 0%,#fff130 51%,#27dd1a 100%);
    height:2rem;
}

.rc-slider-track{
    background:transparent;
}

.rc-slider-rail{
    background:transparent;
}

.rc-slider-dot{
    border:none;
    background:none;
}

.rc-slider-handle{
    border:none;
    background:grey;
    width:30px;
    height:30px;
    margin-top: -10px;
}

.sliderButton{
    margin-top: 3rem;
}