.focus{
    margin: 0;
    font-size: 16px;
}
.heading{
    color: #888888;
    display: flex;
    justify-content: space-between;
    margin-top:16px;
    margin-bottom: 15px;
    font-size: 16px;
    position: relative;
}
.healthGoal{
    color: #212121;
    font-size: 12px;
    font-weight: 400;
}
.healthGoal:hover,
.healthGoal:visited,
.healthGoal:focus{
    text-decoration: none;
    color: #212121;
}
.tileContainer{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.tile{
    width: 50%;
}

@media screen and (max-width: 776px) {
    .tile{
        width: 100%;
    }
}