.blurb{
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}
.container{
    padding: 15px;
    position: relative;
}
.exit{
    font-size: 30px;
    color: #fff;
    background-color: #ccc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding-top: 2px;
}
.heading{
    text-align: center;
    font-size: 22px;
    margin: 20px 0 15px 0;
    font-weight: bold;
}
.logging svg{
    fill: #5ea3c1;
}
.learning svg{
    fill: #56a93b;
    stroke: #56a93b;
}
.living svg{
}
.row{
    display: flex;
    margin-bottom: 1px;
}
.row .points{
    width: 50px;
    margin: 0 15px 0 0;
    font-weight: bold;
}
.scoring svg{
    fill: #ffaa16;
}
.separator{
    width: 100%;
    height: 1px;
    background-color: #4f88a1;
    margin: 20px 0;
}
.title{
    font-weight: bold;
    color: #4f88a1;
    display: flex;
    align-items: center;
}
.title svg{
    width: 15px;
    margin-left: 5px;
}