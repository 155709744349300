.resources{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.resources input[type="checkbox"]{
    width: 50px;
    margin: 0 10px 0 0;
}
.resources label{
    margin: 0;
    font-weight: 400;
}