.videoContainer {
    margin-top: -5rem;
}
.videoContainer.customVideoContainer{
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 0;
    border-radius: 8px;
    max-width: 66%;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width:767px) {
    .videoContainer{
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        border-radius: 8px;
    }
    .videoContainer.customVideoContainer{
        max-width: 100%;
    }
    .videoIframe{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}