.container{
    height: 100%;
    padding-bottom: 75px;
}
.placeholder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    width: 280px;
    text-align: center;
}
