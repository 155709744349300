.badge{
    width: 70px;
    margin-left: -5px;
    margin-right: 15px;
}
.infographic{
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.infographic img{
    width: 35px;
    margin-right: 15px;
    margin-left: -3px;
}
.infographic svg{
    width: 30px;
    margin-right: 15px;
    fill: #c4c4c4
}
.infographicLabel{
    font-weight: bold;
}
.label{
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
}
.myVictories{
    flex: 1;
    overflow: auto;
    min-height: 300px;
}
.reactions{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 0 0;
}
.reactions>div{
    display: flex;
    align-items: center;
    margin: 0 5px;
}
.reactions .icon{
    width: 20px;
    margin-left: 5px;
}
.reactions svg{
    margin-top: -2px;
}
.reactions .like svg{
    margin-top: -3px;
}
.streak{
    width: 55px;
}
.victory{
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    align-items: center;
    display: flex;
}
