.confirm{
    color: #354052;
    border: none;
    padding: 0;
    margin-left: 10px;
}
.form{
    display: inline-block;
    width: auto;
}
.icons{
    border: none;
    background-color: transparent;
    padding: 0;
    margin-left: 15px;
    display: flex;
}
.icons svg{
    width: 25px;
    height: auto;
    fill: #4BBC4E;
    stroke: #4BBC4E;
}
.icons.cancel svg{
    fill: #a94442;
    stroke: #a94442;
}
.row{
    display: block;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
    margin-bottom: 15px;
    padding: 10px 25px;
    text-transform: capitalize;
    color: #333;
    text-decoration: none;
}
.row:hover{
    text-decoration: none;
    color: #333;
}
.tagEdit{
    color: #354052;
    border: none;
    padding: 0;
    margin-right: 10px;
}
.tagTitle{
    font-weight: 600;
    font-size: 22px;
    text-transform: capitalize;
    margin: 0 10px 0 0;
}