.buttonContainer{
    flex: 1;
    display: flex;
    justify-content: center;
}
.buttonContainer:first-of-type{
    margin-bottom: 15px;
}
.container{
    margin-top: 25px;
}
.container canvas{
    height: auto !important;
    margin: 0 auto;
}
.pageContainer{
    text-align: center;
}
.pages{
    display: flex;
    flex-wrap: wrap;
    padding: 5px 15px;
    width: 430px;
    margin: 0 auto;
}
.pages p{
    width: 100%;
}
.pages button,
.pages a{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    background: #fff;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border: none;
    margin: 0 15px;
    width: 50px;
    height: 50px;
    font-size: 21px;
    color: #3a3a3a;
    border-radius: 50px;
}
.pages button svg{
    width: 21px;
    height: auto;
}
.window{
    overflow: auto;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .pages{
        width: 340px;
        justify-content: center;
    }
    .pages a,
    .pages button{
        margin: 0 10px;
        width: 40px;
        height: 40px;
    }
}