.container{
    min-height: 200px;
    position: relative;
}
.row{
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
}
.row:first-of-type,
.row:last-of-type{
    border-bottom: none;
    padding-bottom: 0;
}
.row .header{
    font-size: 12px;
    text-decoration: underline;
}
.row .calendar{
    flex: 1;
}
.row .meta{
    text-align: center;
    padding: 0 20px;
    min-width: 110px;
}