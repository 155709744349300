.dashBox-container {
	max-width: 1000px;
	margin: auto;
}

.dashBox-ul {
	list-style: none;
    padding: 0;
    margin: 0;
}

.dashBox-li {
	margin: 1rem;
	width: 250px;
	height: 150px;
	border: none;
	display: inline-block;
    overflow: visible;     
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 5px rgba(0,0,0,.2);
}

.dashBox-text {
	margin-top: 5px;
	margin-bottom: 5px;
}