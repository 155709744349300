.client-subheader-container {
	background-color: #DCDCDC;
	height: 60px;
}

.client-subheader-content {
	max-width: 800px;
	margin: auto;
}

.client-back-btn:hover {
	cursor: pointer;
}