.title {
  margin-bottom: 20px;
}


.displayFlexContainers {
  display: flex;
}

.selectBillingTermContainer {
  border: 0.2px solid rgba(211, 211, 211, 0.344);
  margin-left: 20px;
  padding: 10px;
  max-width: 300px;
  height: fit-content;
}

.subscriptionsTermContainer {
  border: 0.2px solid rgba(211, 211, 211, 0.344);
  margin-left: 20px;
  padding: 10px;
  max-width: 500px;
}

.formGroup label {
  margin-top: 10px;
}

.submitButton {
  margin-top: 20px;
}

.formGroup {
  width: 100%;
}
.innerFormLabelContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}



.tabBackground {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #354052;
  color: white;
  padding: 5px;
  margin-bottom: 20px;
  border-radius: 25px;
}


/* Active tab */
.activeTab {
  color: #354052;
  background-color: #ffffff;
  padding: 10px 50px;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Inactive tab */
.inactiveTab {
  padding: 10px 50px;
  cursor: pointer;
  color: #ffffff; 
}

/* Hamburger Menu Styles */
.hamburgerMenu {
  position: relative;
}

.hamburgerIcon {
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
  padding-top: 5px;
  padding-right: 5px;
}

/* Drawer Styles */
.drawer {
  position: fixed;
  top: 0;
  right: -100%; 
  width: 100%;
  height: 100%;
  background-color: rgba(53, 64, 82, 0.95); 
  backdrop-filter: blur(5px);
  transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 2000; 
  display: flex;
  flex-direction: column;
  padding: 20px;
  opacity: 0; /* Start hidden */
}

.drawerOpen {
  right: 0; /* Slide the drawer into view */
  opacity: 1; /* Make it visible */
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.drawerTitle {
  font-size: 24px;
  color: #ffffff;
}

.closeIcon {
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}

.drawerContent {
  display: flex;
  flex-direction: column;
}

.drawerItem,
.drawerItemActive {
  padding: 15px 20px;
  margin: 10px 0;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.drawerItem {
  color: #ffffff;
  background-color: transparent;
}

.drawerItem:hover {
  background-color: #2e3b4e;
}

.drawerItemActive {
  color: #354052;
  background-color: #ffffff;
}

.drawerItemActive:hover {
  background-color: #ffffff; 
}

.drawerItem:last-child,
.drawerItemActive:last-child {
  border-bottom: none;
}

.disabledTab {
  color: grey;
  cursor: not-allowed;

  padding: 10px 50px;
  border-radius: 25px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Adjust existing tabBackground for mobile */
@media (max-width: 647px) {
  .tabBackground {
    display: none; 
    opacity: 0;
  }

  .activeTab,
  .inactiveTab {
    display: none; 
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.disabledTab {
  color: grey;
  cursor: not-allowed;
  position: relative; 
}

/* Tooltip styles */
.disabledTab[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%; 
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75); 
  white-space: nowrap;
  z-index: 1000;
}

.disabledTab[data-tooltip]:hover::before {
  content: '';
  position: absolute;
  bottom: calc(100% - 5px); 
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
  z-index: 1000;
}


#root > div > div > div.HeightObserverProvider-container > div {
  width: 100px;
}

.App-overview{
  display: none !important


}

#root > div > div > div.HeightObserverProvider-container > div > div > div:nth-child(2){
  display: none !important;
}
.flex-container {
min-width: 1200px;
}

.App-Container:not(.App-Container--setupMode) .App-Overview {
  background-color: red !important
}

.App-Container.is-embedded.is-veryRoundBordered.App.App--multiItem::before {
  content: ''; /* Required for ::before to be rendered */
  display: flex; 
  /* Other flexbox properties as needed */
}

.alertParagraph {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  color: #333;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  line-height: 1.4;
}

.alertIcon {
  /* Adjust icon size and color to your liking */
  width: 30px; 
  height: 30px; 
  color: green;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
