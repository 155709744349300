.back{
    border: 0;
    background: transparent;
    text-decoration: underline;
    margin-right: 2rem;
}
.confirmation{
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

/* styles for orgId 1034 and 1038 signup page */
.signupPage {
    min-height: 100vh;
    background-color: #354052; /* Treo navy blue */
    display: flex;
    align-items: stretch;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    z-index: 1000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.signupLayout {
    display: flex;
    width: 100%;
    min-height: 100vh;
}

/* logo section (left side) */
.logoSection {
    flex: 1;
    background-color: transparent; /* Make transparent to show background image */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    max-width: 300px;
}

.johnsonLogo {
    max-width: 280px;
    margin-bottom: 20px;
    height: auto;
    width: 100%;
}

.presents {
    font-size: 22px;
    letter-spacing: 2px;
    color: #6BA543; /* greenFlat */
    margin: 20px 0;
    letter-spacing: 3px;
}

.treoLogo {
    max-width: 400px;
    height: auto;
}

/* Form section (right side) */
.formSection {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.card {
    background: rgba(245, 245, 245, 1); /* same as login panel grey */
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1200px;
    padding: 40px 60px;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px;
}

.title {
    font-size: 26px;
    font-weight: 600;
    color: #6BA543;
    margin-bottom: 25px;
    text-align: center;
    line-height: 1.3;
}

.formGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 25px;
}


.passwordHint {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
}

.submitContainer {
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}


.footer {
    margin-top: 25px;
    text-align: center;
    font-size: 13px;
}


.disclaimer {
    margin: 25px 0;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: left;
    background-color: #f9f9f9;
}

.disclaimer h4 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 8px;
    text-transform: uppercase;
}

.disclaimer p {
    font-size: 13px;
    line-height: 1.5;
    color: #555;
    margin-bottom: 15px;
}

.termsLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}

.termsLinks :global(.TOS-row) {
    margin: 0 10px;
}

.termsLinks :global(.TOS-row) label {
    color: #2c8a1b;
    font-size: 13px;
    text-decoration: none;
    font-weight: 400;
    cursor: pointer;
}

.termsLinks :global(.TOS-row) label:hover {
    text-decoration: underline;
}

.termsLinks a {
    color: #2c8a1b;
    text-decoration: none;
    font-size: 13px;
}

.termsLinks a:hover {
    text-decoration: underline;
}

/* responsive styling */
@media (max-width: 992px) {
    .signupLayout {
        flex-direction: column;
    }
    
    .logoSection {
        flex: none;
        padding: 30px;
    }
    
    .formSection {
        flex: none;
        padding: 20px;
    }
    
    .card {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .formGrid {
        grid-template-columns: 1fr;
        gap: 15px;
    }
    
    .card {
        padding: 20px;
    }
    
    .logoSection {
        padding: 20px;
    }
    
    .treoLogo {
        max-width: 240px;
    }

    .johnsonLogo {
        max-width: 180px;
    }

    .presents {
        font-size: 18px;
    }
}

/* for mobile responsiveness */
@media (max-width: 575px) {
    .formSection {
        padding: 15px;
    }
    
    .card {
        padding: 15px;
        border-radius: 8px;
    }
    
    .title {
        font-size: 18px;
        margin-bottom: 20px;
    }

}