.container{
    max-width: 500px;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 1s ease-in;
}
.container.fadeIn{
    opacity: 1;
}
.heading{
    font-size: 28px;
    font-weight: 400;
    margin: 0 0 25px 0;
    text-align: center;
}
.image{
    width: 255px;
    height: auto;
    display: block;
    margin: 0 auto 25px auto;
}
.text{
    font-size: 16px;
    margin: 0 0 25px 0;
    text-align: center;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}