.notes-container {
    border: 2px solid gray;
    border-radius: 5px;    
}

.notes-title-label, .notes-label, .plan-units-label {
    display: block;
}

.notes-label {
    margin-top: 15px;
}

.notes {
    padding-bottom: 10rem;
}
