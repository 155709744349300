.button{
    border-radius: 10px;
    margin-left: 8px;
    font-size: 14px;
    padding: 2px 20px;
}
.currentScore{
    font-size: 16px;
    font-weight: 800;
}
.logButton{
    background-color: #74a94f;
    border: 2px solid #74a94f;
    color: #fff;
}
.logContainer{
    display: flex;
    flex: 1;
    align-items: center;
}
.logIcon{
    width: 20px;
    height: 20px;
    margin-right: 12px;
    font-size: 20px;
}
.logScore,
.logScore:hover,
.logScore:focus{
    justify-self: flex-start;
    flex: 1;
    text-decoration: none;
    color: #000;
}
.potentialScore{
    font-size: 10px;
}
.tileBorder{
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, .1);

    margin-bottom: 15px;
}
.tileContainer{
    padding: 0 15px;
}
.tileIcon{
    width: 25px;
    height: 25px;
    margin-right: 12px;

}
.tileBottom{
    display: flex;
    background: #f5f5f5;
    padding: 12px 15px;
    align-items: center;
}
.tileTop{
    display: flex;
    background: #fff;
    padding: 12px 15px;
    cursor: pointer;
}
.trendsLink{
    background-color: transparent;
    border: #d9d9d9 2px solid;
    color: #666;
}
.trendsLink:hover,
.trendsLink:focus{
    color: #666;
    text-decoration: none;
}