.container{
    margin: 15px 0 0 0;
    display: flex;
    flex-direction: column;
    padding: 0 15px 75px 15px;
}
.badge{
    width: 70px;
    margin-left: -5px;
}
.divider{
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
}
.infographic{
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.infographic img{
    width: 35px;
    margin-right: 15px;
    margin-left: -3px;
}
.infographic svg{
    width: 30px;
    margin-right: 15px;
    fill: #c4c4c4
}
.infographicLabel{
    font-weight: bold;
}
.label{
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
}
.label2{
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.more{
    display: flex;
    align-items: center;
    background: #f93b3f;
    border: none;
    color: #fff;
    text-decoration: none;
    justify-content: center;
    border-radius: 21px;
    height: 21px;
    margin-left: 10px;
    font-size: 12px;
    padding: 0 8px;
    font-weight: 400;
}
.more:hover{
    color: #fff;
    text-decoration: none;
}
.more svg{
    margin-left: 5px;
    color: #fff;
    width: 10px;
}
.more.noMore{
    background: transparent;
}
.more.noMore svg{
    color: #333;
    margin-left: 0;
}
.myVictories{
    flex: 1;
    overflow: auto;
}
.reactionsFeed{
    position: relative;
    min-height: 100px;
}
.reactions{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 0 0;
}
.reactions>div{
    display: flex;
    align-items: center;
    margin: 0 5px;
}
.reactions .icon{
    width: 20px;
    margin-left: 5px;
}
.reactions svg{
    margin-top: -2px;
}
.reactions .like svg{
    margin-top: -3px;
}
.streak{
    width: 55px;
}
.victory{
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    align-items: center;
    display: flex;
}
