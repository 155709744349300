.backIcon{
    fill: #4bbc4e;
    width: 40px;
    margin-bottom: 15px;
}
.container{
    background-color: #fff;
    padding: 0 5%;
    max-width: 800px;
    margin: 0 auto;
}
.stage{
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #4bbc4e;
    margin-bottom: 40px;
}