.buttonContainer{
    display: flex;
    justify-content: flex-end;
}
.heading{
    font-size: 18px;
    margin: 0 0 15px 0
}
.inputContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}