.arrow{
    width: 12px;
    fill: #354052;
    margin-bottom: -6px;
    margin-left: 5px;
}
.arrow.up{
    transform: rotate(180deg);
}
.row{
    display: flex;
    padding: 10px 15px;
    margin-bottom: 15px;
    transition: all .3s ease;
}
.row:hover{
    background-color: #ececec;
}
.row.header{
    margin-bottom: 10px;
    font-weight: bold;
    position: sticky;
    top: 0;
    background: #cccccc;
    z-index: 1;
}
.row.header:hover{
    background-color: #cccccc;
}
.row .category{
    margin-right: 25px;
    width: 250px;
    text-transform: capitalize;
}
.row .childCategory{
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: -5px;
    transform: translateY(5px);
    display: inline-block;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
}
.row .date{
    width: 150px;
    margin-right: 25px;
}
.row .format{
    color:#354052;
    width: 50px;
    margin-right: 25px;
}
.row .format svg{
    width: 12px;
    fill: #354052;
}
.row .status{
    margin-right: 25px;
    width: 75px;
    overflow: hidden;
    display: inline-block;
    text-align: center;
}
.row .status.draft{
    background-color: #cccc;
    border-radius: 5px;
    padding: 2px 5px;
    text-align: center;
}
.row .status.published{
    color: #4bbc4e;
}
.row .status.scheduled{
    background-color: #bbeaff;
    padding: 2px 5px;
    border-radius: 5px;
    color: #005276;
    text-align: center;
}
.row .title{
    font-weight: bold;
    flex: 1;
    margin-right: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
    text-decoration: none;
}
.row .type{
    width: 80px;
    margin-right: 25px;
    overflow: hidden;
    text-transform: capitalize;
}
.row .view{
    display: none;
    width: 40px;
    overflow: hidden;
}
.row .view button{
    border: none;
    background-color: transparent;
    text-decoration: underline;
}

@media screen and (max-width: 1024px) {
    .row .category,
    .row .type,
    .row .format { display: none;}

}
@media screen and (max-width: 700px) {
    .row .date,
    .row .status,
    .row .view{ display: none;}

}