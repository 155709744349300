.cardContainer{
    font-weight: 600;
  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.128));
    border-radius: 15px ;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;

    width: 100%;

}




@media (max-width: 1000px) {
  .cardContainer { 
    margin-bottom: 0;

  }

}
