.alert{
    position: absolute;
    top:0;
    left: 0;
    color: #4BBC4E;
    transition: opacity .5s ease-in;
    opacity: 0;
    font-size: 14px;
}
.alert.active{
    opacity: 1;
}
.contentLink{
    background-color: #fff;
    color: #000;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    text-transform: capitalize;
    position: relative;
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    height: 100%;
    cursor: pointer;
}
.contentLink .title{
    font-size: 18px;
    font-weight: 800;
    color: #333;
}
.contentLink.disabled .title{
    color: #c2c2c2;
}
.contentLink .text{
    flex: 1;
}
.contentLink .img{
    box-shadow: 0 0 10px rgba(0,0,0,.4);
    border: 4px solid #fff;
    border-radius: 50%;
    background: #fff;
}
.contentLink img.img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.overlay{
    background: transparent;
    background: -moz-linear-gradient(90deg,
    rgba(255,255,255,1) 50%,
    rgba(255,255,255,0.7) 75%,
    rgba(255,255,255,0.5) 80%,
    rgba(255,255,255,0.25) 90%,
    rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(90deg,
    rgba(255,255,255,1) 50%,
    rgba(255,255,255,0.7) 75%,
    rgba(255,255,255,0.5) 80%,
    rgba(255,255,255,0.25) 90%,
    rgba(255,255,255,0) 100%);
    background: linear-gradient(90deg,
    rgba(255,255,255,1) 50%,
    rgba(255,255,255,0.7) 75%,
    rgba(255,255,255,0.5) 80%,
    rgba(255,255,255,0.25) 90%,
    rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.unselected{
    width: 40px;
    height: 40px;
    margin-top: 8px;
    margin-bottom: -5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 40%);
    border: 4px solid #fff;
    border-radius: 50%;
    display: inline-block;
    background-color: #fff;
}