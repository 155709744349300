.container{
    min-height: 505px;
    position: relative;
    padding: 15px;
}
.row{
    display: flex;
    margin-bottom: 5px;
}
.row .info{
    width: 75px;
}
.row .victory{
    flex: 1;
    max-width: 175px;
}
.stat{
    margin-right: 25px;
}
.statsContainer{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.topRow{
    font-size: 12px;
    color: #6c6c6c;
    text-decoration: underline;
    margin-bottom: 0;
}