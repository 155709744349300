.paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pageNumber {
    margin: 0 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: normal;
    display: inline-flex;
    align-items: center;
  }
  
  .pageNumber.active {
    font-weight: bold;
  }
  
  .arrow {
    margin-left: 10px;
    cursor: pointer;
    font-size: 24px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 4px;
  }
  