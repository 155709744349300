.addButton{
    text-align: right;
}
.found{
    font-weight: bold;
    font-size: 18px;
}
.infoContainer{
    display: flex;
}
.infoContainer .image{
    width: 90px;
}
.infoContainer .info{
    flex: 1;
}
.labels{
    margin: 0;
    font-size: 12px;
}
.labels .output{
    float: right;
}
.links{
    color: #000;
}
.links:hover{
    color: #000;
    text-decoration: none;
}
.name{
    margin-top:0;
}
.stat{
    display: inline-block;
    font-weight: 400;
    font-size: 32px;
    color: #000;
    margin-right: 2rem;
}
.stat:last-of-type{
    margin-right: 0;
}
.statBig{
    display: block;
    font-family: din-condensed;
    margin: 0;
}
.stat .statLabel{
    font-size: 14px;
}
.stat.statGrey{
    color: #ccc;
}
.stat .statSmall{
    display: block;
    font-size: 21px;
    margin: 0;
}
@media screen and (max-width: 992px) {
    .labels .output {
        float: none;
        margin-left: 1rem;
    }
    .name{
        margin-top: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .addButton{
        text-align: left;
    }
}