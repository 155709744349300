.container{
    display: flex;
}
.container .sidebar{
    flex: 1;
}
.container .content{
    flex: 4;
    margin-left: 35px;
}
.heading{
    margin: 25px 0 40px 0;
}

@media screen and (max-width: 991px){
    .container {
        display: block;
    }
    .container .content{
        margin-left: 0;
    }
    .container .sidebar{
        margin-bottom: 25px;
    }
    .container .sidebar :global(.container){
        width: 100%;
        padding: 0;
        margin: 0;
    }
}