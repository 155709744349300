.label {
  margin-top: 5px;
  font-weight: 400;
}
.inputWrapper {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  font-size: 18px;
  margin-top: 5px;
  width: 400px;
}

.flexContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.zipCode {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  font-size: 15px;
  width: 400px;
}

.cardInput label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.message {
  margin-top: 10px;
  font-size: 18px;
}

@media (max-width: 490px) {
  .zipCode {
    max-width: 250px;
  }
  .inputWrapper {
    width: 250px;
  }
}



