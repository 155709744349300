.container{
    height: 100%;
    padding: 18px 12px;
    overflow: auto;
}
.header .buttons{
    float: right;
}
.header .close{
    background-color: transparent;
    padding: 9px;
    border: 2px solid #ababab;
    border-radius: 50%;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header .close svg{
    fill: #ababab;
    width: 12px;
    height: 12px;
}