.buttons{
    display: flex;
    justify-content: space-around;
    font-size: 35px;
}
.buttons button{
    background: transparent;
    margin: 15px 0 0 0;
    padding: 0;
    border: none;
    width: 35px;
    height: 35px;
    position: relative;
}
.buttons svg{
    fill: #fff;
}
.buttons span{
    font-size: 14px;
    position: absolute;
}
.buttons .back{
    top: 12px;
    right: 6px;
}
.buttons .forward{
    top: 12px;
    right: 14px;
}
.buttons .play{
    height: 50px;
    width: 50px;
    color: #fff;
}
.buttons .play svg{
    width: 50px;
    height: 50px;
    /* margin-right: -10px; */
}
.buttons .play .pause{
    width: 50px;
    height: 50px;
    margin-right: 0;
}
.player{
    background: rgba(0,0,0,.7);
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    display: block;
}
.progressBar{
    --bar-bg: transparent;
    --seek-before-width: 0px;
    --seek-before-color: #fff;
    --handle: #fff;
    --selected-handel: #333;
    --border-radius: 10px;
    --bar-height: 8px;

    appearance: none;
    background: var(--bar-bg);
    border-radius: var(--border-radius);
    position: relative;
    width: 100%;
    height: var(--bar-height);
    outline: none;
    padding: 0;
    border: 1px solid #fff;
}
/* progress bar - safari */
.progressBar::-webkit-slider-runnable-track{
    background: var(--bar-bg);
    border-radius: var(--border-radius);
    position: relative;
    width: 100%;
    height: 8px;
    outline: none;
}
/* progress bar - firefox */
.progressBar::-moz-range-track{
    background: var(--bar-bg);
    border-radius: var(--border-radius);
    position: relative;
    width: 100%;
    height: var(--bar-height);
    outline: none;
}
.progressBar::-moz-focus-outer{
    border: 0;
}
/* progress bar - chrome and safari */
.progressBar::before{
    content: '';
    height: var(--bar-height);
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    position:absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
}
/* progress bar - firefox */
.progressBar::-moz-range-progress{
    background-color: var(--seek-before-color);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    height: var(--bar-height);
}
/* knobby - chrome and safari */
.progressBar::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
    background-color: var(--handle);
    cursor: pointer;
    position: relative;
    margin-top: -3px;
    z-index: 3;
    box-sizing: border-box;
}
/* knobby while dragging - chrome and safari */
.progressBar:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selected-handel);
}
/* knobby - firefox */
.progressBar::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: transparent;
    background-color: var(--handle);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
}
/* knobby while dragging - firefox */
.progressBar:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selected-handel);
}
.timestamps{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}
.title{
    font-size:14px;
    margin-bottom: 15px;
    text-align: center;
}