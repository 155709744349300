.arrow{
    width: 12px;
    fill: #354052;
    margin-bottom: -6px;
    margin-left: 5px;
}
.arrow.up{
    transform: rotate(180deg);
}
.cancel{
    border: 1px solid #9c4543;
    border-radius: 5px;
    font-size: 12px;
    color: #9c4543;
    background-color: #fff;
    margin-left: 10px;
}
.confirm{
    border: none;
    border-radius: 5px;
    font-size: 12px;
    color: #fff;
    background-color: #9c4543;
    margin-left: 10px;
}
.confirmContainer{
    display: flex;
    align-items: center;
}
.form{
    max-width: 400px;
}
.form .inputs{
    display: flex;
    align-items: center;
}
.form .inputs button{
    margin-left: 15px;
    text-shadow: none;
    font-size: 14px;
    padding: 0 25px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.header .new{
    margin-left: auto;
    font-size: 14px;
    text-shadow: none;
    padding: 0 25px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.row{
    display: flex;
    align-items: center;
    padding: 5px 15px;
    margin-bottom: 15px;
}
.row.head{
    background-color: #ccc;
    margin-bottom: 10px;
}
.row .buttons{
    align-self: flex-end;
    min-width: 80px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
}
.row .buttons svg{
    width: 20px;
    fill: #354052;
    cursor: pointer;
    margin-left: 20px;
}
.row .classes{
    width: 75px;
}
.row .name{
    flex: 1;
}
.row .updated{
    width: 100px;
}