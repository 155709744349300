.horizontal{
    display: flex;
    justify-content: space-between;z-index: 9999999;
}
.row{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.row .policy{
    flex: 1;
    margin: 0;
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400;
}

.btnContainer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.773); /* Grey-black transparent overlay */
    z-index: 9999998; /* Ensure it's below the modal but above other content */
  }