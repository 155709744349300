.btn{
    background-color: transparent;
    border: none;
    color: #333;
    display: flex;
    align-items: center;
}
.category{
    font-size: 32px;
    color: #2A3342;
    margin: 15px 0;
    font-family: "din-condensed", sans-serif;
    font-weight: 400;
}
.filterIcon{
    width: 20px;
    height: auto;
    fill: #333;
    margin-right: 5px;
}
.subHeading{
    margin: 0;
    text-transform: uppercase;
    color: #2A3342;
    font-size: 28px;
    font-family: "din-condensed", sans-serif;
    font-weight: 400;
}
.trademark{
    font-size: 30px;
    color: #2A3342;
    position: relative;
    top: -5px;
}

@media screen and (max-width: 600px) {
    .category{
        font-size: 20px;
    }
    .subHeading{
        font-size: 20px;
    }
}