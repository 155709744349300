.bottomArc{
    background-color: #fff;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.bottomArc .arc{
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 100%;
    border-radius: 50% 50% 0 0;
    background-color: #fff;
}
.container{
    padding: 15px;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    inset: 0;
    z-index: 1000000;
    opacity: 0;
    transition: opacity .4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container.show{
    opacity: 1;
}
.continue{
    border-radius: 20px;
    background-color: #4bbc4e;
    color: #fff;
    border: none;
    padding: 4px 15px;
    position: relative;
    top: -15px;
    opacity: 0;
    transition: opacity .5s ease-in;
}
.continue.show{
    opacity: 1;
}
.greenStar{
    position: absolute;
    width: 0;
    transition: width .3s linear;
}
.greenStar.left{
    top: 100px;
    right: 245px;
}
.greenStar.right{
    top: 160px;
    left: 245px;
}
.medal{
    position: absolute;
    top: 160px;
    left: 70px;
    z-index: 1;
    width: 130px;
    transform: scale(0) translate(54%, 0);
}
.message{
    margin-top: 15px;
    font-size: 18px;
    text-align: center;
}
.messageSecondary{
    margin-top: 33px;
    font-size: 16px;
    text-align: center;
}
.modal{
    position: relative;
    background: rgb(151,201,61);
    background: linear-gradient(180deg, rgba(151,201,61,1) 0%, rgba(51,159,72,1) 100%);
    width: 265px;
    height: 375px;
    border-radius: 10px;
    transform: translateY(10%);
    transition: transformY, .4s ease;
}
.modal.show{
    transform: translateY(0);
}
.rank{
    text-align: center;
    transform: scale(0);
}
.strap{
    opacity: .2;
    margin-top: 25px;
    position: absolute;
    width: 220px;
    right: 50%;
    transform: translateX(50%);
}

/* Animations */
.animateText{
    opacity: 0;
    transform: translateY(30px);
    transition: transform .3s ease-in, opacity .5s ease-in;
}
.animateText.activeAnimation{
    opacity: 1;
    transform: translateY(0);
}
.glowText{
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 2px 2px 2px rgb(206 89 55 / 0%);
    transform: scale(100%);
    animation: glowAnimation .4s ease-in;
}
@keyframes glowAnimation {
    0%{
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 55px #fff,  2px 2px 2px rgb(206 89 55 / 0%);
        transform: scale(0);
    }
    70%{
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 55px #fff, 0 0 75px #fff, 0 0 75px #fff, 0 0 75px #fff, 0 0 95px #fff, 0 0 115px #fff, 0 0 135px #fff, 2px 2px 2px rgb(206 89 55 / 0%);
        transform: scale(105%);
    }
    100%{
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 2px 2px 2px rgb(206 89 55 / 0%);
        transform: scale(100%);
    }

}
.around{
    width: 40px;
    animation: aroundAnimation 6s linear infinite;
}
@keyframes aroundAnimation {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
.scale{
    transform: scale(100%);
    animation: sizeAnimation 1s ease-in;
}
@keyframes sizeAnimation {
    0%{
        transform: scale(0);
    }
    95%{
        transform: scale(105%);
    }
    100%{
        transform: scale(100%);
    }
}

/* Bronze Animations */
.bronzeMove{
    transform: scale(100%) translate(-35%, 0);
    animation: bronzeMoveAnimation .7s ease-in;
}

@keyframes bronzeMoveAnimation {
    from{
        transform: scale(100%) translate(0, 0);
    }
    to{
        transform: scale(100%) translate(-35%, 0);
    }
}

.bronzeMove_2{
    transform: scale(100%) translate(-55%, 0);
    animation: bronzeMoveAnimation_2 .7s ease-in;
}

@keyframes bronzeMoveAnimation_2 {
    from{
        transform: scale(100%) translate(0, 0);
    }
    to{
        transform: scale(100%) translate(-55%, 0);
    }
}

.bronzeMove_3{
    transform: scale(100%) translate(-55%, 0);
    animation: bronzeMoveAnimation_3 .7s ease-in;
}

@keyframes bronzeMoveAnimation_3 {
    from{
        transform: scale(100%) translate(0, 0);
    }
    to{
        transform: scale(100%) translate(-55%, 0);
    }
}

/* Silver Animations*/
.silverMove{
    transform: scale(100%) translate(33%, 0);
    animation: silverMoveAnimation .7s ease-in;
}


@keyframes silverMoveAnimation {
    from{
        transform: scale(100%) translate(0, 0);
    }
    to{
        transform: scale(100%) translate(33%, 0);
    }
}

.silverMove_2{
    transform: scale(100%) translate(0%, 0);
    animation: silverMoveAnimation_2 .7s ease-in;
}


@keyframes silverMoveAnimation_2 {
    from{
        transform: scale(100%) translate(0, 0);
    }
    to{
        transform: scale(100%) translate(0%, 0);
    }
}

.silverMove_3{
    transform: scale(100%) translate(-19%, 0);
    animation: silverMoveAnimation_3 .7s ease-in;
}


@keyframes silverMoveAnimation_3 {
    from{
        transform: scale(100%) translate(0, 0);
    }
    to{
        transform: scale(100%) translate(-19%, 0);
    }
}

/* Gold Animations */
.goldMove{
    transform: scale(100%) translate(53%, 0);
    animation: goldMoveAnimation .7s ease-in;
}


@keyframes goldMoveAnimation {
    from{
        transform: scale(100%) translate(0, 0);
    }
    to{
        transform: scale(100%) translate(53%, 0);
    }
}

.goldMove_2{
    transform: scale(100%) translate(17%, 0);
    animation: goldMoveAnimation_2 .7s ease-in;
}

@keyframes goldMoveAnimation_2 {
    from{
        transform: scale(100%) translate(0, 0);
    }
    to{
        transform: scale(100%) translate(17%, 0);
    }
}

/* Platinum Animations */
.platinumMove{
    transform: scale(100%) translate(53%, 0);
    animation: platinumMoveAnimation .7s ease-in;
}


@keyframes platinumMoveAnimation {
    from{
        transform: scale(100%) translate(0, 0);
    }
    to{
        transform: scale(100%) translate(53%, 0);
    }
}