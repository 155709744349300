.container{
    height: 200px;
    overflow-y: auto;
    position: relative;
}
.clients{
    flex: 1;
    padding-right: 15px;
}
.headers{
    color: #7e7e7e;
    text-decoration: underline;
    margin-bottom: -5px;
    font-size: 12px;
}
.number{
    width: 50px;
    text-align: center;
}
.row{
    display: flex;
    margin-bottom: 15px;
}