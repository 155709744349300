.alignSubscriptionContainerFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.planOptionTitle {
  font-size: 24px;
  font-weight: bold;
}

.planOptionCurrency {
  font-size: 20px;
  text-align: center;
}

.subscriptionsSubtitle {
  font-size: 14px;
  font-style: italic;
  color: #a0a0a0;
}

.radioLabel {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  z-index: 50;
}

.radioInput {
  opacity: 0;
  z-index: 50;
  cursor: pointer;
  position: relative;
  left: 25px;
  top: 2px;
  width: 25px;
  height: 25px;
}

.radioPlaceholder {
  display: inline-block;
  position: relative;
  top: 3px;

  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid #a0a0a0;
  background-color: #fff;
  margin-right: 8px;
}

.radioPlaceholder.checked {
  border-color: #4caf50;
  background-color: #4caf50;
}

.radioPlaceholder i {
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Media Queries */

/* For screens 1024px wide or less */
@media (max-width: 1024px) {
  .planOptionTitle {
    font-size: 22px;
  }
  .planOptionCurrency {
    font-size: 18px;
  }
  .subscriptionsSubtitle {
    font-size: 16px;
  }
  .radioPlaceholder i {
    font-size: 12px;
  }
}

/* For screens 768px wide or less */
@media (max-width: 768px) {
  .planOptionTitle {
    font-size: 20px;
  }
  .planOptionCurrency {
    font-size: 16px;
  }
  .subscriptionsSubtitle {
    font-size: 14px;
  }
}

@media (max-width: 540px) {
  .planOptionCurrency {
    text-align: center;
    width: 140px;
    padding-left: 10px;

    padding-bottom: 5px;
  }

  .planOptionTitle {
    font-size: 14px;
  }
  .planOptionCurrency {
    font-size: 14px;
  }

  .subscriptionsSubtitle {
    font-size: 12px;
    top: -5px;
  }
}


