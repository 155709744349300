.code {
    width: 50%;
    padding-bottom: 10px;
    text-align: center;
}
.codesContainer {
    position: relative;
    max-width: 175px;
    flex-direction: row !important;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.container {
    max-width: 390px;
    gap: 20px;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 5px;
}
.copy {
    position: absolute;
    right: -25px;
    bottom: -15px;
    flex-direction: row !important;
    gap: 5px;
}
.passwordForm {
    gap: 20px;
    align-items: center;
}
.qrCode {
    align-items: center;
}
.qrContainer {
    gap: 20px;
    align-items: center;
}
.qrTitle {
    font-size: 16px;
    text-align: center;
    margin: 0;
}
.secret {
    position: relative;
    margin-bottom: 15px;
}