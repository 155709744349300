.cap{
    background-color: #74a94f;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.capEnd{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.capSingle{
    border-radius: 50%;
    width: 30px;
    margin: 0 auto;
}
.capStart{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.container{
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}
.dateHeader{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.dateNav{
    display: flex;
    justify-content: space-between;
}
.dateNav i{
    background-color: #dee7eb;
    color: #828A8D;
    padding: 7px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: all ease .2s;
}
.dateNav i:hover{
    color: #E1E5EA;
    background-color: #828A8D;
}
.dateNav i:first-of-type{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.dateNav i:last-of-type{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dateYear{
    font-weight: 800;
    font-size: 18px;
    text-transform: capitalize;
}
.hidden{
    color: #fff;
}
.number{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.row{
    display: flex;
    justify-content: space-around;
}
.row .cell{
    flex: 1;
    text-align: center;
    margin-bottom: 20px;
}
.weekHeader{
    display: flex;
    font-weight: 700;
    justify-content: space-around;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.weekHeader .weekday{
    flex: 1;
    text-align: center;
}