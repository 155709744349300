.tooltipContainer {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .tooltipLabel {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .tooltipValue {
    font-size: 16px;
  }
  
  .tooltipValue span {
    font-weight: bold;
    color: #7ed321;
  }
  