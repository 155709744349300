.fontSize {
    font-size: 10px
}

.imgContainer{
    max-width: 320px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.alignCenter{
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.btnContainer{
    width: 100%;
    gap: 20px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 20px;
}