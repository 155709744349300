.filterIcon{
    width: 30px;
    margin-right: 10px;
}
.filterIconContainer{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}
.loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}