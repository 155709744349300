.reactions{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #e8ecf0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 15px;
    padding: 15px 15px 10px;
    -webkit-box-shadow: 0px 14px 12px -9px rgba(0,0,0,0.25);
    box-shadow: 0px 14px 12px -9px rgba(0,0,0,0.25);
}
.reactions.silver{
    background: rgb(214,214,214);
    background: linear-gradient(60deg, rgba(214,214,214,1) 26%, rgba(255,255,255,1) 49%, rgba(125,125,125,1) 60%, rgba(194,194,194,1) 78%);
}
.reactions.gold{
    background: rgb(255,231,110);
    background: linear-gradient(60deg, rgba(255,231,110,1) 26%, rgba(246,240,149,1) 49%, rgba(255,188,0,1) 60%, rgba(255,230,57,1) 78%);
}
.reactions.platinum{
    background: rgb(214,255,253);
    background: linear-gradient(56deg, rgba(214,255,253,1) 8%, rgba(255,255,255,1) 49%, rgba(251,190,255,1) 65%, rgba(230,255,253,1) 70%);
}
.reactions>div{
    display: flex;
    align-items: center;
    margin: 0 5px;
}
.reactions .icon{
    width: 20px;
    margin-right: 5px;
}
.reactions svg{
    margin-top: -2px;
}
.victory{
    background-color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 5px 15px;
    align-items: center;
}
.victoryContainer.silver{
    box-shadow: 0px 0px 16px 2px #C4C4C4;
    border-radius: 5px;
}.victoryContainer.gold{
     box-shadow: 0px 0px 16px 2px #FFE12B;
    border-radius: 5px;
 }.victoryContainer.platinum{
       box-shadow: 0px 0px 16px 2px #85FCFF;
    border-radius: 5px;
   }
.victory .badge{
    width: 70px;
    position: relative;
    z-index: 1;
}
.victory .info{
    flex: 1;
    position: relative;
    z-index: 1;
}
.victory .info p{
    margin: 0;
}
.victory .info .name{
    font-weight: bold;
}
.victory .info .largeTitle{
    font-weight: bold;
    font-size: 18px;
}
.victory .noodle{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 0;
}
.victory .profile{
    margin-right: 15px;
}
.victory .profile .profileImage{
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}
.victory .badge2{
    position: relative;
    z-index: 1;
    width: 80px;
    padding: 5px;
}