.container{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    gap: 10px
}
.innerSegment{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform 1s linear;
    background-color: rgb(75, 188, 78);
}
.innerSegment.filled{
    transform: translateX(0);
}
.innerSegment.halfFilled{
    transform: translateX(-50%);
}
.segment{
    height: 10px;
    border-radius: 10px;
    background-color: rgba(75, 188, 78, .45);
    width: 12%;
    overflow: hidden;
    position: relative;
    margin-right: 1%;
}

.containerSkipped{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    gap: 10px
}