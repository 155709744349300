.answerContainer{
    margin-bottom: 25px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgb(78, 136, 161);
    height: 100%;
}
.answerBottom{
    padding: 10px;
}
.answerOrdinal{
    background-color: rgb(78, 136, 161);
    color: #fff;
    min-width: 40px;
    min-height: 40px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
}
.answerTop{
    display: flex;
    background-color: rgba(78, 136, 161, .25);
    align-items: center;
}