.container{
    display: flex;
}
.list{
    flex: 6;
    margin-right: 30px;
}
.sidebar{
    flex: 4;
}

@media screen and (max-width: 991px) {
    .container{
        flex-direction: column;
    }
    .list,
    .sidebar{
        flex: 1;
    }
}