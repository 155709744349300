.container{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    padding: 15px;
    background-color: #ecf2f6;
}
.container .victories{
    overflow: auto;
    height: 100%;
    padding-bottom: 75px;
}
.container .victoriesContainer{
    position: relative;
    flex: 1;
    overflow: hidden;
}
.label{
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.toggle :global(.toggleContainer) {
    background-color: #fff;
    border: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,.1);
}
.toggle :global(.toggleButton) {
    background-color: #dee7eb;
}
.toggleContainer{
    margin: 0 auto 15px auto;
    max-width: 300px;
}