.header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.headerBtns{
    text-align: right;
}
.headerBtns button{
    margin-left: 15px;
    border: none;
    background: transparent;
    color: #6BA543;
}
.search{
    max-width: 450px;
    display: flex;
    align-items: flex-end;
}
.search .button{
    text-transform: unset;
    font-size: 14px;
    margin-left: 15px;
    padding: 6px 30px;
}
.search :global(.form-group){
    margin-bottom: 0 !important;
}
.search .text {
    flex: 1;
}
@media screen and (max-width: 767px) {
    .headerBtns{
        text-align: unset;
    }
    .headerBtns button{
        margin-left: unset;
        margin-right: 15px;
    }
}