.btn{
    background: transparent;
    border: none;
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
}
.btn:first-of-type{
    margin-top: 15px;
}
.btn svg{
    width: 30px;
    fill: #333;
    margin-left: 10px;
}
.bulgeContainer{
    position: absolute;
    overflow: hidden;
    height: 70px;
    width: 100%;
    top: -45px;
}
.bulge{
    position: absolute;
    top: 65px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100px;
    width: 120%;
    border-radius: 50% 50% 0 0;
    background-color: #ecf2f6;
    display: none;
}
.concave {
    position: relative;
    background: #ecf2f6;
    height: 120px;
    top: 45px;
}

.concave:before,
.concave:after {
    box-shadow: 0 0 0 80px #ecf2f6;
    border-radius: 100%;
    position: absolute;
    height: 285px;
    content: '';
    right: -20%;
    left: -20%;
    top: 100%;
}

.concave:after {
    bottom: 100%;
    top: auto;
}
.loggingModal :global(.modal-dialog){
    max-width: 520px;
    margin-right: auto;
    margin-left: auto;
}
.infoContainer :global(.modal-dialog) {
    max-width: 375px;
    margin-right: auto;
    margin-left: auto;
}
.info{
    padding: 15px;
}
.info .infoBtns{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 25px;
}
.info .infoBtns a{
    font-size: 14px;
    text-transform: unset;
}
.info .infoClose{
    font-size: 14px;
    color: #354052;
    border: 1px solid #354052;
    background: transparent;
    padding: 3px 8px;
    border-radius: 5px;
    margin: 0 auto;
}
.info .infoList{
    margin-bottom: 15px;
}
.info .infoQuestions{
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
}
.info .infoTitle{
    font-size: 21px;
    margin: 0 0 15px 0;
    text-align: center;
}
.tilesContainer :global(.modal-dialog) {
    max-width: 375px;
    margin-right: auto;
    margin-left: auto;
}
.tiles{
    padding: 15px;
}
.tiles .actions{
    width: 30px;
    padding: 7px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    fill: #d9d9d9;
    cursor: pointer;
}
.tiles .actions.plus{
    padding: 0;
    fill: #4BBC4E;
    border: 1px solid #4BBC4E;
}
.tiles .actions.confirmX{
    border: 2px solid #a94442;
    fill: #a94442;
}
.tiles .tilesClose{
    font-size: 14px;
    color: #354052;
    border: 1px solid #354052;
    background: transparent;
    padding: 3px 8px;
    border-radius: 5px;
    margin: 0 auto;
}
.tiles .chevrons{
    display: flex;
    justify-content: space-evenly;
    font-size: 21px;
    cursor: pointer;
    margin-bottom: 25px;
}
.tiles .chevrons .disabled{
    color: #ccc;
    cursor: not-allowed;
}
.tiles .confirm{
    margin-bottom: 15px;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: flex-end
}
.tiles .confirm svg{
    width: 25px;
    border: 2px solid #a94442;
    fill: #a94442;
    padding: 5px;
    border-radius: 50%;
    margin: 0 5px;
}
.tiles .plan{
    margin: 0;
}
.tiles .tile{
    padding: 10px 5px;
    margin-bottom: 15px;
    border: 1px solid #4BBC4E;
    border-radius: 10px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}
.tiles .tile.grey{
    border: 1px solid #d9d9d9;
}
.tiles .tileQuestions .title{
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;
}
.tiles .tileQuestions .btns{
    display: flex;
    justify-content: space-around;
}
.tiles .tileQuestions .btns a{
    font-size: 14px;
    text-transform: unset;
    margin-bottom: 25px;
}
.tiles .title{
    font-size: 21px;
    margin-bottom: 25px;
    text-align: center;
}
.tile .icon{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tile .icon svg{
    width: 95%;
    height: 95%;
}
@media screen and (max-width: 550px) {
    .loggingModal :global(.modal-dialog){
        margin: 15px;
    }
}
@media screen and (max-width: 400px) {
    .infoContainer :global(.modal-dialog) {
        margin: 50px 15px 15px 15px;
    }
    .tilesContainer :global(.modal-dialog) {
        margin: 50px 15px 15px 15px;
    }
}
@media screen and (max-width: 600px) {
    .bulge{
        display: block;
    }
    .concave{
        display: none;
    }
    .mainButtons{
        margin: -50px auto 20px auto;
        justify-content: space-around;
    }
    .mainButtons .button{
        margin: 0;
    }
}