.documentsSection {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    width: 100%;
height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    font-size: 21px;
    margin-bottom: 20px;
  }
  

  
  .addButton {
    background: #6BA543;
    color: white;
    font-size: 20px;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .documentsList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .documentItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecf2f6;
    padding: 5px;
    border-radius: 5px;

    transition: background 0.2s ease-in-out;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.024);
  }
  
  .documentItem:hover {
    background: #f1f1f1;
  }
  
  .dragHandle {
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
  }
  
  .documentName {
    flex-grow: 1;
    text-align: left;
    font-size: 16px;
    color: #333;
    text-decoration: underline;
    margin-left: 30px;
  }
  
  .documentName:hover {
    text-decoration: underline;
  }
  

  .icon {
    font-size: 14px;
    vertical-align: middle;
  }
  
  .pdfIcon {
    color: red;
    margin-left: 5px;
  }
  
  .deleteButton {
    background: transparent;
    border: none;
    cursor: pointer;
    color: black;
    font-size: 14px;
  }
  
  .deleteButton:hover {
    color: red;
  }
  


  .paginationContainer{
margin-top: 100px;
margin-left: auto;
margin-right: auto;
  }