.heading{
    text-align: center;
    font-size: 20px;
    opacity: 0;
    transition: opacity 1s ease;
}
.heading.show{
    opacity: 1;
}
.mainContainer{
    width: 200px;
    margin: 13rem auto 0 auto;
}
.ring{
    position: absolute;
    transition: all 2s ease;
    opacity: 0;
    transform: rotate(60deg);
    z-index: 10;
}
.ringContainer{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    top: 12rem;
}
.show{
    opacity: 1;
}
.text{
    margin: 0;
    font-weight: bold;
    font-size: 21px;
    opacity: 0;
    transition: all 1s ease;
    position: absolute;
    text-align: center;
}