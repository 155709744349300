.container{
    max-width: 500px;
    margin: 0 auto;
}
.goalContainer{
    display: flex;
    align-items: center;
    background: #f3f3f3;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 15px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
    color: #333;
    cursor: pointer;
}
.goalContainer img{
    width: 40px;
    height: auto;
    margin-right: 10px;
    background-color: #808080;
}
.heading{
    font-size: 24px;
    font-weight: 400;
    text-transform: unset;
    margin: 0 0 25px 0;
}

.selected {
    background: linear-gradient(135deg, #399c39, #87c33f);
    color: white;
}

.goalContainer img{
    width: 40px;
    height: auto;
    margin-right: 10px;
}