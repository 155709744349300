.dateRangeContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
}

.dateFont {
  font-size: 21pt;
  font-weight: 700;
  color: #585858; /* Updated font color */
}

.customSelect {
  padding: 10px;
}

.viewingRange {
  display: flex;
  flex-direction: column;
}

.viewingRange p {
  font-size: 16pt;
  color: black; /* Updated font color */
  margin: 0;
}

.viewingRange h3 {
  color: black; /* Updated font color */
  margin: 0;
}

.controls {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.dropdown img {
  margin-right: 10px;
}
.dropdown {
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #ddd;
  width: 249px;
  height: 44px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; /* Added for positioning arrow */
}

.dropdown select {
  border: none;
  background: none;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  color: #585858; /* Ensuring the font color is updated */
  width: 100%;
  padding-right: 30px; /* Added space for the arrow */
  appearance: none; /* Hides default browser styling */
}

/* New wrapper for better control over the select box */
.customSelectWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

/* New: Custom dropdown arrow positioned to the right */
.customSelectWrapper {
  position: relative; /* Ensure the pseudo-element is positioned relative to its container */
}

/* New: Custom dropdown arrow positioned to the right */
.customSelectWrapper::after {
  content: "";
  background-image: url("../../../images/portalTriangleDown.png");
  background-size: contain; /* Adjust if needed (cover, auto, etc.) */
  background-repeat: no-repeat;
  width: 8px; /* Adjust to your icon size */
  height: 8px; /* Adjust to your icon size */
  position: absolute;
  top: 50%; /* Vertically center the icon */
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Ensuring the icon is on the left */
.icon {
  margin-right: 8px;
  color: #585858;
}

.setDateButton {
  background: linear-gradient(#f12f06, #d12a06);
  color: white;
  border: none;
  border-radius: 10px;
  width: 167px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  height: 44px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.setDateButton:hover {
  background-color: #c9302c;
}

.datePickers {
  display: flex;
  gap: 20px;
}

@media (max-width: 900px) {
  .controls {
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .dropdown {
    width: 100%;
  }
}
