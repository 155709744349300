/* SideMenu.module.css */
.listMenu {
    float: left;
    height: 100%;
    width: 200px;

  }
  



.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  padding: 30px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  background: #ffffff;
  background-color: #ecf2f6;;
  transition: background-color 0.2s ease;
}

.listItem:hover {
  background-color: #ecf2f6;;
}

.listItem.active {
  background:  #ffffff;;

}


@media (max-width: 608px) {
/* SideMenu.module.css */
.listMenu {
display: none;

}

}