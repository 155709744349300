/* Modal Overlay (Blurred Background) */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Container */
  .modal {
    background: white;
    padding: 24px;
    border-radius: 12px;
    width: 350px;
    text-align: center;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Modal Title */
  .modal h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #354052;
  }
  
  /* Input Group */
  .inputGroup {
    margin: 12px 0;
    text-align: left;
  }
  
  .inputGroup label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 6px;
  }
  
  .inputGroup input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    transition: border 0.2s ease-in-out;
  }
  
  .inputGroup input:focus {
    border-color: #6BA543;
    outline: none;
  }
  
  /* Modal Actions (Buttons) */
  .modalActions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .createButton {
    flex: 1;
    margin-right: 8px;
    background-color: #6BA543;
    color: white;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
  }
  
  .createButton:hover {
    background-color: #5a9135;
  }
  
  .closeButton {
    flex: 1;
    margin-left: 8px;
    background: #e74c3c;
    color: white;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
  }
  
  .closeButton:hover {
    background: #c0392b;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      transform: scale(0.95);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  

  .error{
    padding: 3px;
    color: red;
  }