.filter .dateRange{
    display: flex;
    align-items: center;
    max-width: 350px;
}


.cardWrapper{
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    flex-shrink: inherit;
    }
    