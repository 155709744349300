.editLink{
    float: right;
    font-size: 16px;
    text-decoration: underline;
}
.icon{
    width: 18px;
    height: 18px;
    margin-left: 0.5rem;
    top: -10px;
    position: relative;
    display: inline-block;
}
.measure{
    margin-bottom: 2.5rem;
}
.placeholder {
    font-size: 14px;
    color: #ccc;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
}
.placeholder a{
    color:#fff;
    text-decoration: underline;
}
.placeholder .arrow{
    padding: 0 2rem 0;
}