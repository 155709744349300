.placeholder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    width: 230px;
    text-align: center;
}
.row{
    margin: 0 10px 10px 10px;
    display: flex;
    align-items: center;
    line-height: 1.3;
}
.row .icon{
    width: 15px;
    top:-6px;
    margin-right: 2px;
}
.row .profile{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background-position: center;
    background-size: cover;
}
.row .text{
    flex: 1;
    font-size: 12px;
}
.row .text strong{
    font-size: 14px;
    color: #333;
}
.row .time{
    margin-left: 3px;
    font-size: 12px;
    color: #6c6c6c;
}
.row .unseen{
    width: 10px;
    height: 10px;
    background-color: #4bbc4e;
    margin: 0 5px 0 -5px;
    border-radius: 50%;

}