.membershipTitle {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(211, 211, 211, 0.362);
    font-size: 24px; /* Base font size */
  }
  
  .btnContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
    margin-left: auto;
  }
  
  .memberShipListContainer {
    display: table;
    width: 100%;
    list-style: none;
    padding-left: 0;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(211, 211, 211, 0.362);
  }
  
  .memberShipListContainer li {
    display: table-row;
    font-size: 24px; /* Base font size */
    font-weight: bold;
  }
  
  .memberShipListContainer li span,
  .memberShipListContainer li::before {
    display: table-cell;
    width: 60%;
    padding: 5px 10px;
    font-weight: 400;
  }
  
  /* Media Queries */
  @media (max-width: 1024px) {
    .membershipTitle,
    .memberShipListContainer li {
      font-size: 22px;
    }
  }
  
  @media (max-width: 768px) {
    .membershipTitle,
    .memberShipListContainer li {
      font-size: 20px;
    }
  }
  
  @media (max-width: 600px) {
    .membershipTitle {
        padding-bottom: 10px;
  
      }
    .membershipTitle,
    .memberShipListContainer li {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .membershipTitle,
    .memberShipListContainer li {
      font-size: 16px;
    }
  }
  
  @media (max-width: 320px) {
    .membershipTitle,
    .memberShipListContainer li {
      font-size: 14px;
    }
  }
  