table { 
	margin-top: 3rem;
	background: #fff;
}
.setDefault{
    display: none;
}
.setDefault.show{
    display: block;
    cursor: pointer;
}