.exit{
    font-size: 30px;
    color: #ccc;
    border-radius: 50%;
    border: 2px solid #ccc;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 2px;
    margin-left: 5px;
}
.tutorial{
    height: 40px;
    width: 100%;
    display: flex;
    background-color: #e0e6ea;
    box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15px;
    cursor: pointer;
}
.tutorial svg{
    width: 40px;
    height: 40px;
    margin-right: 15px;

}