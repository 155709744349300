.container{
    background: rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px -8px 10px -1px rgba(0,0,0,0.20);
    box-shadow: 0px -8px 12px -1px rgba(0,0,0,0.20);
    padding: 10px 0;
    display: flex;
    justify-content: space-evenly;
}
.link{
    min-width: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    color: #333;
    font-weight: bold;
    border: none;
    background-color: transparent;
    position: relative;
}
.link:hover, .link:focus, .link:active, .link:visited{
    text-decoration: none;
    color: #333;
}
.link svg{
    width: 35px;
    height: 35px;
    margin-bottom: 3px;
    fill: #dbd8d8;
}
.more{
    position: absolute;
}
.notification{
    background: #f93b3f;
    color: #fff;
    width: 23px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: 0;
    border-radius: 50%;
    font-weight: 400;
    font-size: 12px;
}