.boxes{
    margin: 0 15px 15px 15px;
    padding: 15px;
}
.boxesOuter{
    min-width: 150px;
    min-height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 25px;
}
.inner{
    position: relative;
    min-height: 191px;
}
.filter .dateRange{
    display: flex;
    align-items: center;
    max-width: 350px;
}
.heading{
    margin: 0 0 30px 0;
}
.heading2{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
}
.mainLabel{
    font-size: 36px;
    font-weight: bold;
}
.subLabel{
    font-size: 18px;
    font-weight: 400;
}
.healthConnectSyncedRow{
    display: flex;
    min-width: 150px;
    text-align: center;
}
.healthConnectSyncedRow .healthConnectSynced{
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 25px;
    height: 195px;
    position: relative;
}