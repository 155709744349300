.buttonContainer{
    padding: 10px 5px;
    -webkit-box-shadow: 0px 14px 12px -9px rgba(0,0,0,0.25);
    box-shadow: 0px 14px 12px -9px rgba(0,0,0,0.25);
    position: sticky;
    z-index: 2;
    background-color: #fff;
    top:0;
    display: flex;
    justify-content: space-evenly;
}
.buttonContainer button{
    border: 1px solid #354052;
    color: #354052;
    background: #fff;
    width: 130px;
    height: 35px;
    margin: 0;
    padding: 0;
    border-radius: 30px;
    font-size: 12px;
    position: relative;
}
.buttonContainer button.active{
    background-color: #354052;
    color: #fff;
}
.containerInner{
    max-width: 800px;
    margin: 0 auto;
}
.containerOuter{
    padding: 0;
    margin-bottom: -60px;
    color: #333;
    text-shadow: unset;
}
.notification{
    position: absolute;
    background-color: #f93b3f;
    color: #fff;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    bottom: 22px;
    right: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
@media screen and (max-width: 767px) {
    .buttonContainer button{
        border: none;
    }
}