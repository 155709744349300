.inputContainer {
  margin-bottom: 15px;
  position: relative;
}

/* Wrapper for the label and error text */
.labelErrorWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Label style remains unchanged */
.label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: #585858; /* Gray label color */
  font-weight: 600;
}

.labelText {
  display: inline-block;
}

/* Input wrapper for relative positioning */
.inputWrapper {
  position: relative;
}

/* Base style for the input field */
.input {
  width: 100%;
  padding: 10px 12px;
  border-radius: 10px;
  border: 1px solid #d8e5ea; /* Light border */
  background-color: #ecf5f797; /* Default light background */
  font-size: 16px;
  font-weight: 400;
  color: #333; /* Dark text */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inner shadow */
}

.input.readOnly {
  background-color: #ffffff; /* White background for read-only */
  cursor: not-allowed; /* Indicate non-editable */
}

/* When an icon exists, add extra left padding to the input field text */
.inputWithIcon {
  padding-left: 40px;
}

.input:focus {
  outline: none;
  border-color: #4caf50; /* Green border on focus */
  background-color: #fff; /* White background on focus */
}

/* Style for the icon inside the input field */
.inputIcon {
  position: absolute;
  top: 50%;
  left: 12px; /* Positioned within the padding */
  transform: translateY(-55%);
  font-size: 16px;
  color: #d32f2f; /* Red color for icon */
  pointer-events: none; /* Prevent interaction */
}

.select {
  width: 100%;
  padding: 10px 12px; /* Same padding as input */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #e0e0e0; /* Light border */
  background-color: #ecf5f797; /* Same background as input */
  font-size: 14px;
  font-weight: 400;
  color: #6b6b6b; /* Gray label color */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inner shadow */
  appearance: none; /* Remove default browser styles for dropdowns */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%236b6b6b" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px 16px;
  cursor: pointer;
}

.select:focus {
  outline: none;
  border-color: #4caf50; /* Green border on focus */
  background-color: #fff; /* White background on focus */
}

.select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  background-image: none; /* Remove arrow icon when disabled */
}

.error {
  border-color: #f02f06;
  border-width: 2px;
}

.errorText {
  color: #f02f06;
  font-size: 16px;
  margin-top: 5px;
}

/* The red asterisk for required fields */
.required::after {
  content: " *";
  color: #f02f06;
}
