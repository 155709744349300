.container{
    padding: 15px;
    height: 300px;
    position: relative;
    overflow: hidden;
}
.container .header{
    margin: 0 0 15px 0;
}
.more{
    border: none;
    color: #585858;
    padding: 2px 10px;
    margin: 5px 0 15px 0;
}
.reactions{
    height: 235px;
    overflow: auto;
}