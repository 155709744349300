.details{
    float: left;
    max-width: 100%;
}
.details .fileName{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    max-width: 100%;
}
.image{
    float:left;
    max-width: 130px;
    margin-right: 15px;
    margin-bottom: 1rem;
}
.input{
    display: inline-block !important;
    margin-bottom: 1rem;
}