.barContainer{
    display: flex;
    align-items: center;
    width: 100%;
}
.bar{
    width: 100%;
    height: 25px;
    margin-right: 15px;
    flex: 1;
}
.bar.onboarding{
    background-color: #3c7b7e;
}
.bar.coached{
    background-color: #e2cc10;
}
.bar.notCoached{
    background-color: #4bb850;
}
.bar.total{
    background-color: #6697bd;
}
.barLabel{
    margin-right: 15px;
    width: 210px;
}
.row{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}