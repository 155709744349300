.container{
    padding: 15px;
    height: 300px;
    position: relative;
    overflow: hidden;
}
.container .header{
    margin: 0 0 15px 0;
}
.more{
    border: none;
    color: #585858;
    padding: 2px 10px;
    margin: 5px 0 15px 0;
}
.reactions{
    height: 235px;
    overflow: auto;
}
.row{
    margin: 0 10px 10px 10px;
    display: flex;
    align-items: center;
    line-height: 1.3;
}
.row .icon{
    width: 15px;
    top:-6px;
    margin-right: 2px;
}
.row .profile{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background-position: center;
    background-size: cover;
}
.row .text{
    flex: 1;
    font-size: 12px;
}
.row .text strong{
    font-size: 14px;
    color: #333;
}
.row .time{
    margin-left: 3px;
    font-size: 12px;
    color: #6c6c6c;
}