.categories{
    width: 300px;
    display: flex;
    justify-content: flex-end;
}
.childCategory{
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: -5px;
    transform: translateY(5px);
    display: inline-block;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
}
.format{
    margin-right: 15px;
}
.historyItem{
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
}
.info{
    flex: 1;
}
.length{
    margin-right: 25px;
}
.thumbnail{
    background-position: top center;
    background-size: cover;
    width: 120px;
    height:160px;
    border-radius: 5px;
    margin-right: 15px;
}
.title{
    font-size: 21px;
    margin: 0 0 5px 0;
}
.watched{
    margin-right: 25px;
}