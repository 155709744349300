.addBtn{
    background-color: #fff;
    color: #354052;
    border-radius: 10px;
    width: 50px;
    height: 20px;
    border: 1px solid #354052;
    font-size: 12px;
}
.arrow{
     width: 12px;
     fill: #354052;
     margin-right: 15px;
     transform: rotate(-90deg);
     transition: transform .5s ease;
     position: relative;
     top: 4px;
     left: 5px;
 }
.arrow.open{
    transform: none;
}
.container{
    position: relative;
    margin-bottom: 25px;
}
.container .line{
    position: absolute;
    height: 0;
    left: 12px;
    top: 0;
    width: 1px;
    background: #ccc;
    transition: height .5s ease;
    z-index: 0;
}
.container .line.open{
    height: 100%;
}
.heading{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    margin-bottom: 5px;
}
.heading .thumbnail{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;
    margin-right: 10px;
    position: relative;
    z-index: 1;
}
.heading img{
    width: 25px;
    height: auto;
}
.heading .text{
    text-transform: capitalize;
    margin: 0 10px 0 0;
    flex: 1;
    max-width: 225px;
}
.headBtn{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
.highlight{
    background-color: #4BBC4E;
    color: #fff;
}
.subs{
    height: 0;
    overflow: hidden;
}
.subs.open{
    height: auto;
}
.subcategory{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.subcategory:hover{
    font-weight: bold;
}
.subcategory .name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 15px 0 35px;
    max-width: 225px;
}