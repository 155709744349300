.videoContainer {
    width: 100%;
    background-color: #000;
}
.videoContainer.offset{
    margin-top: -5rem;
    border-radius: 5px;
    overflow: hidden;
}
.videoContainer :global(.video-js) {
    width: 500px;
    max-width: 700px;
    margin: 0 auto;
    background-color: #000;
}
.videoContainer :global(.vjs-dock-title) {
    display: none;
}
@media screen and (max-width:992px) {
    .videoContainer{
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .videoContainer :global(.video-js) {
        width: 100%;
    }
}