.green{
    background-color: #6BA543;
    flex:4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 15px 0;
    flex: unset;
}
.heading{
    text-align: center;
    font-size: 28px;
    padding: 10px 0;
}
.logo{
    width: 150px;
    margin: 0;
}
.main{
    display: flex;
    flex-direction: column;
}
.text{
    padding-bottom: 20px
}