/* danger */
.danger{
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

/* saving */
.savingContainer{
    display: inline-block;
}
.saving{
    display: flex;
    align-items: center;
    color: #ccc;
}
.saving .alternativeCheckmark{
    height: auto;
    display:block;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7AC142;
}
.saving .checkmark{
    width: 20px;
    margin: 0 1rem 0 0;
    height: auto;
    border-radius: 50%;
    display: block;
    stroke-width: 4px;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bbc4e;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.saving .text{
    flex: 1 1;
    margin-top: -3px;
    margin-left: 0.5rem;
}

.saving .saveText.text{
    color: #4bbc4e;
}

/* secondary info */
.secondaryInfo{
    border-radius: 10px;
    padding: 20px;
    display: inline-block;
    width: 100%;
}

/* secondary warning */
.secondaryWarning{
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
    background-color: #FFEA84;
}

/* warning */
.warningContainer{
    display: inline-block;
}
.warning{
    display: flex;
    align-items: center;
    color: #ccc;
}
.warning .icon{
    width: 25px;
    margin: 0 5px 0 0;
    height: auto;
    border-radius: 50%;
    display: block;
    stroke-width: 2px;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #e2b156;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    background: #e2b156
}
.warning .text{
    flex: 1 1;
    margin-top: -3px;
    margin-left: 0.5rem;
}

.warning .warningText.text{
    color: #e2b156;
    font-weight: 600;
}