.heading{
    margin: 0 0 5px 0;
}
.headingSub{
    margin: 0 0 30px 0;
    font-size: 16px;
    font-weight: 400;
}
.list{
    margin: 0;
    padding: 0;
    list-style: none;
}
.row {
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
}
.row div{
    margin-right: 15px;
}
.row .approve{
    color: #4bbc4e;
    margin-right: 0;
}
.row button{
    border: none;
    background-color: transparent;
}
.row .nickname{
    flex: 1;
}
.row .reject{
    color: #9c4543;
}
.row .username{
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
}