.selectors{
    display: flex;
    margin-bottom: 30px;
}
.selectors button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 15px;
    background: transparent;
    border: none;
    border-radius: 5px;
    margin-right: 15px;
}
.selectors button.active{
    background-color: #354052;
    color:#fff;
}
.selectors .count{
    color: #fff;
    background: #6c6c6c;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: 15px;
}