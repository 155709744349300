.container{
    height: 200px;
    position: relative;
}
.dateToggle :global(.toggleContainer) {
    background-color: #354052;
    border: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,.1);
    width: 310px;
}
.dateToggle :global(.toggleButton) {
    background-color: #fff;
}