/* MonthSelector.module.css */
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    padding: 10px 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    width: fit-content;
    transition: background 0.3s ease;
    min-width: 300px;
  }
  
  .arrowButton {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px;
    display: flex;             
    align-items: center;        
    justify-content: center; 
    transition: transform 0.3s ease;
  }
  
  .arrowButton:hover {
    transform: scale(1.1);
  }
  
  .icon {
    width: 28px;               /* Increased size */
    height: 28px;              /* Increased size */
    fill: #68B93F;
    transition: fill 0.3s ease;
    vertical-align: middle;    /* Ensures icon aligns with text */
  }
  
  .monthDisplay {
    font-size: 21px;
    font-weight: 400;
    color: #333;
    margin: 0 15px;
    transition: color 0.3s ease;
  }
  
  .arrowButton:hover .icon {
    fill: #4a7d32;
  }
  