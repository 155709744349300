.card{
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 14px 12px -9px rgba(0,0,0,0.25);
}
.card .icon {
    fill: #6BA543;
    stroke: #6BA543;
}
.card .separator {
    height: 1px;
    background-color: #ccc;
}
.card .subtitle{
    margin: 0;
}
.card .title{
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}
.card .titleContainer{
    display: flex;
    flex-direction: column;
}
.card .top {
    display: flex;
    gap: 20px;
    border-bottom: 1px;
    border-color: #ccc;
}
.cardContainer{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.mfaHeading{
    font-weight: 700
}
.mfaMethod {
    flex-direction: row !important;
    display: flex;
    gap: 20px;
    align-items: center;
    max-width: 100%;
}
.mfaMethodText {
    flex-shrink: 1;
}
.mfaMethodText p{
    margin: 0;
}
.mfaMethodContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
}
.mfaMethodContainer div {
    display: flex;
    flex-direction: column;
}
.passwordContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.passwordContainer > div{
    max-width: 500px;
}
.passwordContainerButtons{
    display: flex;
    gap: 20px;
}
.passwordModal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}
.passwordModalButtons{
    display: flex;
    gap: 10px;
}