.customerPortal {
  padding: 2rem 4rem;
  padding: 2rem 4rem;
}

/* Box Container */
.boxes {
  padding: 20px;
}

/* Customer Button Container */
.customerBtnContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
  text-transform: uppercase;
  padding: 12px;
  border-radius: 6px;
  background: #6ba543;
  color: #fff;
  outline: none !important;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  margin-top: 20px;
}

/* Customer Tile Styles */
.customerTile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  transition: background 0.3s ease;
}

.customerTile:hover {
  background: #eaeaea;
}

/* User Icon */
.icon {
  font-size: 24px;
  color: #6ba543;
  margin-right: 10px;
}

/* Customer Info */
.info {
  flex-grow: 1;
}

.info h4 {
  margin: 0;
  font-size: 16px;
}

.info p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.role {
  font-size: 12px;
  color: white;
  background: #6ba543;
  padding: 4px 8px;
  border-radius: 4px;
}

/* Delete Button */
.deleteButton {
  background: transparent;
  border: none;
  color: #e74c3c;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

.deleteButton:hover {
  color: #c0392b;
  transform: scale(1.1);
}


.documentationContainer {
    display: flex;
    width: 100%;
    flex-direction: row; /* default layout */
  }
  
  /* When the screen width is 768px or smaller, switch to a column layout */
  @media (max-width: 908px) {
    .documentationContainer {
      flex-direction: column;
    padding-bottom: 100px;
    }
  }
  