
.modalDialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  width: 100%;
  margin: 0 auto;
  z-index: 9999;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
}

.modalTitle {
  font-weight: 600;
  text-align: center;
  font-size: 22px;
  margin-bottom: 1rem;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

/* Modal text (p) styles */
.modalText {
  width: 100%;
  font-size: 18px;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 400px;
}

/* Modal button styles */
.modalButton {
  margin-top: 20px;
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
}

/* Logo styles */
.logo {
  max-width: 100%;
  height: auto;
  padding: 10px;
}

/* Media queries for tablet and mobile screens */
@media (max-width: 768px) {
  .modalBody {
    padding: 15px;
  }

  .modalTitle {
    font-size: 21px;
  }


}

