.bar{
    height: 25px;
    max-width: 100%;
    background-color: #4bbc4e;
}
.container{
    height: 300px;
    position: relative;
}
.containerResponsive{
    min-height: 300px;
    position: relative;
}
.label{
    width: 235px;
    margin-right: 15px;
}
.number{
    width: 60px;
    margin-left: 15px;
}
.row{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.row:last-of-type{
    margin-bottom: 0;
}