
/* CardTypes.module.css */

.cardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 20px;
    margin: 5px 0;
    max-width: 400px;
    cursor: pointer;
    background: lightgrey; /* Default background color */
  }
  
  .cardContainerSelected {
    background: #d3d3d3; /* Background color when selected */
  }
  
  .cardInfo {
    display: flex;
    gap: 40px;
  }
  .cardIcon {
    transform: scale(2.8); 
  }
  
  .cardIconContainer {
    background: white;
    padding: 10px 25px 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: fill;
    border: 4px solid rgba(128, 128, 128, 0.504);
    border-radius: 8px; 
  }
  
  .cardExpiry {
    display: flex;
    gap: 20px;
  }
  
  .cardActions {
    display: flex;
    gap: 20px;
    position: relative;
    top: -10px;
    background-color: #1b7af62e;
    padding: 5px;
  }
  
  .cardForm {
    max-width: 700px;
    margin-top: 25px;
  }
  
  .addCardButton {
    margin-top: 20px;
  }
  
  .btnContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
  }
  