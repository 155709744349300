/* InvoiceDetails.module.css */
.invoiceContainer {
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
  }
  
  .titleContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .companyName {
    font-size: 18px;
    font-weight: bold;
  }
  
  .title {
    font-size: 18px;
  }
  
  .downloadContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .downloadText {
    color: lightgray;
  }
  
  .payOnlineLink {
    color: #0070f3;
    text-decoration: underline;
  }
  
  .invoiceTable {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
  }
  
  .leftAlign {
    text-align: left;
  }
  
  .centerAlign {
    text-align: center;
  }
  
  .rightAlign {
    text-align: right;
  }
  
  .subtotalLabel {
    text-align: right;
    font-weight: bold;
  }
  
  .amountDue {
    text-align: right;
    font-weight: bold;
  }
  