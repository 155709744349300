.add{
    color: #4BBC4E;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.add:hover,
.add:active,
.add:visited{
    text-decoration: none;
    color: #4BBC4E;
}
.add svg{
    width: 30px;
    height: 30px;
    margin-left: 10px;
    transition: fill .5s ease;
    border-radius: 50%;
    fill:#4BBC4E;
}
.add svg circle{
    stroke: #4BBC4E;
}
.add:hover svg{
    fill:#fff !important;
    background-color: #4BBC4E;
}