.align_items {align-items: center}
.container {padding-right: 15px; padding-left: 15px;}
.flex_1 {flex: 1}
.flex_between {justify-content: space-between;}
.flex_around {justify-content: space-around;}
.flex_wrap {flex-wrap: wrap;}
.flex_end {align-items: flex-end;}
.flex_end_row {justify-content: flex-end;}
.flex_row {flex-direction: row;}
.flexDiv,
.flexDiv div {display: flex; flex-direction: column;}
.flexDiv p{margin: 0}
.italic {font-style: italic;}
.justify_items {justify-content: center;}
.margin_vertical_1 {margin-top: 10px; margin-bottom: 10px;}
.margin_vertical_2 {margin-top: 20px; margin-bottom: 20px;}
.margin_vertical_3 {margin-top: 30px; margin-bottom: 30px;}
.margin_top_1 {margin-top: 10px}
.margin_top_2 {margin-top: 20px}
.margin_top_3 {margin-top: 30px}
.margin_bottom__5 {margin-bottom: 5px}
.margin_bottom_1 {margin-bottom: 10px}
.margin_bottom_2 {margin-bottom: 20px}
.margin_bottom_3 {margin-bottom: 30px}
.margin_bottom_4 {margin-bottom: 40px}
.margin_sides_1 {margin-left: 10px; margin-right: 10px;}
.margin_sides_2 {margin-left: 20px; margin-right: 20px;}
.margin_sides_3 {margin-left: 30px; margin-right: 30px;}
.margin_right__5 {margin-right: 5px}
.margin_right_1 {margin-right: 10px}
.margin_right_2 {margin-right: 20px}
.margin_right_3 {margin-right: 30px}
.margin_left__5 {margin-left: 5px}
.margin_left_1 {margin-left: 10px}
.margin_left_2 {margin-left: 20px}
.margin_left_3 {margin-left: 30px}
.padding_bottom_1 {padding-bottom: 10px}
.padding_bottom_2 {padding-bottom: 20px}
.padding_top_1 {padding-top: 10px}
.padding_top_2 {padding-top: 20px}
.padding_sides_1 {padding-left: 10px; padding-right: 10px}
.padding_sides_2 {padding-left: 20px; padding-right: 20px}
.padding_1 {padding: 10px}
.padding_1_5 {padding: 15px}
.padding_2 {padding: 20px}
.padding_3 {padding: 30px}
.padding_right__5 {padding-right: 5px}
.padding_right_1 {padding-right: 10px}
.padding_right_2 {padding-right: 20px}
.padding_right_3 {padding-right: 30px}
.padding_left__5 {padding-left: 5px}
.padding_left_1 {padding-left: 10px}
.padding_left_2 {padding-left: 20px}
.padding_left_3 {padding-left: 30px}
.padding_vertical_1 {padding-top: 10px; padding-bottom: 10px;}
.padding_vertical_2 {padding-top: 20px; padding-bottom: 20px;}
.padding_vertical_3 {padding-top: 30px; padding-bottom: 30px;}
.relative {position: relative}
.roundedCorners {border-radius: 5px; overflow: hidden}
.textCenter {text-align: center}
.underlined {text-decoration: underline}
.transparentBg {background-color: transparent}