.skeletonText {
    background-color: #f0f0f0; /* Lighter grey */
    position: relative;
    overflow: hidden;
    border-radius: 4px; /* Add border radius */
}

.skeletonText::after {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    height: 100%;
    width: 150%;
    background: linear-gradient(
        to right,
        transparent 0%,
        rgba(255, 255, 255, 0.4) 50%,
        transparent 100%
    );
    animation: loading 1.5s infinite;
    border-radius: inherit; /* Inherit border radius from parent */
}

@keyframes loading {
    0% {
        left: -150%;
    }
    50% {
        left: 0%;
    }
    100% {
        left: 150%;
    }
}


